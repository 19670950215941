.routePlan-download {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  :global {
    .download-container {
      width: 100%;
      display: flex;
      gap: 12px;
      .download-radio {
        height: unset;
      }

      .download-item {
        display: flex;
        align-items: center;

        &>* {
          color: #355691;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          /* 157.143% */
          padding: 0px 10px;
          border: 1px solid rgba(0, 0, 0, 0.10);
          border-right: 0px;

          &.linear-measure {
            padding: 0px 27px;
          }
        }

        &>:last-child {
          border-right: 1px solid rgba(0, 0, 0, 0.10);
        }
      }
    }
  }
}