.legend-typhoons {
	:global {
		.legend-typhoons-container {
			display: flex;
			gap: 10px;
			align-items: center;
			.legend-typhoons-item {
				gap: 2px;
				display: flex;
				align-items: center;
				& > .legend-typhoons-zero {
					display: flex;
					width: 20px;
					height: 20px;
					-ms-flex-item-align: center;
					align-self: center;
					border: 1px solid red;
					background-color: gray;
					border-radius: 12px;
				}
				& > .legend-typhoons-icon {
					font-size: 24px;
					width: 24px;
					height: 24px;
				}
				& > :last-child {
					font-size: 12px;
					font-weight: 400;
				}
			}
			& > :first-child.legend-typhoons-item {
				& > :first-child {
					// border: 1px solid red;
					// border-radius: 12px;
					// stroke: red;
				}
			}
		}
	}
}
