.estimation-list-filter {
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: space-between;

  :global {
    .filter {
      height: 100%;
      display: flex;
      grid-template-rows: repeat(auto-fit, 58px);
      gap: 8px;

      .filter-item {
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: 10px;

        .label {
          color: #484848;
          font-size: 14px;
          font-weight: 450;
          line-height: 16px;
        }

        .date-input {
          height: 32px;
        }
      }

      .filter-btn {
        align-self: flex-end;
      }
    }

    .filter-toolbar {
      display: flex;
      align-items: flex-end;
      gap: 11px;
    }
  }
}

.estimation-category {
  width: 660px !important;
}
.filter-content {
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 8px;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
