.headFixture-payment {
  :global {
    .form-container {
    }
  }
}

.headFixture-paymentTerm-tooltip {
  width: 298px;
  padding: 6px 8px;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.75);
  color: #FFFFFF;
  
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}