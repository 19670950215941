.handoverForm-loadMore {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  :global {
    .loadMore-action {
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.10);
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      padding: 3px 12px;
      background-color: #e6e8ef;
      cursor: pointer;
      // background: #76C4FC;

      &>:first-child {
        color: #355691;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
      }

      &>:last-child {
        font-size: 16px;
        transform: rotateZ(-90deg);
        transition: all 300ms ease-in;

        &.open {
          transform: rotateZ(90deg);
        }
      }
    }

    .loadMore-container {
      width: 100%;
      // transform: scale(0);
      // max-height: 0px;
      transition: all 300ms ease-in;
      display: none;
      border-top: 1px solid #D9D9D9;
      padding-top: 16px;

      &.open {
        // transform: scale(1);
        // max-height: fit-content;
        display: inline-block;
      }
    }
  }
}