// @import url(https://api.mapbox.com/mapbox-gl-js/v2.9.2/mapbox-gl.css);
@import (reference) url(https://api.mapbox.com/mapbox-gl-js/v2.9.2/mapbox-gl.css);
@import url(./asserts/css/PolylineMeasure.css);
.routeCommon-map {
	width: 100%;
	height: 100%;
	position: relative;
	:global {
		#windy {
			height: 100%;
			position: relative;
			.leaflet-control-container {
				.leaflet-bottom + .leaflet-right {
					.leaflet-control-attribution {
						display: none;
					}
				}
				.leaflet-control-mouseposition {
					.routeCommon-lnglat-control {
						position: fixed;
						bottom: 80px;
						left: 332px;
						color: #fff;
						font-weight: 600;
						background: rgba(0, 0, 0, 0.5);
						padding: 5px;
						font-size: 12px;
						border-radius: 4px;
					}
				}
			}
		}
		.routeCommon-map-legend {
			width: 120px;
			position: absolute;
			z-index: 2;
			right: 10px;
			top: 103px;
		}
	}
}

.routeCommon-map-vesselInfo-popup {
	position: absolute;
	z-index: 101;
	:global {
		// /* 去掉 Popup 的默认背景和边框 */
		// .mapboxgl-popup {
		// 	background: none;
		// 	/* 去掉背景 */
		// 	border: none;
		// 	/* 去掉边框 */
		// }

		/* 自定义 Popup 内容样式 */
		.mapboxgl-popup-content {
			padding: 0;
			/* 去掉默认内边距 */
			color: #333;
			/* 设置文字颜色 */
			font-size: 14px;
			// transition: all 2s ease-in-out;
			box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
			/* 设置字体大小 */
		}

		/* 自定义 Popup 尖角样式 */
		.mapboxgl-popup-tip {
			/* background: rgba(0, 0, 0, 0.65); */
			border-top-color: rgba(0, 0, 0, 0.65) !important;
			/* 去掉尖角背景 */
		}
	}
}

.routeCommon-map-vesselInfo-popup--active {
	// display: block;
	opacity: 1;
}

.routeCommon-map-leafletPopup {
	margin-bottom: 0px !important;
	:global {
		.leaflet-popup-content-wrapper {
			background: transparent !important;
			padding: 0px !important;
			box-shadow: none !important;
			.leaflet-popup-content {
				margin: 0px !important;
				box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
			}
		}
		.leaflet-popup-tip-container {
			display: none;
		}
	}
}
