.voyagemanage-cargoBook {
  overflow: hidden;
  position: relative;
  padding: 24px 21px 30px 20px;
  display: flex;
  flex-direction: column;

  :global {
    .cargoBook-toolbar {
      width: 100%;
      height: 58px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 19px;

      .toolbar-filter {
        height: 100%;
        display: flex;

        .filter {
          display: flex;
          flex-direction: column;
          // justify-content: flex-end;
          justify-content: space-between;
          height: 100%;
          margin-right: 8px;

          .filter-label {
            color: #707070;
            
            font-size: 14px;
            font-weight: 500;
          }

          .input-filter {
            height: 32px;
            width: 254px;
          }

          .date-filter {
            height: 32px;
            width: 288px;
          }

        }

        .category-filter {
          justify-content: flex-end;
        }

      }

      .toolbar-actions {
        height: 100%;
        display: flex;
        align-items: flex-end;

        .toolbar-action {
          // width: 125px;
          // height: 32px;
          // height: 36px;
          margin-right: 8px;
        }
      }
    }

    .cargoBook-table {
      width: 100%;
      height: calc(100% - 77px);
      .td-operate {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        .operate-btn {
          font-size: 12px;
        }
        .operate-icon {
          width: 16px;
          height: 16px;
          display: flex;
          cursor: pointer;
        }
      }
      // height: calc(100% -136px);
    }

    .cargoBook-icon {
      width: 16px;
      height: 16px;
    }

    .headFixture-form {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin-bottom: 24px;
      .form-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, 210px);
        grid-template-rows: repeat(auto-fit, 56px);
        grid-gap: 12px 8px;
      }
    }
  }
}
.filter-content{
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}