.database-task {
	:global {
		.task-toolbar {
			.task-operator {
				display: flex;
				gap: 10px;
			}
		}
		.task-voyageType {
			padding: 0px 4px;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			border-radius: var(--spacing-1, 2px);
			&.TCTC {
				background: rgba(9, 209, 125, 0.12);
				color: #06b169;
			}

			&.TCVC {
				background: var(--color-bg-accent-fleetblue-subtler, #ebeef4);
				color: var(--color-bg-accent-fleetblue-bolder, #355691);
			}
		}
	}
}
