.invCommon-offHire {
	width: 1129px;
	padding: 20px 24px;
	display: flex;
	flex-direction: column;
	border-radius: 6px;
	background: #fff;
	gap: 16px;

	:global {
		.offHire-header {
			width: 100%;
			height: 23px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.header-icon {
				width: 16px;
				height: 16px;
				cursor: pointer;
				display: flex;
			}
		}

		.offHire-tabs {
			height: 32px;
			.ant-tabs-nav {
				padding-left: 0px;
			}
		}

		.offHire-container {
			width: 100%;
			height: 300px;
			display: flex;
			flex-direction: column;
			border-radius: 6px;
			// border: 1px solid var(--Table_Outline, #D2DAE4);
			border: 1px solid #d2dae4;

			.offHireItem-common {
				display: grid;
				grid-template-columns:40px 184.5px 184.5px 114px 115px 115px 250px 77px;
			}

			.offHireItem-header {
				width: 100%;
				height: 39px;
				// background: var(--Table-Bg_top, #EFF1F5);
				border-radius: 6px 6px 0px 0px;

				.offHireItem {
					border-bottom: 1px solid rgba(0, 0, 0, 0.06);
					background-color: #eff1f5;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					// color: var(--character-title-85, rgba(0, 0, 0, 0.85));
					color: rgba(0, 0, 0, 0.85);
					font-size: 12px;
					font-weight: 450;
					line-height: 22px;
					padding: 8px;
					position: relative;

					&::after {
						content: "";
						display: inline-block;
						width: 1px;
						background: rgba(0, 0, 0, 0.06);
						height: 22px;
						right: 0px;
						position: absolute;
					}

					/* 183.333% */
				}

				& > :first-child.offHireItem {
					border-radius: 6px 0px 0px 0px;
					/* 183.333% */
				}

				& > :last-child.offHireItem {
					border-radius: 0px 6px 0px 0px;

					&::after {
						display: none;
					}
				}
			}

			.offHireItem-scrollArea {
				width: 100%;
				height: calc(100% - 39px);
				overflow: hidden auto;
				border-radius: 0px 0px 6px 6px;

				.offHireItem-container {
					width: 100%;
					height: 39px;

					& > *.offHireItem {
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						padding: 4px 8px;

						border-bottom: 1px solid rgba(0, 0, 0, 0.06);
						// color: var(--character-title-85, rgba(0, 0, 0, 0.85));
						color: rgba(0, 0, 0, 0.85);

						font-size: 14px;
						font-weight: 400;
						line-height: 22px; /* 157.143% */
						& > * {
							width: 100%;
							height: 30px;
						}
						.offHireItem-icon {
							width: 30px;
							height: 30px;
							display: flex;
							cursor: pointer;
						}
						input {
							min-width: unset;
						}
					}
				}
			}
		}

		.offHire-count {
			border-radius: 6px;
			border: 1px solid #d9d9d9;
			width: 100%;
			height: 45px;
			padding: 5px 12px;
			display: flex;

			& > * {
				display: flex;
				align-items: center;
				color: #355691;
				font-size: 16px;
				font-weight: 500;
				line-height: normal;
				height: 100%;
			}

			& > :first-child {
				width: 400px;
				justify-content: center;
			}

			& > :last-child {
				width: 253px;
				justify-content: space-between;
			}
		}

		.offHire-footer {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			height: 40px;
			gap: 10px;
		}
	}
}
