.routeCommon-timeAxis {
	width: 100%;
	height: 28px;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
	border-radius: 14px;
	background: #fff;
	display: flex;
	:global {
	}
}
