.movement-portRotation {
  width: 100%;
  display: flex;
  flex-direction: column;

  :global {

    .portRotation-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .portRotation-container--toolbars {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .movement-details--header {
          .fleet-title {
            line-height: 22px;

            &::before {
              background: rgba(53, 86, 145, 0.50);
            }
          }
        }

        .toolbars {
          display: flex;
          gap: 12px;

          .toolbars-item {
            display: flex;
            gap: 6px;
            align-items: center;

            &>:first-child {
              color: #484848;
              font-size: 12px;
              font-weight: 400;
              line-height: 22px;
              white-space: nowrap;
            }

            &>.toolbars-input {
              width: 54px;

              input {
                min-width: unset;
              }
            }
          }
          .add-port-button{
            width:90px;
          }
        }
      }

      .portRotation-container--portGroup {
        height: fit-content;
        display: block;
        .portGroup-disabled {
          background-color: rgba(0, 0, 0, 0.04);
          width: 100%;
          height: 28px;
          cursor: not-allowed;
          border-radius: 4px;
        }
        .port-actions {
          &.move {
            gap: 4px;
            justify-content: space-between;
            align-items: center;
            .port-moveActions {
              display: flex;
              flex-direction: column;
              &>* {
                font-size: 16px;
                line-height: 1;
                cursor: pointer;
              }
              &>:first-child {
                transform: rotateZ(90deg);
              }
              &>:last-child {
                transform: rotateZ(-90deg);
              }
            }
          }
        }
      }
    }
  }
}