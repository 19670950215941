.estimation-template {
  padding: 16px;
  border-radius: 4px;
  background: var(--Conditional-pop-over, #FFF);
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 236px;
  :global {
    .template-actions {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      gap: 8px;
      // &>* {
      //   height: 24px;
      //   font-size: 14px;
      //   line-height: 22px;
      //   padding: 0px 7px;
      // }
    }
  }
}