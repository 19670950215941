.fleet-voyagemanage {
  width: 100%;
  height: calc(100% - 64px);
  overflow: hidden;

  :global {
    .voyagemanage-common {
      width: 100%;
      height: calc(100% - 49px);
      .vm-common-title {
        height: 36px;
        width: 100%;
        padding: 0px 0px 16px;
        border-bottom: 1px solid #D9D9D9;
        color: #484848;
        
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 16px;
      }
    }
  }
}