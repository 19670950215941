.invoicesList-filter {
  width: 100%;
  height: 102px;
  display: flex;
  padding: 18px 12px 12px;
  flex-direction: column;
  gap: 12px;
  border-bottom: 1px solid var(--color-line-separator, #E7E9EC);

  :global {
    .filter-title {
      color: var(--color-text-body-1, #151515);
      font-size: 20px;
      font-weight: 590;
      line-height: 28px;
      /* 140% */
    }

    .filter-container {
      width: 100%;
      height: 32px;
      display: flex;
      gap: 8px;

      .filter-select {
        width: calc(100% - 40px);
      }

      .filter-add {
        font-size: 16px;
      }
    }
  }
}