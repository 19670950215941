.estimation-newly {
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
  border-radius: 6px;
  background: #FFF;
  gap: 12px;

  :global {
    .estimation-newly--container {
      display: flex;
      gap: 14px;
      // height: 662px;

      .estimation-newly--lines {
        height: 100%;
        width: 1px;
        background: rgba(0, 0, 0, 0.10);
      }

      .estimation-newly--item {
        width: 282px;
        border-radius: 6px;
        border: 1px solid #D9D9D9;
        background: #F5F5F5;
        padding: 12px 16px;
        height: 100%;
        gap: 10px;
        display: flex;
        flex-direction: column;

        .newly-item--header {
          width: 100%;
          display: flex;
          padding-bottom: 10px;
          border-bottom: 1px solid #E7E7E7;
          justify-content: space-between;
          align-items: center;

          .header-title {
            color: #484848;
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
          }

          .header-actions {
            display: flex;
            gap: 12px;
            align-items: center;

            &>* {
              font-size: 16px;
              cursor: pointer;
            }

            &>:last-child {
              color: #F15D5D;
            }
          }
        }

        .newly-item--container {
          width: 100%;
          // height: calc(100% - 40px);
          // overflow: hidden;

          .newly-item--scrollArea {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 4px;

            .newly-item--formItem {
              width: 100%;
              height: 56px;
              display: grid;
              // margin-bottom: 4px;
              grid-template-rows: repeat(auto-fit, 56px);
              align-items: flex-end;

              &.full {
                grid-template-columns: repeat(auto-fit, 100%);
              }

              &.ninetyPercent {
                grid-template-columns: 212px 32px;
                gap: 4px;
              }

              &.trisection {
                grid-template-columns: repeat(auto-fit, calc((100% - 8px)/3));
                gap: 4px;
              }

              &.untrisection {
                grid-template-columns: 122px 70px 48px;
                gap: 4px;
              }

              &.bisector {
                grid-template-columns: repeat(auto-fit, calc((100% - 4px)/2));
                gap: 4px;
              }

              &.unbisector {
                grid-template-columns: 152px 92px;
                gap: 4px;
              }

              &.eightyPercent {
                grid-template-columns: 176px 71px;
                gap: 4px;
              }

              input {
                min-width: unset;
              }

            }
          }
        }

        &.scroll-allowed {
          width: 288px;

          .newly-item--container {
            overflow: hidden auto;
            padding-right: 2px;
            &::-webkit-scrollbar {
              width: 4px;
              height: 0px;
              // display: none;
            }

            &::-webkit-scrollbar-corner {
              background-color: none;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 2px;
              background-color: rgba(53, 86, 145, 0.18);
              border: 1px solid rgb(219, 219, 219);
            }

            &::-webkit-scrollbar-track {
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
}