.portReport-stowagePlan {
  width: 100%;
  height: 440px;

  :global {
    .stowage-plan-table {
      height: 380px;
      overflow-x: auto;
      overflow-y: auto;
      &::-webkit-scrollbar {
        height: 6px;
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        height: 6px;
        width: 6px;
        border-radius: 3px;
        background: #d2d2d2;
      }
      table {
        width: 100%;
        height: 100%;
        table-layout: fixed;
        border-left: 1px solid rgba(0, 0, 0, 0.06);
        border-right: 1px solid rgba(0, 0, 0, 0.06); 
        .stowage-plan-table-thead {   
          tr {   
            th {
              height: 36px;
              color: rgba(0, 0, 0, 0.85);
              text-align: center;
              font-family: "SF Pro";
              font-size: 14px;
              font-weight: 400;
              line-height: 36px;
              border: 1px solid rgba(0, 0, 0, 0.06);
              background: #f8f8f9;              
              position: sticky;
              top:0;
              z-index: 5; 
            }
            th:first-child,
            th:nth-last-child(2),
            th:last-child {
              position: sticky;
              left: 0;
              right: 0;
              background: #f8f8f9;
            }
            th:last-child {
              width: 120px;
            }
            th:nth-last-child(2) {
              width: 120px;
            }
            th:nth-last-child(2) {
              right: 120px;
            }
          }
        }
        .stowage-plan-table-tbody { 
          tr {
            td {
              height: 42px;
              text-align: center;
              padding: 3px 6px;
              font-size: 14px;
              text-align: left;
              border: 1px solid rgba(0, 0, 0, 0.06);
              input {
                min-width: 50px;
                width: 100%;
              }
            }
          }
        }
        .stowage-plan-table-tfoot {
          background: #f8f8f9;
          height: 36px;
          position: sticky;
          bottom: 0;
          z-index: 5;
          tr {
            td {
              padding: 3px 8px;
              color: #222;
              font-family: "SF Pro";
              font-size: 14px;
              font-weight: 400;
              line-height: 36px;
              border-top: 1px solid #F0F0F0;
              border-bottom: 1px solid #F0F0F0;
            }
          }
        }
      }
    }
  }
}
