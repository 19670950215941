.routeCommon-toolbar {
	box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
	display: flex;
	flex-direction: column;
	padding: 4px;
	background: #fff;
	border-radius: 6px;
	position: absolute;
	top: 22px;
	right: 22px;
	z-index: 98;
	gap: 8px;
	:global {
		.meteorological-actions {
			display: flex;
			gap: 4px;
			flex-direction: column;
			align-items: center;
			&::after {
				height: 1px;
				width: 26px;
				background-color: rgb(204, 204, 204);
				display: inline-block;
				content: "";
				margin-top: 4px;
			}
			.meteorological-item {
				background: #fff;
				border-color: #ccc;
				&:hover {
					background: rgba(255, 255, 255, 0);
				}
				&.active {
					background: #355691;
					border-color: #355691;
				}
			}
		}
	}
	:last-child {
		&::after {
			display: none;
		}
	}
}
