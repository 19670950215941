.route-portOtherIntervals {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 5px;
	:global {
		.portOtherIntervals-tip {
			color: #b3b3b3;
			font-size: 12px;
			font-weight: 400;
			line-height: 22px; /* 183.333% */
		}
	}
}
