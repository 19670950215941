.routeCommon-aisItem {
	width: 100%;
	display: flex;
	gap: 7px;
	align-items: center;
	padding: 8px 6px 8px 3px;
	transition: all 300ms ease-in-out;
	user-select: none;
	&:hover {
		background: rgba(76, 114, 215, 0.06);
		border-radius: 4px;
		:first-child {
			visibility: visible;
			transition: all 300ms ease-in-out;
		}
	}
	:global {
		.aisItem-moveIcon {
			visibility: hidden;
			cursor: move;
		}
		.aisItem-container {
			display: flex;
			flex-direction: column;
			width: calc(100% - 23px);
			gap: 4px;
			.aisItem-operator {
				width: 100%;
				display: flex;
				justify-content: space-between;
				& > :first-child {
					color: #333;
					font-size: 14px;
					font-weight: 400;
					line-height: 20px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
				.aisItem-tools {
					display: flex;
					align-items: center;
					gap: 8px;
					& > .warning {
						color: rgba(236, 34, 31, 1);
					}
				}
			}
			.aisItem-otherInfo {
				width: 100%;
				display: flex;
				align-items: center;
				color: rgba(117, 117, 117, 1);
				font-size: 13px;
				font-weight: 400;
				line-height: 18px;
				justify-content: space-between;
			}
		}
	}
}

.routeCommon-aisItem--active {
	background: rgba(76, 114, 215, 0.06);
	border-radius: 4px;
	:global {
		.aisItem-container {
			.aisItem-tools {
				& > .routeing {
					color: rgba(40, 83, 195, 1);
				}
			}
		}
	}
}
