.portReport-basicInfo {
	width: 100%;
	height: 500px;

	:global {
		table {
			width: 100%;
		}
		.basic-info-table-tr {
			td {
				height: 54px;
				text-align: center;
				padding: 2px 10px;
				font-size: 12px;
				border: 1px solid rgba(0, 0, 0, 0.06);
			}
			.basic-info-table-header {
				background: #f8f8f9;
				overflow: hidden;
				color: #626262;
				text-align: center;
				text-overflow: ellipsis;
				font-family: "SF Pro";
				font-size: 14px;
				font-weight: 400;
				line-height: 22px; /* 157.143% */
				.basic-info-table-cell {
					height: 100%;
					width: 100%;
					line-height: 45px;
					padding: 0;
				}
			}
			.basic-info-table-input {
				color: #000;
				text-align: left;
				font-family: "SF Pro";
				font-size: 14px;
				font-weight: 400;
				line-height: 22px; /* 157.143% */
				.basic-info-table-cell {
					height: 100%;
					width: 100%;
					line-height: 45px;
					padding: 0;
					display: flex;
					align-items: center;
				}
			}
			td:first-child,
			td:nth-child(3),
			td:nth-child(5) {
				min-width: 240px;
			}
			td:nth-child(2),
			td:nth-child(4),
			td:nth-child(6) {
				width: 180px;
				padding: 3px 6px;
				input {
					min-width: 120px !important;
					width: 120px;
				}
			}
		}
		// .basic-info-table-tr:nth-child(3){
		//   td:nth-child(4){
		//     min-width:540px;
		//     padding:3px 6px;
		//     input{
		//       min-width:540px !important;
		//     }
		//   }
		// }
	}
}
