.voyageManage-voyageRemark {
	width: 100%;
	height: 100%;
	overflow: hidden auto;
	position: relative;

	:global {
		.voyageRemark-scrollArea {
			display: flex;
			flex-direction: column;
			gap: 16px;
			width: 100%;
			padding-top: 16px;
			padding-bottom: 16px;

			.voyageRemark--common {
				width: 100%;
				border-radius: 4px;
				border: 1px solid rgba(0, 0, 0, 0.1);
				background: #fbfbfb;
				display: flex;
				flex-direction: column;
				padding: 12px;
				gap: 16px;

				.voyageRemark--common--header {
					width: 100%;
					color: var(--color-text-body-1, #151515);
					font-size: 16px;
					font-weight: 590;
					line-height: 24px;
					padding-bottom: 8px;
					border-bottom: 1px solid #e7e9ec;

					&.secondary {
						color: var(--color-text-body-3, #a3a3a3);
					}
				}

				.voyageRemark--remarks--textarea {
					width: 100%;
					background: rgba(250, 250, 250, 0.6);
					height: 139px;
				}
			}

			.voyageManage-voyageRemark-container {
				display: flex;
				flex-direction: column;
				gap: 16px;
				width: 100%;
			}
		}
	}
}