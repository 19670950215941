.movement-taskDetails-taskList {
  width: 726px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  :global {
    .taskList-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .taskList-header--tag {
        background: #E0E6F0;
        padding: 4px 15px;
        border-radius: 4px;
        background: #E0E6F0;
        color: #151515;
        font-size: 16px;
        font-weight: 510;
        line-height: 24px;
        height: fit-content;
        /* 150% */
      }

      &>:first-child {
        background: #E0E6F0;
      }

      .taskList-header--filter {
        display: flex;
        gap: 8px;

        &>* {
          width: 152px;
        }
      }
    }

    .taskList-container {
      width: 100%;
      height: calc(100% - 68px);
      border-radius: 4px;
      border: 1px solid #D9D9D9;
      background: #F8F8F9;
      padding: 12px;
      overflow: hidden auto;

      .taskList-scrollArea {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 14px;

        &>* {
          &.active {
            border: 1px solid #3770A9;
            background: rgba(55, 112, 169, 0.03);
          }
        }
      }

      &::-webkit-scrollbar {
        width: 0px;
        height: 4px;
        // display: none;
      }

      &::-webkit-scrollbar-corner {
        background-color: none;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: rgba(53, 86, 145, 0.18);
        border: 1px solid rgb(219, 219, 219);
      }

      &::-webkit-scrollbar-track {
        border-radius: 2px;
      }
    }
  }
}