.estimation-quicklockItem-container {
  min-width: 195px;
  width: calc(25% - 2px);
  height: 94px;
  position: relative;
  // background: rgba(53, 86, 145, 0.05);
  border-radius: 6px;
  margin-right: 8px;
}

.estimation-quicklockItem {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 12px;
  border-radius: 6px;
  border: 1px solid rgba(217, 217, 217, 0.87);
  background: rgba(53, 86, 145, 0.05);
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  backface-visibility: hidden;
  transition: all 0.4s ease-in-out;

  :global {
    .quicklockItem {
      width: 100%;
      height: 22px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4px;

      .header-left {
        display: flex;
        width: 50%;
        align-items: center;

        &>span {
          color: #626262;
          font-size: 12px;
          font-weight: 500;
          line-height: 22px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        /* 157.143% */
        .header-extends {
          margin-left: 7.5px;
          background: rgba(70, 121, 150, 0.60);
          width: 17px;
          height: 17px;
          justify-content: center;
          align-items: center;
          border-radius: 8.5px;
          display: flex;
          cursor: pointer;

          img {
            width: 14px;
            height: 14px;
          }
        }
      }

      .header-right {
        width: 50%;
        color: #484848;
        
        font-size: 12px;
        font-weight: 600;
        line-height: 22px;
        display: flex;
        justify-content: flex-end;

        &>:first-child {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-right: 3px;
        }

        /* 137.5% */
      }

      .quicklockItem-content {
        display: flex;
        width: 50%;
        justify-content: flex-end;

        &>span {
          color: #989898;

          /* Body/regular */
          
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }

        &>:first-child {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-right: 3px;
        }
      }

      .content-text {
        color: #989898;
        width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        /* Body/regular */
        
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
      }
    }

    :last-child.quicklockItem {
      margin-bottom: 0px;
    }
  }
}

.quicklockItem-flip {
  transform: rotateY(0deg);
}

.quicklockItem-flip--reverse {
  transform: rotateY(-180deg);
}

.quicklockItem-normal {
  transform: rotateY(180deg);
}

.quicklockItem-normal--reverse {
  transform: rotateY(0deg);
}

.estimation-quicklock {
  width: 100%;
  height: 142px;
  display: flex;
  flex-direction: column;
  margin-bottom: 17px;

  :global {
    .qiucklock-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 36px;
      align-items: center;
      margin-bottom: 12px;

      .header-title {
        display: flex;
        height: 100%;

        .quicklock-duration {
          display: flex;
          margin-left: 10px;
          align-items: center;

          &>span {
            color: #626262;
            
            font-size: 12px;
            font-weight: 500;
            line-height: 22px;
            font-size: 16px;
            font-weight: 500;
            color: rgb(162 162 163);
          }

          &>span:first-child {
            margin-right: 3px;
          }

          &>span:last-child {}
        }
      }


      .header-actions {
        display: flex;
        height: 100%;
        gap: 8px;
        &>* {
          height: 100%;
        }
        &>:first-child {
          border-radius: 50%;
          width: 36px;
        }
        .performance-insight-icon{
          margin-top:6px;
        }
      }
    }

    .quicklock-container {
      width: 100%;
      height: calc(100% - 48px);
      justify-content: space-around;
      display: flex;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}