.movementSeareport-segment {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 8px;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  padding: 12px;
  gap: 6px;
  :global {
    .segment-header {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }
    .segment-container {
      width: 540px;
      height: 150px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        width: 6px;
        border-radius: 3px;
        background: #d2d2d2;
      }
      table {
        width: 100%;
        border-radius: 6px;
        overflow: hidden;
        table-layout: fixed;
        .segmentTable-thead {
          background-color: #eff1f5;
          font-size: 14px;
          height: 32px;
          line-height: 32px;
          padding: 0 8px;
          position: sticky;
          top: 0;
          z-index: 5;
          th {
            font-size: 14px;
            font-weight: 500;
          }
        }
        .segmentTable-tbody {
          td {
            width: 100%;
            padding: 4px 2px;
            text-align: center;
            // display: flex;
            // align-items: center;
            .ant-picker {
              min-width: 120px;
            }
            input {
              min-width: 60px;
            }
          }
        }
      }
    }
    .warning-foot {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 8px;
    }
  }
}
