.invoices-addTo {
  display: flex;
  gap: 10px;
  align-items: center;
  :global{
    .addTo-radio {
      .radio-label {
        color: var(--color-text-body-3, #A3A3A3);
        font-size: 12px;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }
}