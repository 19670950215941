.movement-seaReport-lat-lon-input {  
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 8px;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  padding: 12px;
  :global{
    .seaReport-lat-lon-input-title{
      height:28px;
      line-height: 28px;
      font-size: 14px;
    }
    .seaReport-lat-lon-input-content{
      display: flex !important;
      flex-direction: row;
      justify-content: space-between;
      gap: 6px;
      font-size: 20px;
      input {
        width: 70px;
        min-width: 70px;
      }
      div:first-child,
      div:nth-child(2) {
        // width: 70px;
        height: 36px;
      }
      .lat-lon-select {
        width: 60px;
      }
    }
  }
}
.movement-seaReport-lat-lon-div {
  width: 100%;
  height: 24px;
}
