.layout-header-filter {
  display: flex;

  :global {
    .filter-input {
      width: 254px;
      margin-right: 20px;
    }

    .filter-avatar {
      width: 32px;
      height: 32px;
      background-color: rgba(53, 86, 145, 1);
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      >span {
        color: #FFF;
        
        font-size: 24px;
        font-weight: 900;
      }
    }
  }
}

.layout-header-menu {
  width: 150px;
  display: flex;
  flex-direction: column;
  padding: 4px;
  border-radius: 6px;
  background: #FFF;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
  :global{
    .menu-item {
      width: 100%;
      padding: 8px;
      height: 32px;
      display: flex;
      align-items: center;
      cursor: pointer;
      .menu-item-icon {
        margin-right: 8px;
        width: 16px;
        height: 16px;
        display: flex;
      }
  
      .menu-item-text {
        width: calc(100% - 24px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #484848;
        
        font-size: 14px;
        font-weight: 400;
      }
  
      &:hover {
        border-radius: 6px;
        background:  rgba(239, 241, 245, 0.60);
      }
    }
  }
}