.invocies-statement {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 6px 6px 0px 0px;
  margin-top: 64px;
  margin-bottom: 20px;
  :global {
    .statement-fields {
      display: flex;
      gap: 4px;
      align-items: flex-end;
      &>:first-child {
        width: calc(100% - 36px);
      }
      &>:last-child {
        width: 32px;
        height: 32px;
        display: flex;
        cursor: pointer;
      }
    }
  }
}