.invoices-freight {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 6px 6px 0px 0px;
  margin-top: 64px;
  margin-bottom: 20px;
  :global {
    .invoices-base--header {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, calc((100% - 24px) / 4));
      grid-template-rows: repeat(auto-fit, 56px);
      gap: 8px;
    }
    .freight-fields {
      display: flex;
      gap: 4px;
      align-items: flex-end;
      &>:first-child {
        width: calc(100% - 36px);
      }
      &>:last-child {
        width: 32px;
        height: 32px;
        display: flex;
        cursor: pointer;
      }
    }
  }
}