.fleet-estimation {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: calc(100% - 64px);
  :global{
    .estimation-common {
      width: 100%;
      height: 100%;
    }
  }
}
