/*********************************************************
**                                                      **
**       Leaflet Plugin "Leaflet.PolylineMeasure"       **
**       File "Leaflet.PolylineMeasure.css"             **
**       Date: 2021-10-05                               **
**                                                      **
*********************************************************/

.leaflet-control {
	cursor: pointer;
}

a.polyline-measure-controlOnBgColor,
a.polyline-measure-controlOnBgColor:hover {
	background-color: #345692;
	color: white;
}

.measure-btn-svg {
	fill: rgb(85, 85, 85);
}

a.polyline-measure-controlOnBgColor .measure-btn-svg {
	stroke: #345692;
	fill: #345692;
}

.polyline-measure-unicode-icon {
	font-size: 19px;
	font-weight: bold;
}

a.polyline-measure-clearControl:active,
#unitControlId:active {
	background-color: #345692;
	color: white;
}

.polyline-measure-tooltip {
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
	padding: 2px 8px;
	width: auto !important;
	height: auto !important;
	white-space: nowrap;
}

.polyline-measure-tooltip-end {
	/* background-color: rgba(250, 250, 247, 0.7); */
}

.polyline-measure-tooltip-total {
	color: rgba(53, 86, 145, 1);
	font-weight: 500;
	font-size: 14px;
	margin-right: 12px;
}

.polyline-measure-tooltip-difference {
	font-weight: 500;
	font-size: 14px;
	color: rgba(82, 196, 26, 1);
}

.polyline-measure-popupTooltip {
	font:
		11px Arial,
		Helvetica,
		sans-serif;
	line-height: 11px;
}

.polyline-measure-tooltip-label {
	font-size: 12px;
	color: rgba(157, 157, 157, 1);
	font-weight: 400;
	line-height: 22px;
}

.polyline-measure-tooltip-position {
	font-size: 14px;
	color: rgba(72, 72, 72, 1);
	font-weight: 400;
	line-height: 22px;
	margin-bottom: 4px;
}

.polyline-measure-tooltip--label {
	color: #9d9d9d;
	font-size: 12px;
	font-weight: 400;
	line-height: 22px;
}

.polyline-measure-tooltip--value {
	color: #355691;
	font-size: 12px;
	font-weight: 500;
	line-height: 22px;
}

.polyline-measure-marker--label {
	color: #355691;
	-webkit-text-stroke-width: thin;
	-webkit-text-stroke-color: #fff;
	font-size: 14px;
	font-weight: 600;
	line-height: 22px; /* 183.333% */
	text-wrap: nowrap;
}
