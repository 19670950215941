.fleet-image {
  position: relative;

  :global {
    img {
      width: 100%;
      height: 100%;
      border-radius: inherit;
    }
  }
}