.fleet-404 {
  :global {
    .errorPage {
      width: 100%;
      min-width: 600px;
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: center;
      padding-top: 275px;

      .errorPage-logo {
        width: 77px;
        height: 40px;
        margin-bottom: 25px;

      }

      .errorPage-logoTitle {
        width: 236px;
        height: 34px;
        margin-bottom: 31px;
      }

      .errorPage-image {
        width: 235.5px;
        height: 235.5px;
        margin-bottom: 35.5px;
      }

      .errorPage-404 {
        color: rgba(0, 0, 0, 0.88);
        text-align: center;
        
        font-size: 32px;
        font-weight: 400;
        line-height: 32px;
        margin-bottom: 10px;
      }

      .errorPage-tip {
        color: rgba(0, 0, 0, 0.45);
        
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 35px;
      }

      .errorPage-operation {
        display: flex;
        gap: 12px;
      }

      .errorPage-back {
        
      }

    }
  }
}