.esdetails-freightSen {
  padding: 20px 24px;
  width: 629px;
  height: 767px;
  display: flex;
  flex-direction: column;
  background: #FFF;
  border-radius: 6px;
  position: relative;

  :global {
    .freightSen-header {
      width: 100%;
      height: 23px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      .details-title {
        font-size: 20px;
      }

      .header-close {
        width: 16px;
        height: 16px;
        display: flex;
      }
    }

    .freightSen-container {
      width: 100%;
      height: calc(100% - 91px);
      margin-bottom: 16px;
      display: flex;
      border: 1px solid rgba(0, 0, 0, 0.06);
      background: #FAFAFA;
      border-radius: 6px;

      .freightSen {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 6px 0px 0px 6px;

        .freightSen-label {

          width: 100%;
          height: 39px;
          display: flex;
          justify-content: flex-start;
          background: #FAFAFA;
          padding: 8px;
          padding-right: 0px;
          border-radius: 6px 0px 0px 0px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);

          &>span {
            width: 100%;
            color: rgba(0, 0, 0, 0.85);
            
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            display: flex;
            align-items: center;
            border-right: 1px solid rgba(0, 0, 0, 0.06);

            &::before {
              content: "";
              display: inline-block;
              width: 6px;
              height: 6px;
              border-radius: 11px;
              background: #254377;
              margin-right: 4px;

            }
          }
        }

        .freightSen-label__tce {
          border-radius: 0px 6px 0px 0px;

          &>span {
            border-right: 0px;
          }
        }

        .freightSen-label__result {
          border-radius: 0px;
        }

        .freightSen-input {
          width: 100%;
          height: 67px;
          padding: 6px 8px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);

          &>.verity-text {
            margin-bottom: 0px;
          }
        }
      }

      &>:last-child.freightSen {
        border-radius: 0px 6px 6px 0px;
      }
    }

    .freightSen-footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 36px;
    }
  }
}

.fr-table {
  width: 100%;
  display: flex;
  flex-direction: column;

  :global {
    .fr-headTr {
      width: 100%;
      height: 39px;
      padding: 8px;
      display: flex;
      border-bottom: 1px solid  rgba(0, 0, 0, 0.06);


      &>span {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #707070;
        
        font-size: 12px;
        font-weight: 500;
        line-height: 22px;
        padding-right: 2px;

        /* 183.333% */
      }

      &>span:last-child {
        padding-left: 2px;
      }
    }

    .fr-tr {
      width: 100%;
      height: 35px;
      display: flex;
      border-bottom: 1px solid  rgba(0, 0, 0, 0.06);

      &>span {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #707070;
        padding: 6px 8px;
        /* Body/regular */
        
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
      }
    }

    .fr-tr__tce {
      border-right: 1px solid  rgba(0, 0, 0, 0.06);
    }

    :last-child.fr-tr  {
      border-bottom: 0px;
    }
  }
}