.invoices-remitAmount {
  width: 100%;
  padding-top: 30px;
  border-top: 1px solid var(--color-line-separator, #E7E9EC);
  display: flex;
  flex-direction: column;
  gap: 2px;

  :global {
    .remitAmount-title {
      color: var(--color-text-body-2, #7D7D7D);
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }

    .remitAmount-summary {
      color: var(--color-text-body-1, #151515);
      font-size: 30px;
      font-weight: 590;
      line-height: 38px;
      color: #EC344F;

      &::before {
        display: inline-block;
        color: inherit;
        font-size: 30px;
        font-weight: 590;
        line-height: 38px;
        margin-right: 8px;
        content: "$";
      }

      &.fall-down {
        color: #00D62F;
      }
    }

  }
}