.preOperation-details {
	width: calc(100% - 397px);
	height: 100%;
	position: relative;
	border-left: 1px solid #e1e1e1;
	background: #fff;
	padding: 38px 25px 0px;
	display: flex;
	flex-direction: column;

	:global {
		.preOperation-header {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 37px;
			margin-bottom: 36px;

			.header-tags {
				display: flex;
				align-items: center;
				gap: 16px;
				& > :first-child {
					color: #484848;
					font-size: 24px;
					font-weight: 500;
					line-height: 28px;
				}
				& > .voyage-type {
					padding: 0px 4px;
					border-radius: var(--spacing-1, 2px);
					color: var(--color-bg-accent-fleetblue-bolder, #355691);
					font-size: 14px;
					font-weight: 400;
					line-height: 22px;
					&.TCTC {
						background: rgba(9, 209, 125, 0.12);
						color: #06b169;
					}
					&.TCVC {
						background: var(--color-bg-accent-fleetblue-subtler, #ebeef4);
						color: var(--color-bg-accent-fleetblue-bolder, #355691);
					}
				}
			}

			.header-actions {
				display: flex;
				gap: 8px;
				justify-content: flex-end;
				align-items: center;

				& > .update-container {
					display: flex;
					flex-direction: column;
					align-items: center;
					color: var(--Normal-Text, #adadad);
					font-size: 12px;
					font-weight: 400;
					line-height: 15px;
					/* 125% */
				}
			}
		}
		.preOperation-tabs {
			height: 32px;
			.ant-tabs-nav {
				padding-left: 0px;
			}
		}
		.preOperation-conatainer {
			width: 100%;
			height: calc(100% - 105px);
			overflow: hidden;
			.preOperation-handoverForm {
				padding-top: 16px;
			}
		}
	}
}
