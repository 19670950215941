.common-select--company {
	width: 100%;
	height: fit-content;
}

.common-select--company--newly {
	display: flex;
	gap: 4px;
	align-items: flex-end;

	:global {
		.company-select--container {
			width: calc(100% - 36px);
		}
	}
}
