.legend-vessels {
	display: flex;
	align-items: center;
	gap: 4px;
	:global {
		.vessels-title {
			color: rgba(30, 30, 30, 1);
			font-size: 12px;
			font-weight: 700;
			line-height: 17px;
		}
		.vessels-container {
			display: flex;
			gap: 6px;
			& > * {
				display: flex;
				gap: 6px;
        align-items: center;
				& > :last-child {
					color: rgba(30, 30, 30, 1);
					font-size: 12px;
					font-weight: 700;
					line-height: 17px;
				}
			}
		}
	}
}
