.routeCoommon-tabs {
	position: absolute;
	left: 332px;
	top: 21px;
	z-index: 98;
	box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.06);
	border-radius: 6px;
	display: flex;
	:global {
		.tabs-container {
			width: 130px;
			.ant-select-selector {
				border-radius: 6px 0px 0px 6px;
			}
			.routeCommon-tab--active {
				display: flex;
				align-items: center;
				gap: 8px;
				&::before {
					display: inline-block;
					content: "";
					width: 6px;
					height: 6px;
					border-radius: 3px;
				}
				&.leg {
					&::before {
						background-color: #1e1e1e;
					}
				}
				&.ais {
					&::before {
						background-color: #5e89f7;
					}
				}
				&.area {
					&::before {
						background-color: #fd881c;
					}
				}
				&.port {
					&::before {
						background-color: #f5cdcd;
					}
				}
			}
		}
		.tabs-visible {
			padding: 0px 10px;
			background-color: #fff;
			border-radius: 0px 6px 6px 0px;
			height: 32px;
			border: 1px solid #d9d9d9;
			border-left: 0px;
			display: flex;
			align-items: center;
			cursor: pointer;
		}
	}
}
