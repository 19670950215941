.common-category {
	display: flex;
	position: relative;
	flex-direction: column;
	border-radius: 6px;
	background: #fff;
	padding: 10px;
	gap: 12px;
	:global {
		.category-header {
			display: flex;
			height: 32px;
			color: #707070;
			font-size: 14px;
			font-weight: 500;
			align-items: center;
			gap: 12px;
		}
		.category-container {
			display: flex;
			overflow: auto hidden;
			&::-webkit-scrollbar {
				width: 0px;
				height: 4px;
				// display: none;
			}

			&::-webkit-scrollbar-corner {
				background-color: none;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 2px;
				background-color: rgba(53, 86, 145, 0.18);
				border: 1px solid rgb(219, 219, 219);
			}

			&::-webkit-scrollbar-track {
				border-radius: 2px;
			}

			scrollbar-width: none;
			/* Firefox */
			-ms-overflow-style: none;
		}
	}
}
