.movement-sea-reports-table {
	width: 100%;
	height: 100%;

	:global {
		.sea-reports-table {
			height: 680px;
			// max-height: 680px;
			margin: 8px 0;
			overflow-x: auto;
			overflow-y: auto;
			&::-webkit-scrollbar {
				height: 8px;
				width: 8px;
			}
			&::-webkit-scrollbar-thumb {
				height: 8px;
				width: 8px;
				border-radius: 4px;
				background: #d2d2d2;
			}
			table {
				table-layout: fixed;
				width: 100%;
				height: 680px;
				.sea-reports-table-col {
					width: 100px;
				}
				.sea-reports-table-type {
					width: 150px;
				}
				.sea-reports-table-remarks {
					width: 450px;
				}
				.sea-reports-table-meRevolutionCounter {
					width: 150px;
				}
				.sea-reports-table-foFlowMeter {
					width: 150px;
				}
				.sea-reports-table-timeOfNextPort,
				.sea-reports-table-reportDateStr,
				.sea-reports-table-positionStr {
					width: 180px;
				}
				.sea-reports-table-col:first-child {
					width: 45px;
				}
				.sea-reports-table-col:nth-child(2) {
					width: 100px;
				}
				.sea-reports-table-col:nth-child(4) {
					width: 180px;
				}
				.sea-reports-table-col:last-child {
					width: 140px;
				}
			}
			thead {
				position: sticky;
				top: 0;
				background: #eff1f5;
				z-index: 5;
				th {
					padding: 2px 10px;
					border-right: 1px solid rgba(0, 0, 0, 0.06);
					border-bottom: 1px solid rgba(0, 0, 0, 0.06);
					color: rgba(0, 0, 0, 0.85);
					text-align: center;
					font-family: "SF Pro";
					font-size: 12px;
					font-weight: 500;
					line-height: 22px; /* 183.333% */
					position: sticky;
					top: 0;
				}

				tr:first-child {
					th:first-child,
					th:nth-child(2),
					th:nth-child(3),
					th:nth-last-child(2),
					th:last-child {
						position: sticky;
						left: 0;
						right: 0;
						top: 0;
						background: #eff1f5;
						border-left: 1px solid rgba(0, 0, 0, 0.06);
						z-index: 3;
					}
					th:nth-last-child(2) {
						right: 140px;
						box-shadow: -2px 0px 2px 0px rgb(171 171 171 / 50%);
					}
					th:nth-child(2) {
						left: 45px;
						z-index: 5;
					}
					th:nth-child(3) {
						left: 145px;
						z-index: 8;
						box-shadow: 2px 0px 2px 0px rgb(171 171 171 / 50%);
					}
				}
			}
			tbody {
				z-index: -1;
				tr {
					border-bottom: 1px solid rgba(0, 0, 0, 0.06);
					max-height: 45px;
					td {
						height: 45px;
						text-align: center;
						padding: 6px 10px;
						font-size: 12px;
						border-right: 0.5px solid rgba(0, 0, 0, 0.06);
						border-bottom: 0.5px solid rgba(0, 0, 0, 0.06);
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
					}

					td:first-child,
					td:nth-child(2),
					td:nth-child(3),
					td:nth-last-child(2),
					td:last-child {
						position: sticky;
						left: 0;
						text-align: center;
						right: 0px;
						background: #fff;
						width: 180px;
						border-left: 1px solid rgba(0, 0, 0, 0.06);
						z-index: 3;
					}
					td:nth-last-child(2) {
						right: 140px;
						box-shadow: -2px 0px 2px 0px rgb(171 171 171 / 50%);
					}
					td:nth-child(2) {
						left: 45px;
						z-index: 3;
					}
					td:nth-child(3) {
						left: 145px;
						z-index: 4;
						box-shadow: 2px 0px 2px 0px rgb(171 171 171 / 50%);
					}
					.warning-bg-light {
						background-color: #fff6a9;
					}
					.warning-bg-weight {
						background-color: #ffe0e0;
					}
					.warning-text {
						color: red;
					}
					.sea-reports-table-alarm {
						color: #ff9213;
						gap: 10px;
						.table-alarm-number {
							color: #333;
							margin-left: 6px;
						}
						.zero-warning {
							color: #b7b7b7;
						}
					}
				}
				tr:last-child {
					border: 0;
				}
				.state {
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.state-btn {
					height: 24px;
					width: 70px;
					border-radius: 2px;
					padding: 0px 8px;
					text-align: center;
					border: 1px solid rgba(53, 86, 145, 0.4);
					background: rgba(53, 86, 145, 0.06);
					color: #3f63a3;
					font-family: Roboto;
					font-size: 12px;
					font-weight: 400;
					line-height: 20px;
				}
				.state-btn-not {
					width: 50px;
					border: 1px solid rgba(223, 143, 50, 0.45);
					background: #ffeedf;
					color: #ff9213;
				}
				.action-btn {
					display: flex;
					flex-direction: row;
					justify-content: center;
				}
				.action-btn-del {
					color: red;
					cursor: pointer;
					margin-left: 8px;
				}
				.table-input {
					input {
						min-width: 100%;
					}
				}
				.table-showing-detail {
					background: #f8f8f9;
				}
			}
			tfoot {
				background: #f8f8f9;
				height: 28px;
				position: sticky;
				bottom: 0;
				z-index: 5;
				.sea-reports-total {
					td {
						padding: 6px 10px;
						text-align: center;
						font-size: 14px;
					}
					td:first-child,
					td:nth-last-child(2),
					td:last-child {
						position: sticky;
						left: 0;
						text-align: center;
						right: 0px;
						background: #f8f8f9;
					}
					td:nth-last-child(2) {
						right: 140px;
					}
				}
			}
		}
	}
}

.sea-reports-table-remarks {
	width: 300px !important;
}
