.esDetails-routeLineWrap {
  width: 399px;
  height: 494px;
  position: relative;
}
.estimation-routeLine {
  padding: 27px 42px 31px 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid #D9D9D9;
  background: rgba(53, 86, 145, 0.05);
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;

  :global {
    .routeLine-scrollArea {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden auto;
      .routeline-laycan {
        width: 100%;
        height: 56px;
        display: flex;
        margin-bottom: 12px;
        gap: 4px;
        .laycan-item {
          width: 170px;
          height: 100%;
        }
      }
  
      .routeline {
        width: 100%;
        height: calc(100% - 64px);
        display: flex;
  
        .routeline-timeline {
          height: 100%;
          margin-right: 16px;
          width: 140px;
          display: flex;
          flex-direction: column;
  
          .timeline-select {
            width: 100%;
            margin-bottom:15px;
            height: 32px;
          }
  
          .timeline-info {
            width: 100%;
            
            font-size: 12px;
            font-weight: 400;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-bottom: 22px;
            line-height: 14px;

            &>span:nth-child(1) {
              color: #C1C1C1;
              // line-height: 14px;
            }

            &>span:nth-child(2) {
              color: #C1C1C1;
              margin-bottom: 22px;
              // line-height: 14px;
            }
  
            // &>span:first-child {
            //   color: #C1C1C1;
            //   margin-bottom: 28px;
            // }


  
            &>span:last-child {
              color: #707070;
            }
          }


          &>:nth-child(2).timeline-info {
            // margin-bottom: 28px;
            // &>span:nth-child(2) {
            //   margin-bottom: 17px;
            //   // line-height: 14px;
            // }
          }
  
          &>:last-child.timeline-info {
            margin-bottom: 0px;
          }
        }
  
        .routeline-step {
          width: calc(100% - 156px);
          height: 100%;
          display: flex;
          padding-top: 4px;
  
          .step-container {
            width: 16px;
            display: flex;
            flex-direction: column;
            margin-right: 22px;
            padding-top: 3px;
            .port-step {
              display: flex;
              flex-direction: column;
              align-items: center;
              cursor: pointer;
              width: 100%;
              .portStep-icon {
                width: 16px;
                height: 16px;
                display: flex;
              }
              .portStep-line {
                width: 2px;
                height: 71px;
                background: #355691;
              }
            }
          }
  
          .step-icon {
            width: 16px;
            margin-right: 22px;
            padding-top: 3px;
          }
  
          .port-container {
            display: flex;
            flex-direction: column;
            width: calc(100% - 38px);
            height: 100%;
            .port-item {
              width: 100%;
              // height: 87px;
              display: flex;
              flex-direction: column;
              // min-height: 22px;
              max-height: 54px;
              margin-bottom: 33px;
    
              .port-info {
                display: flex;
                justify-content: space-between;
                margin-bottom: 2px;
                align-items: flex-start;
    
                &>span:first-child {
                  color: #484848;
                  
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 22px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
    
                .port-status {
                  display: flex;
                  // width: 57px;
                  height: 20px;
                  padding: 4px 7px;
                  justify-content: center;
                  align-items: center;
                  
                  font-size: 12px;
                  font-weight: 400;
                  border-radius: 27px;
                  border: 0px;
    
                  &.delivery {
                    border: 1px solid #355691;
                    background: #F5F7F9;
                    color: #355691;
                  }
    
                  &.loading {
                    color: #4899BB;
                    background: rgba(72, 153, 187, 0.20);
                  }
    
                  &.discharging {
                    background: rgba(53, 86, 145, 0.20);
                    color: #355691;
                  }
                }
              }

              .port-info--delivery {
                margin-bottom: 0px;
              }
    
              .port-costdays {
                display: flex;
                justify-content: space-between;
                margin-bottom: 2px;
                align-items: center;
    
                &>span {
                  color: #707070;
                  
                  font-size: 12px;
                  font-weight: 400;
                }
              }

              &>:last-child.port-costdays {
                margin-bottom: 0px;
              }
            }
    
            &>:first-child.port-item {
              margin-bottom: 65px;
            }
    
            &>:last-child.port-item {
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
}