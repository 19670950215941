.movement-cargo {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  :global {
    .movement-details--header {
      .fleet-title {
        line-height: 22px;

        &::before {
          background: rgba(53, 86, 145, 0.50);
        }
      }
    }
    .cargo-container {
      height: unset;
    }
  }
}