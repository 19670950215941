.movement-charterBase {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.10);
  padding: 16px;
  gap: 12px;
  position: relative;

  :global {
    .charterBase-container {
      width: 100%;
      height: calc(100% - 36px);
      display: flex;
      overflow: hidden;
      gap: 24px;
      padding: 16px;
      border-radius: 4px;
      border: 1px solid #DADADA;
      display: flex;
      flex-direction: column;
    }
  }
}