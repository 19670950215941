.movement-otherReports {
  width: 1600px;
  height: 860px;
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: #fff;
  gap:10px;
  :global {
    .otherReports-main {
      width: 100%;
      height: calc(100% - 80px);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap:10px;
      .otherReports-type{
        width:160px;
        border-right:1px solid #dedede;
        height: 100%;
        padding:10px;
        display: flex;
        flex-direction: column;
        gap:10px;
        justify-content: flex-start;
        .otherReports-typeItem{
          width:140px;
          height: 60px;
          border-radius: 4px;
          font-size: 14px;
          padding:8px;
          cursor: pointer;
          border:1px solid #dedede;
          background-color: #f8f8f8;          
        }
        .active-typeItem{
          border-color:#355691;
          background-color: #eef4ff;
        }
      }
      .otherReports-container{
        width:calc(100% - 170px);
        height: 100%;
      }
    }

    .otherReports-footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 32px;
      gap: 10px;
    }
  }
}
