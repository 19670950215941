.common-otherExpenses {
  width: 880px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;

  :global {
    .otherExpenses-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;
      height: 300px;

      .otherExpenses-burden--container {
        display: grid;
        grid-template-columns: 101px 71px;
        gap: 4px;
        grid-template-rows: repeat(auto-fit, 28px);

        input {
          min-width: unset;
        }
      }
    }
  }
}