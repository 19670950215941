.vesselAdd-performance {
  :global{
    .commonAdd-container,.commonEdit-container  {
      height: 520px;
      overflow: hidden auto;
      .performance-area {
        .performance {
          width: 100%;
          display: flex;
          flex-direction: column;
          // background: #EFF1F5;
          border-radius: 6px 6px 0px 0px;
          border: 1px solid #D2DAE4;
          border-bottom: 0px;
          // margin-bottom: 16px;
          height: 295px;
          .performance-common {
            display: grid;
            grid-template-columns: 118px repeat(auto-fill, calc((100% - 118px)/5));
            &>:nth-child(1) {
              grid-column: span 1;
            }
          }
          .performance-header {
            width: 100%;
            height: 38px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.06);
            background: #EFF1F5;
            align-items: center;
            border-radius: 6px 6px 0px 0px;
            .performance-item {
              padding: 7px 16px;
              height: 100%;
              font-size: 12px;
              color: rgba(0, 0, 0, 0.88);
              text-align: center;
              
              font-weight: 450;
              line-height: 22px;
              position: relative;
              &::after {
                content: "";
                display: inline-block;
                width: 1px;
                background: rgba(0, 0, 0, 0.06);
                height: 22px;
                right: 0px;
                position: absolute;
              }
            }
            &>:first-child.performance-item {
              border-radius: 6px 0px 0px 0px;
              /* 183.333% */
            }
    
            &>:last-child.performance-item {
              border-radius: 0px 0px 0px 6px;
              &::after {
                display: none;
              }
            }
          }
          .performance-container {
            width: 100%;
            height: calc(100% - 38px);
            display: flex;
            flex-direction: column;
            .performance-content {
              width: 100%;
              height: 41px;
              border-bottom: 1px solid rgba(0, 0, 0, 0.06);
              .performance-item {
                padding: 4px 8px;
                align-items: center;
                height: 100%;
                input{
                  min-width: unset;
                }
              }
              &>:first-child.performance-item {
                // color: var(--character-title-85, rgba(0, 0, 0, 0.85));
                color: rgba(0, 0, 0, 0.85);
                
                font-size: 12px;
                font-weight: 400;
                line-height: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 9px 8px;
                /* 183.333% */
              }
            }
          }
        }
        &>:last-child.performance {
          height: 120px;
          border-radius: 0px 0px 6px 6px;
          border-bottom: 1px solid #D2DAE4;
          margin-bottom: 16px;
          .performance-header {
            border-radius: 0px;
          }
          .performance-container {
            &>:last-child.performance-content {
              border-bottom: 0px;
            }
          }
        }
      }
    }
  }
}