.route-legItem {
	width: 100%;
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.06);
	background: #fff;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 8px 12px;
	cursor: pointer;
	gap: 2px;
	&:hover {
		border-color: #355691;
	}
	:global {
		.legItem-header {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 10px;
			.legItem-vessel {
				color: var(--character-title-85, rgba(0, 0, 0, 0.85));
				font-size: 14px;
				font-weight: 500;
				line-height: 22px;
				width: calc(100% - 20px);
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				width: 150px;
			}
			.legItem-status {
				max-width: calc(100% - 160px);
				padding: 1px 8px;
				border-radius: 4px;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
				background-color: rgba(217, 217, 217, 0.3);
				color: #333;

				// &.Departed {
				// 	background: var(--color-bg-accent-Cyan-subtler, #e6f9f9);
				// 	color: var(--color-bg-accent-Cyan-bolder, #0f9595);
				// }

				// &.Arrived {
				// 	background: var(--color-bg-accent-Green-subtler, #eef9e8);
				// 	color: var(--color-bg-accent-Green-bolder, #4cb518);
				// }

				// &.Preparation {
				// 	background: var(--color-bg-accent-Lime-subtler, #f6fbe7);
				// 	color: var(--color-bg-accent-Lime-bolder, #7ba70d);
				// }

				// &.Completed {
				// 	background: var(--color-bg-accent-fleetblue-subtler, #ebeef4);
				// 	color: var(--color-bg-accent-fleetblue-bolder, #355691);
				// }

				// &.Closed {
				// 	color: var(--color-bg-accent-neutral-bolder, #7d7d7d);
				// 	background: var(--color-bg-accent-neutral-subtler, #f3f3f3);
				// }
			}
		}
		.legItem-container {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 2px;
			.legItem-info {
				width: 100%;
				display: flex;
				gap: 8px;
				align-items: center;
				&.legItem-voyage {
					justify-content: space-between;
				}
				.legItem-avatar {
					font-size: 12px;
					color: var(--Normal-Text, #adadad);
				}
				.legItem-pic {
					color: var(--Normal-Text, #adadad);
					font-size: 12px;
					font-weight: 400;
					line-height: 17px;
					gap: 2px;
					display: flex;
					& > :last-child {
						color: #484848;
					}
				}
				.legItem-vesselStatus {
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 17px;
					gap: 8px;
					display: flex;
					align-items: center;
					&::before {
						width: 4px;
						height: 4px;
						display: inline-block;
						content: "";
						border-radius: 2px;
					}
					&.ballast {
						color: #5498ff;
						&::before {
							background-color: #5498ff;
						}
					}
					&.laden {
						color: #f18d1e;
						&::before {
							background-color: #f18d1e;
						}
					}
				}
			}
		}
	}
}

.route-legItem--active {
	border-color: #355691;
	background: #ebeef4;
}
