.bunkerPlan-cost {
	width: 100%;

	:global {
		.bunkerPlan-cost--container {
			width: 100%;
			display: flex;
			flex-direction: column;

			.bunkerPlan-cost--thead {
				display: grid;
				grid-template-columns: 1fr 2fr 2fr 1fr 3fr 3fr;
				grid-template-rows: 38px;

				& > * {
					border-bottom: 1px solid var(--Conditional-divider, rgba(0, 0, 0, 0.06));
					background: #f8f8f9;
					padding: 7px 16px;
					color: rgba(0, 0, 0, 0.88);
					font-size: 12px;
					font-weight: 450;
					line-height: 22px;
					/* 183.333% */
				}

				& > :not(:last-child) {
					border-right: 1px solid var(--Conditional-divider, rgba(0, 0, 0, 0.06));
				}
			}

			.bunkerPlan-cost--table {
				height: fit-content;

				.table-layout {
					.table-thead {
						tr {
							th {
								padding: 7px 16px;

								&::after {
									height: 100%;
								}
							}
						}
					}

					.table-tbody {
						tr {
							td {
								padding: 4px 8px;
								height: 41px;

								.td-input {
									height: 32px;
								}

								&.td-title {
									color: var(--character-title-85, rgba(0, 0, 0, 0.85));
									text-align: center;
									font-size: 12px;
									font-weight: 450;
									line-height: 22px;
									/* 183.333% */
								}
							}

							& > :not(:last-child) {
								&::after {
									position: absolute;
									right: 0px;
									top: 50%;
									transform: translateY(-50%);
									width: 1px;
									height: 100%;
									background: #e7e9ec;
									display: inline-block;
									content: "";
								}
							}
						}
					}
				}
			}

			.bunkerPlan-cost--summary {
				display: grid;
				grid-template-columns: 1fr 7fr 1fr 1fr 1fr 1fr 1fr 1fr;
				grid-template-rows: 28px;
				background: #f8f8f9;
				margin-top: 10px;

				& > * {
					color: var(--color-text-Gray-95, #222);
					font-size: 14px;
					font-weight: 400;
					line-height: 22px;
					padding: 4px 8px;
					text-align: center;
					/* 157.143% */
				}
				& > :not(:last-child) {
					border-right: 1px solid var(--Conditional-divider, rgba(0, 0, 0, 0.06));
				}
			}
		}
	}
}
