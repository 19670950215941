.database-taskEdit {
  width: 452px;
  :global{
    .commonAddItem-container {
      grid-template-columns: repeat(auto-fit,calc((100% - 8px)/2)) !important;
    }
    .taskEdit-textarea {
      width: 100%;
      height: 118px;
      display: flex;
      flex-direction: column;
      margin-top: 8px;
    }
  }
}