.contact-filter {
  display: flex;
  // flex-direction: column;
  height: 100%;
  gap: 8px;
  :global{
    .first-filter {
      width: 254px;
    }
    .other-filter {
      width: 184px;
    }
    // .filter-label{
    //   color: #707070;
    //   font-size: 14px;
    //   font-weight: 500;
    //   line-height: 16px;
    // }
    // .filter-container {
    //   display: flex;
    //   height: 32px;
    //   gap: 8px;
    //   &>*:not(:first-child){
    //     width: 160px;
    //   }
    //   &>:first-child {
    //     width: 254px;
    //   }
    // }
  }
}