.common-vesselArrival {
  width: 1200px;
  height: 724px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 6px;
  padding: 24px 12px;
  gap: 16px;

  :global {
    .vesselArrival-header {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .header-title {
        color: var(--color-text-body-1, #151515);
        font-size: 20px;
        font-weight: 590;
        line-height: 28px;
        gap: 10px;
        display: flex;
        align-items: center;
        &::before {
          display: inline-block;
          content: "";
          border-radius: 38px;
          background: #355691;
          width: 6px;
          height: 16px;
        }
      }
    }
    .vesselArrival-container {
      width: 100%;
      height: calc(100% - 44px);
      overflow: hidden auto;
      scrollbar-width: none;
      .vesselArrival-scrollArea {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  }
}