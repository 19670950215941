.movement-charterBase--container {

  :global {
    .movement-charterBase--table {
      .table-layout {
        .table-tbody {
          tr {
            td {
              padding: 3px 14px;
            }
          }
  
          .port-select {
            height: 28px;
  
            .ant-select-selector {
              height: 28px;
              border-radius: 4px;
              border: 1px solid var(--Neutral-5, #D9D9D9);
              opacity: 0.8;
  
              /* drop-shadow/button-secondary */
              box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  
              input {
                height: 100%;
              }
  
              .ant-select-selection-item {
                line-height: 28px;
                font-size: 14px;
                padding-inline-end: 12px;
              }
            }
          }
  
          .port-input {
            height: 28px;
            border-color: transparent;
            transition: 200ms all ease-in-out;
  
            input {
              font-size: 14px;
              min-width: unset;
            }
  
            &:focus-within {
              border-color: #D9D9D9;
            }

            &:hover {
              border-color: #D9D9D9;
            }
          }
  
          .date-picker {
            // border: 0px;
            height: 28px;
            font-size: 14px;
  
            // border-color: unset;
            // box-shadow: none;
            // border-inline-end-width: 0px;
            input {
              font-size: 14px;
              line-height: normal;
            }
          }
  
          .port-actions {
            display: flex;
            gap: 4px;
            max-width: 130px;
  
            .fleet-delete {
              font-size: 14px;
              color: #EE605B;
              cursor: pointer;
            }
          }
        }
        
      }
    }
  }
}