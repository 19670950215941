.handoverForm-portDetails {
  :global {
    .portDetails-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;
      .portDetails--header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .header--rate {
          display: flex;
          align-items: center;
          gap: 16px;
          &>:first-child {
            color: #484848;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            /* 166.667% */
          }
        }
      }
      .portDetails-table {
        height: unset;
        .table-layout {
          .table-thead {
            tr{
              th{
                background: var(--color-bg-Table-top, #EFF1F6);
              }
            }
          }
          .table-tbody {
            tr{
              td {
                padding: 6px 12px 6px 16px;
                .td-input {
                  height: 32px;
                }
                .td-actions {
                  &.move {
                    gap: 4px;
                    justify-content: space-between;
                    align-items: center;
                    .port-moveActions {
                      display: flex;
                      flex-direction: column;
                      &>* {
                        font-size: 16px;
                        line-height: 1;
                        cursor: pointer;
                      }
                      &>:first-child {
                        transform: rotateZ(90deg);
                      }
                      &>:last-child {
                        transform: rotateZ(-90deg);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}