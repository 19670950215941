.route-legList {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: none;
	flex-direction: column;
	gap: 8px;
	:global {
		.legList-wrapper {
			width: 100%;
			gap: 10px;
			display: flex;
			flex-direction: column;
			.legList-container {
				width: 100%;
				height: calc(100% - 32px);
				overflow: hidden auto;
				&::-webkit-scrollbar {
					width: 4px;
					height: 0px;
				}

				&::-webkit-scrollbar-corner {
					background-color: rgba(41, 42, 44, 1);
				}

				&::-webkit-scrollbar-thumb {
					border-radius: 6px;
					background-color: rgba(53, 86, 145, 0.18);
					border: 1px solid rgb(219, 219, 219);
					cursor: pointer;
				}

				&::-webkit-scrollbar-track {
					border-radius: 4px;
				}
				.legList-scrollArea {
					padding-right: 4px;
					width: 100%;
					display: flex;
					flex-direction: column;
					gap: 4px;
				}
			}
		}
	}
}

.route-legList--active {
	display: flex;
}
