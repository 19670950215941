.fleet-button {
  width: fit-content;
  display: inline-flex;
  padding: 11px 30px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-size: 14px;
  border: 0px;
  font-weight: 500;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.03);
  background-color: #355691;
  color: #FFF;
  position: relative;
  line-height: 1;
  height: fit-content;
  // height: 36px;

  &:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: #4B6FB0;
    color: #FFF;
  }

  &:not(:disabled):not(.ant-btn-disabled):active {
    background-color: #2A4A84;
    color: #FFF;
  }

  &:not(:disabled):not(.ant-btn-disabled):focus-visible {
    outline: 2px solid #91caff;
    background-color: #4B6FB0;
    color: #FFF;
  }

  &:disabled {
    background-color: #E4E4E4;
    color: #fff;
  }

  :global {
    .btn-loading {
      height: 100%;
    }

    .btn-suffix {
      margin-left: 8px;
      display: flex;
    }
  }
}

.fleet-button__primary {
  background-color: #467996;
  line-height: 1;

  &:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: #4A8AAE;
    color: #FFF;
  }

  &:not(:disabled):not(.ant-btn-disabled):active {
    background-color: #2F6889;
    color: #FFF;
  }

  &:not(:disabled):not(.ant-btn-disabled):focus-visible {
    background-color: #4A8AAE;
    color: #FFF;
  }
}

.fleet-button__secondary {
  border: 1px solid rgba(53, 86, 145, 0.50);
  background: #FFF;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.03);
  color: #355691;
  padding: 6px 28px;
  line-height: 22px;

  &:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: #FFF;
    color: #355691;
  }

  &:not(:disabled):not(.ant-btn-disabled):active {
    background-color: #FFF;
    color: #355691;
  }

  &:not(:disabled):not(.ant-btn-disabled):focus-visible {
    background-color: #FFF;
    color: #355691;
  }

  &:disabled {
    color: #CECECE;
    border: 1px solid #D4D4D4;
    background: #F5F5F5;
  }
}

.fleet-button__text {
  padding: 6px 12px;
  border: 0px;
  border-radius: 4px;
  background-color: #fff;
  color: #355691;
  box-shadow: none;

  &:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: #FAFAFA;
    color: #355691;
  }

  &:not(:disabled):not(.ant-btn-disabled):active {
    background-color: #F5F5F5;
    color: #355691;
  }

  &:not(:disabled):not(.ant-btn-disabled):focus-visible {
    outline: 2px solid #91caff;
    background-color: #4B6FB0;
    color: #355691;
  }

  &:disabled {
    background-color: #fff;
    color: #CFCFCF;
  }
}

.fleet-button__small {
  padding: 5px 12px;
  line-height: 22px;
  height: 32px;
}

.fleet-button__large {
  padding: 13px 30px;
  line-height: 1;
  height: 40px;
}

.fleet-button__secondary__samll {
  padding: 9px 18px;
  line-height: 1;
}

.fleet-button__secondary__large {
  padding: 13px 16px;
  line-height: 1;
}

.fleet-button__anchor {
  padding: 8px;
  background-color: #467996;
  line-height: 1;

  &:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: #4A8AAE;
  }

  &:not(:disabled):not(.ant-btn-disabled):active {
    background-color: #2F6889;
  }
}

.fleet-button__icon {
  padding: 8px;
  line-height: 1;
  background-color: #355691;
  &:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: #4A8AAE;
  }

  &:not(:disabled):not(.ant-btn-disabled):active {
    background-color: #2F6889;
  }
}

.fleet-button__add {
  border: 1px solid #D9D9D9;
  background: #FFF;
  padding: 8px;
  line-height: 1;
  

  &:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: #F7F7F7;
  }

  &:not(:disabled):not(.ant-btn-disabled):active {
    background-color: #FFF;
  }
}