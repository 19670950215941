.common-select--contact {
  width: 100%;
  height: fit-content;
}

.common-select--contact--newly {
  display: flex;
  gap: 4px;
  align-items: center;

  :global {
    .contact-select--container {
      width: calc(100% - 36px);
    }

    .contanct-select--newly {
      font-size: 32px;
      cursor: pointer;
      line-height: 1;
      opacity: .4;
    }
  }
}