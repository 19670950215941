.estimation-profit {
  width: 100%;
  height: 926px;
  display: flex;
  flex-direction: column;

  :global {
    .profit {
      width: 100%;
      height: 669px;
      margin-bottom: 8px;
      padding: 12px;
      border-radius: 6px;
      border: 1px solid #D9D9D9;
      background: #FFF;

      .profit-header {
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 1px solid #D9D9D9;
        margin-bottom: 8px;
      }

      .profit-item {
        width: 100%;
        height: 38px;
        padding: 8px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #D9D9D9;

        .item-left-0 {
          color: rgba(0, 0, 0, 0.88);
          
          font-size: 12px;
          font-weight: 500;
          line-height: 22px;
          /* 183.333% */
        }

        .item-left-1 {
          color: rgba(0, 0, 0, 0.50);
          
          font-size: 12px;
          font-weight: 400;
          line-height: 22px;
          padding-left: 12px;
          /* 183.333% */
        }

        .item-left-2 {
          color: rgba(0, 0, 0, 0.50);
          
          font-size: 12px;
          font-weight: 400;
          line-height: 22px;
          padding-left: 24px;
          /* 183.333% */
        }

        .item-right-0 {
          
          font-size: 12px;
          font-weight: 600;
          line-height: 22px;

          /* 183.333% */
          &>span:first-child {
            color: #FF8632;
            margin-right: 4px;
          }

          &>span:last-child {
            color: #355691;
          }
        }

        .item-right-1 {
          color: rgba(0, 0, 0, 0.50);
          
          font-size: 12px;
          font-weight: 400;
          line-height: 22px;
          /* 183.333% */
        }
      }

      :last-child.profit-item {
        border-bottom: 0px;
      }
    }

    .remakes {
      display: flex;
      width: 100%;
      height: 249px;
      padding: 12px;
      flex-direction: column;
      border-radius: 6px;
      border: 1px solid #D9D9D9;
      background: #FFF;

      .remakes-header {
        width: 100%;
        height: 19px;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
      }

      .remakes-container {
        width: 100%;
        height: calc(100% - 29px);
        border-radius: 6px;
        background: #F5F7F9;

        // textarea {
        //   // padding: 6px 12px;
        //   border: 1px solid rgba(53, 86, 145, 0.16);
        //   color: rgba(0, 0, 0, 0.25);

        //   /* Body/regular */
        //   
        //   font-size: 14px;
        //   font-weight: 400;
        //   line-height: 22px;
        //   /* 157.143% */
        // }
      }
    }
  }
}