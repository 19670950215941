.fleet-biInsights {
	width: 100%;
	overflow: hidden;
	position: relative;
	height: calc(100% - 64px);
	:global {
		.biInsights-tabs {
			height: 48px;
			border-bottom: 1px solid #d9d9d9;
		}
		.biInsights-wrapper {
			width: 100%;
			height: calc(100% - 49px);
			position: relative;
			iframe {
				width: 100%;
				height: 100%;
			}
		}
	}
}
