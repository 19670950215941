.handoverForm-fixtureNotes {
  :global {
    .fixtureNotes-container {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, calc((100% - 24px)/4));
      grid-template-rows: repeat(auto-fit, 56px);
      gap: 16px 8px;
      &.time-select {
        grid-template-columns: 210px 210px 330px 1fr;
      }
      .fixtureNotes--splitArea {
        display: grid;
        gap: 4px;
        grid-template-columns: 135px 71px;
        grid-template-rows: repeat(auto-fit,32px);
        &.cargo-info {
          grid-template-columns: 135px 71px;
        }
        &.freight-info {
          grid-template-columns: 137px 69px;
        }
      }
    }
  }
}