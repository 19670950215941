.route-areaPreset {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-top: 6px;
	gap: 6px;
	:global {
		.areaPreset-header {
			width: fit-content;
			color: rgba(30, 30, 30, 1);
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: 22px; /* 169.231% */
			padding: 0px 4px;
			background: rgba(40, 83, 195, 0.1);
			border-radius: 4px;
		}
		.areaPreset-container {
			width: 100%;
			height: calc(100% - 28px);
			overflow: hidden auto;
			&::-webkit-scrollbar {
				width: 5px;
			}
			&::-webkit-scrollbar-thumb {
				width: 5px;
				border-radius: 4px;
				background-color: rgba(192, 197, 207, 0.8);
			}
			.areaPreset-scrollArea {
				width: 100%;
				display: flex;
				flex-direction: column;
				.areaPreset-item {
					border: 0px;
					background-color: #fff;
					.ant-collapse-item {
						border-bottom: 1px solid rgba(0, 0, 0, 0.06);
						.ant-collapse-header {
							padding: 6px 20px 6px 6px;
							align-items: center;
							.areaPreset-item-icon {
								line-height: 1;
								transform: rotateZ(180deg);
								&.active {
									transform: rotateZ(270deg);
								}
							}
							.ant-collapse-header-text {
								width: calc(100% - 28px);
							}
						}
						.ant-collapse-content {
							.ant-collapse-content-box {
								padding: 0px;
							}
						}
					}
					& > :last-child {
						border-bottom: 0px;
					}
				}
				.areaPreset-group-popover-trigger {
					width: 100%;
				}
				.areaPreset-group-header {
					width: 100%;
					height: fit-content;
					.radio-label {
						color: rgba(30, 30, 30, 1);
						width: calc(100% - 24px);
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
					.radio-icon {
						width: 14px;
						height: 14px;
					}
				}
			}
		}
	}
}
