.routeCommon-ais-groupAdd {
	width: 300px;
	display: flex;
	flex-direction: column;
	padding: 10px 16px;
	border-radius: 6px;
	background-color: #fff;
	box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
	gap: 12px;
	:global {
		.groupAdd-header {
			color: #151515;
			font-size: 16px;
			font-weight: 400;
			line-height: 28px; /* 175% */
		}
		.groupAdd-container {
			display: flex;
			flex-direction: column;
			gap: 16px;
			& > * {
				justify-content: flex-end;
			}
			.groupAdd-colors {
				display: grid;
				grid-template-columns: repeat(6, 16px);
				grid-template-rows: repeat(2, 16px);
				gap: 8px;
				width: 172px;
				flex-grow: unset;
				& > * {
					transition: border-color 300ms ease-in-out;
					border: 2px solid transparent;
					border-radius: 9999px;
					cursor: pointer;
					&.active {
						border-color: #000;
					}
				}
			}
		}
		.groupAdd-footer {
			gap: 10px;
			justify-content: flex-end;
			align-items: center;
			display: flex;
		}
	}
}
