.route-portCurrent {
	width: 100%;
	height: calc(100% - 33px);
	overflow: hidden auto;
	:global {
		.portCurrent-container {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 8px;
			.portCurrent-empty {
				color: #b3b3b3;
				font-size: 14px;
				font-weight: 400;
				line-height: 22px;
				padding-left: 10px;
			}
		}
	}
}
