.movement-temporaryTasks {
	width: 394px;
	max-height: 532px;
	overflow: hidden auto;
	position: relative;
	padding: 6px;
	background: #ffffff;

	:global {
		.temporaryTasks-scrollArea {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 12px;
			.temporaryTasks-item {
				width: 100%;
				height: 116px;
				background: #f5f7f9;
				padding: 16px;
				display: flex;
				border-radius: 6px;
				&::before {
					width: 4px;
					height: 100%;
					display: inline-block;
					content: "";
					border-radius: 2px;
					margin-right: 12px;
				}
				&.last-first {
					&::before {
						background-color: rgb(255, 96, 82);
					}
					.temporaryTasks-item--container {
						.temporaryTasks-item--footer {
							& > .temporaryTasks-item--deadline {
															background-color: rgba(254, 97, 82, 0.14);
								& > :first-child {
									color: rgb(255, 96, 82);
								}
								& > :last-child {
									color: rgb(255, 96, 82);
								}
							}
						}
					}
				}
				&.last-second {
					&::before {
						background-color: rgb(255, 187, 0);
					}
					.temporaryTasks-item--container {
						.temporaryTasks-item--footer {
							& > .temporaryTasks-item--deadline {
								background-color: rgba(255, 187, 0, 0.14);
								& > :first-child {
									color: rgb(255, 187, 0);
								}
								& > :last-thiry {
									color: rgb(255, 187, 0);
								}
							}
						}
					}
				}
				&.last-thiry {
					&::before {
						background-color: rgb(0, 171, 180);
					}
					.temporaryTasks-item--container {
						.temporaryTasks-item--footer {
							& > .temporaryTasks-item--deadline {
								background-color: rgba(0, 171, 180, 0.14);
								& > :first-child {
									color: rgb(0, 171, 180);
								}
								& > :last-child {
									color: rgb(0, 171, 180);
								}
							}
						}
					}
				}
				.temporaryTasks-item--container {
					width: calc(100% - 16px);
					display: flex;
					flex-direction: column;
					.temporaryTasks-item--stage {
						font-weight: 400;
						font-size: 14px;
						color: #282828;
						line-height: 20px;
						margin-bottom: 8px;
					}
					.temporaryTasks-item--info {
						display: flex;
						gap: 8px;
						align-items: center;
						.temporaryTasks-item--priority {
							padding: 2px 4.5px;
							font-size: 14px;
							font-weight: 400;
							line-height: 1;
							border-radius: 4px;
							border: 1px solid rgba(137, 137, 137, 0.22);
							background: rgba(99, 99, 99, 0.06);
							color: #858585;
							&.low {
								background: rgba(46, 195, 52, 0.28);
								color: #0ca112;
							}

							&.medium {
								color: #fa8d18;
								background: rgba(250, 141, 24, 0.28);
							}

							&.high {
								background: #fcdcdc;
								color: #ec0404;
							}
						}
						.temporaryTasks-item--title {
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							font-weight: 500;
							font-size: 14px;
							color: #282828;
							line-height: 24px;
							width: calc(100% - 28px);
						}
					}

					.temporaryTasks-item--footer {
						display: flex;
						align-items: flex-end;
						justify-content: space-between;
						& > :first-child {
							font-weight: 400;
							font-size: 14px;
							color: rgba(0, 0, 0, 0.25);
							line-height: 20px;
						}
						& > .temporaryTasks-item--deadline {
							background: rgba(254, 97, 82, 0.14);
							border-radius: 8px;
							padding: 8px;
							display: flex;
							gap: 8px;
							align-items: center;
							& > :first-child {
								font-size: 16px;
							}
							& > :last-child {
								font-weight: 500;
								font-size: 12px;
								line-height: 16px;
							}

							// &.last-first {
							// 	background-color: rgb(255, 96, 82);
							// 	& > :first-child {
							// 		color: rgb(255, 96, 82);
							// 	}
							// 	& > :last-child {
							// 		color: rgb(255, 96, 82);
							// 	}
							// }
							// &.last-second {
							// 	background-color: rgb(255, 187, 0);
							// 	& > :first-child {
							// 		color: rgb(255, 187, 0);
							// 	}
							// 	& > :last-thiry {
							// 		color: rgb(255, 187, 0);
							// 	}
							// }
							// &.last-thiry {
							// 	background-color: rgb(0, 171, 180);
							// 	& > :first-child {
							// 		color: rgb(0, 171, 180);
							// 	}
							// 	& > :last-child {
							// 		color: rgb(0, 171, 180);
							// 	}
							// }
						}
					}
				}
			}
		}
	}
}
