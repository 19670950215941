.portReport-bunkerLog {
  width: 100%;
  height: 440px;
  :global {
    .bunker-log-table {
      height: 380px;
      overflow-x: auto;
      overflow-y: auto;
      border-bottom:1px solid rgba(0, 0, 0, 0.06);
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        width: 6px;
        border-radius: 3px;
        background: #d2d2d2;
      }
      table {
        width: 100%;
        height: 100%;
        border-left: 1px solid rgba(0, 0, 0, 0.06);
        border-right: 1px solid rgba(0, 0, 0, 0.06); 
        table-layout: fixed;
        .bunker-log-table-thead {
          position: sticky;
          top: 0;
          z-index:5;
          tr {
            th {
              height: 36px;
              color: rgba(0, 0, 0, 0.85);
              text-align: center;
              font-family: "SF Pro";
              font-size: 14px;
              font-weight: 400;
              line-height: 36px;
              border: 1px solid rgba(0, 0, 0, 0.06);
              background: #f8f8f9;
            }
          }
        }
        .bunker-log-table-tbody {
          tr {
            td {
              height: 42px;
              padding: 3px 6px;
              font-size: 14px;
              text-align: left;
              border: 1px solid rgba(0, 0, 0, 0.06);
              input {
                min-width: 50px;
                width: 100%;
              }
              .bunker-log-table-action {
                text-align: center;
                color: red;
              }
            }
          }
        }
        .bunker-log-table-tfoot{
          position: sticky;
          bottom: 0;
          z-index:5;
          tr{
            td{
              padding: 3px 6px;
              font-size: 14px;
              height: 36px;
              line-height: 36px;
              text-align: center;
              border-top: 1px solid #F0F0F0;
              background: #F8F8F9;
            }
          }
        }
      }
    }
  }
}
