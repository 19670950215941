.areaVessel-filterItem {
	border: 0px;
	background-color: #fff;
	:global {
		.ant-collapse-item {
			display: flex;
			flex-direction: column;
			gap: 4px;
			.ant-collapse-header {
				padding: 16px 12px 4px 12px;
				border-top: 1px solid rgba(0, 0, 0, 0.06);
				align-items: center;
				.filter-item-icon {
					line-height: 1;
					transform: rotateZ(180deg);
					&.active {
						transform: rotateZ(270deg);
					}
				}
				.filter-item-header {
					display: flex;
					justify-content: space-between;
					align-items: center;
					.filter-item-label {
						color: rgba(0, 0, 0, 0.88);
						font-size: 14px;
						font-weight: 500;
						line-height: 22px;
					}
					.filter-item-count {
						width: 20px;
						height: 20px;
						background: #368fe1;
						border-radius: 10px;
						color: #fff;
						font-size: 12px;
						font-weight: 400;
						line-height: 12px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
			.ant-collapse-content {
				.ant-collapse-content-box {
					padding: 0px 16px;
					.filter-item-wrapper {
						display: flex;
						flex-direction: column;
						gap: 12px;
						.filter-item-select {
							padding: 0px 6px;
							height: fit-content;
							.radio-label {
								font-size: 14px;
								color: #333;
							}
						}
					}
				}
			}
		}
	}
}

.areaVessel-filterItem--search {
	:global {
	}
}
