.routeCommon-portVessel {
	width: 100%;
	height: 100%;
	position: relative;
	display: none;
	padding: 12px 12px 8px 25px;
	gap: 20px;
	:global {
	}
}
