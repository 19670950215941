@tailwind base;
@tailwind components;
@tailwind utilities;
@import "../iconfont/iconfont.css";

@keyframes flicker {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html,
body {
	width: 100%;
	height: 100%;

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.fleet-nonScrolBar {
		&::-webkit-scrollbar {
			width: 0px;
			height: 0px;
			display: none;
		}

		&::-webkit-scrollbar-corner {
			background-color: rgba(41, 42, 44, 1);
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 6px;
			background-color: rgba(41, 42, 44, 1);
			border: 1px solid rgb(219, 219, 219);
		}

		&::-webkit-scrollbar-track {
			border-radius: 4px;
		}

		scrollbar-width: none;
		/* Firefox */
		-ms-overflow-style: none;
		/* IE 10+ */
	}

	.fleet-header {
		color: var(--color-text-body-1, #151515);
		font-size: 20px;
		font-weight: 590;
		line-height: 28px;
		gap: 10px;
		display: flex;
		align-items: center;

		&::before {
			display: inline-block;
			content: "";
			border-radius: 38px;
			background: #355691;
			width: 6px;
			height: 16px;
		}
	}

	.fleet-selectTag {
		width: fit-content;
		height: 24px;
		padding: 3px 4px;
		background: rgba(0, 0, 0, 0.06);
		border-radius: 4px;
		align-items: center;
		gap: 4px;
		display: flex;
		margin-right: 2px;

		.selectTag-name {
			// width: calc(100% - 18px);
			line-height: 22px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-size: 12px;
			overflow: hidden;
			color: #355691;
			font-weight: 400;
		}

		.selectTag-close {
			font-size: 14px !important;
			display: flex;
			cursor: pointer !important;
		}
	}

	.fleet-disabled {
		opacity: 0.4;
		cursor: not-allowed !important;
	}
}

#app {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;

	.fleet-common {
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: relative;
	}
}
