.contract-newAdd {
  width: 912px;
  height: 824px;
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
  overflow: hidden;
  border-radius: 6px;
  background: #fff;
  
  :global {
    .newAdd-header {
      width: 100%;
      display: flex;
      height: 32px;
      margin-bottom: 12px;
      align-items: center;
      justify-content: space-between;

      .header-title {
        display: flex;
        height: 100%;
        &>span:first-child {
          color: #484848;
          
          font-size: 20px;
          font-weight: 500;
          margin-right: 12px;
          
          &::before {
            display: inline-block;
            content: "";
            margin-right: 12px;
            width: 6px;
            height: 16px;
            border-radius: 8px;
            background: #355691;
          }
        }
      }

      .header-close {
        display: flex;
        width: 16px;
        height: 16px;
      }
    }

    .newAdd-container {
      width: 100%;
      margin-bottom: 12px;
      height: calc(100% - 90px);
      overflow: hidden auto;
      .newAdd-scrollArea {
        width: 100%;
        display: flex;
        flex-direction: column;
        &>:last-child {
          margin-bottom: 0px;
        }
      }
    }

    .newAdd-footer {
      width: 100%;
      height: 36px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .footer-action {
        margin-right: 8px;
      }
    }
  }
}