.portDa-filter {
  width: 100%;
  display: flex;
  gap: 8px;

  :global {
    .filter-search {
      width: 254px;
    }
    .filter-contactSelect {
      width: 160px;
    }

    .filter-timeSelect {
      width: 240px;
    }
  }
}