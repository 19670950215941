.portDa-newly {
  width: 872px;
  padding: 16px;
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;

  :global {
    .newly-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 6px 6px 0px 0px;

      .header-title {
        color: #484848;
        font-size: 20px;
        font-weight: 500;
        line-height: normal;
        display: flex;
        align-items: center;

        &::before {
          content: '';
          width: 6px;
          height: 14px;
          border-radius: 38px;
          background: #355691;
          display: inline-block;
          margin-right: 8px;
        }

        /* 140% */
      }

      .font_family {
        font-size: 14px;
        line-height: 1;
      }
    }

    .portDa-newly-container {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, calc((100% - 24px) / 4));
      grid-template-rows: repeat(auto-fit, 56px);
      gap: 10px 8px;
      .newly-cargoQuantity {
        display: grid;
        gap: 8px;
        grid-template-columns: calc(100% - 80px) 72px;
      }
    }

    .portDa-newly-footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
    }
  }
}