.bunkerPlan-clause {
  :global {

    .bunkerPlan-clause--container {
      display: grid;
      grid-template-columns: repeat(auto-fit, calc((100% - 32px)/5));
      gap: 8px;
      grid-template-rows: repeat(auto-fit, 56px);
    }
  }
}