.cargoBook-basic {
  :global {
    .form-container {
      &.cargoBook-form-container {
        margin-top: 12px;
        grid-template-columns: 432px repeat(auto-fit, calc((100% - 456px)/2)) !important;

        .cargoBook-basic-laycan {
          // width: 432px;
        }
      }
    }
  }
}