.fleet-layout-header {
	width: 100%;
	padding: 13px 20px 14px;
	display: flex;
	height: 64px;
	background-color: #f4f4f4;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #d9d9d9;

	:global {
		.layout-header-left {
			height: 100%;
			display: flex;
			.layout-header-logo {
				width: 100px;
				height: 37px;
				margin-right: 40px;
			}
		}

		.layout-header-right {
			height: 100%;
		}
	}
}
