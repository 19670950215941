.contract-edit {
  width: 904px;
  height: 100%;
  background: #FFF;
  box-shadow: -4px 0px 20px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0px 20px 26px;

  :global {
    .edit-header {
      width: 100%;
      height: 114px;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #D9D9D9;
      align-items: flex-start;
      padding: 26px 0px 8px;
      margin-bottom: 20px;

      .edit-close {
        width: 16px;
        height: 16px;
        margin-bottom: 16px;
      }

      .edit-header-container {
        width: 100%;
        height: calc(100% - 32px);
        display: flex;
        justify-content: space-between;
        align-items: center;

        .edit-header-info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;

          .edit-info-top {
            display: flex;
            // color: var(--Normal-Text, #ADADAD);
            text-align: center;
            
            font-size: 20px;
            font-weight: 450;
            line-height: 22px;

            /* 110% */
            &>span:first-child {
              color: #ADADAD;
            }

            &>:nth-child(2) {
              margin: 0 8px;
              color: #ADADAD;
            }

            &>span:last-child {
              color: rgba(0, 0, 0, 0.88);
            }
          }

          .edit-info-bot {
            // color: var(--Tip_Text, rgba(0, 0, 0, 0.18));
            color: rgba(0, 0, 0, 0.18);
            text-align: center;
            
            font-size: 12px;
            font-weight: 450;
            line-height: 22px;
            display: flex;

            &>:first-child {
              margin-right: 4px;
            }
          }
        }

        .edit-header-action {
          display: flex;
          height: 100%;
          align-items: flex-start;
          

          .edit-header-time {
            width: 84px;
            margin-right: 8px;
            // color: var(--Normal-Text, #ADADAD);
            color: #ADADAD;
            
            font-size: 12px;
            font-weight: 400;
            text-indent: 12px;
            line-height: 15px;
            margin-top: 3px;
          }
          .edit-header-submit {

          }
        }
      }
    }
    .edit-container {
      width: 100%;
      height: calc(100% - 134px);
      overflow: hidden auto;
      .edit-scrollArea {
        width: 100%;
        display: flex;
        flex-direction: column;
        &>:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}