.voyageList-filter {
  height: 85px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--color-line-separator, #E7E9EC);

  :global {
    .filter-title {
      color: var(--color-text-body-1, #151515);
      font-size: 20px;
      font-weight: 590;
      line-height: 28px;
    }

    .filter-container {
      height: 32px;
      width: 100%;
      display: flex;
      gap: 6px;

      .filter-search {
        width: 249px;
      }

      .filter-select {
        width: calc(100% - 255px);
      }
    }
  }
}