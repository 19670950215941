.movement-lat-lon-input {  
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 8px;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  padding: 12px;
  :global{
    .lat-lon-input-content{
      display: flex !important;
      flex-direction: row;
      justify-content: space-between;
      gap: 6px;
      font-size: 20px;
      input {
        width: 70px;
        min-width: 70px;
      }
      div{
        // width: 70px;
        height: 36px;
      }
      .lat-lon-select {
        width: 60px;
      }
    }
  }
}
.movement-lat-lon-div {
  text-align: center;
  width: 100%;
  height: 32px;
}
