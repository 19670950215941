.billOfLading-contain {
	display: flex;
	flex-direction: column;
	gap: 16px;

	:global {
		.billOfLading-commom {
		}
		.billOfLading-basics,
		.billOfLading-information {
			display: flex;
			flex-direction: column;
			gap: 12px;
			.flex-container {
				display: flex;
				gap: 5px;
				.fleet-utc {
					width: 208px;
					display: flex;
					align-items: center;
					background-color: #f2f2f2;
					padding: 5px 12px;
					height: 32px;
					border: 1px solid #d9d9d9;
					border-radius: 6px;
					font-size: 14px;
				}
			}
		}
		.billOfLading-port {
			display: flex;
			flex-direction: column;
			gap: 12px;

			.discharge-port-container {
				display: flex;
				flex-direction: column;
				gap: 10px;
				max-height: 430px;
				overflow: auto;
				&::-webkit-scrollbar {
					width: 6px;
					position: absolute;
				}
				&::-webkit-scrollbar-thumb {
					width: 6px;
					border-radius: 4px;
					background-color: rgba(0, 0, 0, 0.1);
				}

				.discharge-port-box {
					border: 1px solid #0000001a;
					border-radius: 4px;
					padding: 12px;
					background: #fbfbfb;
					display: flex;
					flex-direction: column;
					gap: 12px;

					.box-header {
						font-size: 16px;
						font-weight: 400;
						padding-bottom: 10px;
						border-bottom: 1px solid var(--color-line-separator, #e7e9ec);
					}
					.port-details {
						display: flex;
						gap: 5px;
						margin-bottom: 15px;

						.radio-label {
							font-size: 12px;
							font-weight: 400;
						}
					}
					.uploadfile-item {
						width: 100%;
						border-radius: 4px;
						border: 1px solid #d9d9d9;
						background: #fff;
						padding: 9px 8px;
						display: flex;
						justify-content: space-between;
						align-items: center;

						.uploadfile-info {
							display: flex;
							gap: 8px;
							align-items: center;
							.img-icon {
								font-size: 48px;
								width: 48px;
								height: 48px;
							}
							& > :first-child {
								font-size: 48px;
								color: #2fa556;
								border-radius: 6px;
								line-height: 1;
							}

							& > :last-child {
								color: #355691;
								font-size: 14px;
								font-weight: 400;
								line-height: 22px;
								width: 300px;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								/* 157.143% */
							}
						}

						.uploadfile-tool {
							display: flex;
							gap: 8px;
							align-items: center;
						}

						.uploadfile-icon {
							font-size: 14px;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
}
