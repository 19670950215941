.invStatement-counterParty {
	:global {
		.invoicesForm-contain {
			width: 100%;
			gap: 16px 8px;
			display: grid;
			align-items: flex-end;
			grid-template-columns: 370px 200px 200px;
		}
	}
}
