.fleet-basic-information {
  flex: 1;
  height: 500px;
  border-radius: 4px;
  margin-left: 30px;
  border: 1px solid #e6ebf5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  :global {
    .basic-information-header {
      font-size: 18px;
      padding: 16px 15px 7px;
      height: 50px;
      border-bottom: 1px solid #e6ebf5;
      color: #303133;
    }
    .basic-information-tab {
      padding: 30px;
    }
  }
}
