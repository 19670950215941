.common-companySelect--Popup {
	height: 270px;
	width: 500px;

	:global {
		.select--popup--header {
			grid-template-columns: 250px 200px;
			gap: 10px;
		}

		.select--popup--container {
			.select--popup--scrollArea {
				.select--popup--item {
					grid-template-columns: 250px 200px;
					gap: 10px;
				}
			}
		}
	}
}
.common-companySelect--Popup--tooltip {
	z-index: 1100;
}
