.routeCommon-list-tabbar {
	width: 100%;
	border: 1px solid rgba(53, 86, 145, 0.5);
	background: rgba(53, 86, 145, 0.08);
	border-radius: 6px;
	padding: 2px;
	height: 40px;
	display: grid;
	grid-template-columns: repeat(auto-fit, calc((100% - 4px) / 3));
	grid-template-rows: repeat(auto-fit, 100%);
	gap: 2px;
	:global {
		li {
			color: rgba(53, 86, 145, 0.5);
			border-radius: 4px;
			cursor: pointer;
			font-size: 14px;
			font-weight: 500;
			display: flex;
			align-items: center;
			justify-content: center;

			&.active {
				color: #fff;
				background: rgba(53, 86, 145, 1);
			}
		}
	}
}
