.common-newly--account {
  width: 1116px;

  :global {
    .newly-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .accountNewly-company {
        width: 263px;
        height: 56px;
      }

      .accountNewly-toolbar {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .toolbar-label {
          color: var(--color-text-body-2, #7D7D7D);
          font-size: 12px;
          font-weight: 590;
          line-height: 24px;
          /* 200% */
        }
      }

      .accountNewly-table {
        width: 100%;
        overflow: auto;
        border: 1px solid #EBEBEB;
        border-radius: 6px;
        height: 122px;

        .table-layout {
          width: max-content;
          border-collapse: collapse;

          .table-thead {
            tr {
              th {
                position: sticky;
                top: 0;
                height: 34px;
                background: var(--color-bg-Table-top, #EFF1F6);
                padding: 8px;
                // border: 1px solid #ccc;
                z-index: 10;
                color: var(--color-text-body-1, #151515);
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                padding: 7px 12px 7px 16px;
                white-space: nowrap;
                border-bottom: 1px solid var(--color-line-table-line, #E7E9EC);

                &::after {
                  position: absolute;
                  right: 0px;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 1px;
                  height: 22px;
                  background: rgba(0, 0, 0, 0.06);
                  display: inline-block;
                  content: "";
                }

                &.required {
                  &::before {
                    color: #F00;
                    font-family: Roboto;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 22px;
                    display: inline-block;
                    content: "*";
                    margin-right: 5px;
                  }
                }
              }

              &>th:nth-child(1) {
                position: sticky;
                left: 0px;
                z-index: 13;
              }
            }
          }

          .table-tbody {
            tr {

              // border-bottom: 1px solid #EBEBEB;
              td {
                background-color: #fff;
                padding: 6px 12px 6px 16px;
                height: 44px;
                color: #484848;
                text-align: center;
                font-size: 12px;
                font-weight: 400;
                line-height: 22px;
                position: relative;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 150px;
                border-bottom: 1px solid #EBEBEB;

                input {
                  min-width: unset;
                }

                &.sticky {
                  position: sticky;
                  z-index: 1;
                  box-shadow: inset -10px 0 8px -8px rgba(5, 5, 5, 0.06);
                }
              }
            }

          }
        }

        &::-webkit-scrollbar {
          width: 0px;
          height: 5px;
        }

        &::-webkit-scrollbar-corner {
          background-color: rgba(41, 42, 44, 1);
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 6px;
          background-color: rgba(53, 86, 145, 0.18);
          border: 1px solid rgb(219, 219, 219);
          cursor: pointer;
        }

        &::-webkit-scrollbar-track {
          border-radius: 4px;
        }

        // scrollbar-width: none;
        // /* Firefox */
        // -ms-overflow-style: none;
      }
    }
  }
}