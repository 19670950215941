.routeCommon-details {
	z-index: 98;
	position: absolute;
	bottom: 0;
	overflow: hidden;
	left: 0;
	width: 100%;
	box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.08);
	background: #f3f3f3;
	height: 40px;
	:global {
		.routeCommon-details-header {
			width: 100%;
			border-top: 1px solid rgba(0, 0, 0, 0.06);
			border-bottom: 1px solid rgba(0, 0, 0, 0.06);
			padding: 3px 24px;
			background: #eff1f5;
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: n-resize;
		}
		.routeCommon-details-container {
			width: 100%;
			// padding: 25px 26px 0px 32px;
			height: calc(100% - 40px);
			display: flex;
			flex-direction: column;
			.routeCommon-details--active {
				display: flex;
			}
			.routeCommon-details-table {
				.ant-spin-nested-loading {
					.ant-spin-container {
						.ant-table {
							height: 100%;
							.ant-table-container {
								.ant-table-header {
									.ant-table-thead {
										tr {
											th {
												padding: 4px 8px;
												color: #333;
												font-size: 12px;
												font-weight: 450;
												line-height: 22px;
												background: #e6eaef;
											}
										}
									}
								}
								.ant-table-body {
									height: calc(100% - 31px) !important;
									tr {
										td {
											padding: 4px 8px;
											color: #484848;
											font-size: 12px;
											font-weight: 400;
											line-height: 22px;
										}
									}
								}
								.areaVessel-list-operation {
									display: flex;
									gap: 12px;
									& > * {
										color: #355691;
										font-size: 12px;
										font-weight: 400;
										line-height: 22px; /* 183.333% */
										cursor: pointer;
									}
								}
							}
						}
					}
				}
				&.pagination {
					.ant-table {
						height: calc(100% - 47px) !important;
					}
				}
			}
		}
	}
}
