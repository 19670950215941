.invoices-item {
  padding: 8px 16px;
  width: 100%;
  height: 79px;
  border-bottom: 1px solid var(--color-line-separator, #E7E9EC);
  cursor: pointer;

  display: grid;
  grid-template-columns: repeat(auto-fit, 100%);
  grid-template-rows: 22px repeat(auto-fit, calc((100% - 22px)/2));
  :global{
    .invoicesItem-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .invoicesItem-period {
        color: var(--color-text-body-1, #151515);
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
      .invoicesItem-tag {
        padding: 0 4px;
        color: var(--color-bg-accent-lime-bolder, #7BA70D);
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        &.final {
          color: #d29894
        }
      }
    }
    .invoicesItem-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--color-text-body-3, #A3A3A3);
      font-size: 12px;

      font-weight: 400;
      line-height: 20px;
      gap: 10px;

      &>:first-child {
        white-space: nowrap;
      }
    }
  }
}

.invoices-item-active {
  background: var(--color-elevation-surface-overlay-pressed, #EBEEF4);
  border-bottom: 1px solid var(--color-line-separator-2, #CFD3DA);
}