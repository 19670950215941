.estimation-vesselArrival {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  :global {
    .vesselArrival-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 6px;
      .vesselArrival {
        width: 100%;
        display: grid;
        padding: 12px 0px;
        border-radius: 6px;
        border: 1px solid rgba(217, 217, 217, 0.85);
        background: #F5F6F9;
        grid-template-columns: repeat(auto-fit, calc((100% / 6)));
        grid-template-rows: repeat(auto-fit, 42px);
  
        &>* {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
  
          &>:first-child {
            color: var(--color-text-body-2, #7D7D7D);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
          }
  
          &>:last-child {
            color: #333;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
          }
        }
  
        &>:not(:last-child) {
          &::after {
  
            background-color: #d9d9d9;
            display: inline-block;
            width: 1px;
            height: 32px;
            content: '';
            position: absolute;
            right: 0px;
            top: 5px;
          }
        }
      }
    }
  }
}