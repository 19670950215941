.fleet-toast {
  width: 277px;
  min-height: 86px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  background: #FFF;
  justify-content: space-between;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

  :global {
    .toast-header {
      width: 100%;
      // height: 22px;
      display: flex;
      align-items: center;

      .header-icon {
        margin-right: 8px;
        width: 14px;
        height: 14px;
      }

      &>span {
        color: rgba(0, 0, 0, 0.85);
        
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }

    .toast-actions {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      height: 24px;

      .toast-action {
        display: flex;
        padding: 0px 7px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        // color: var(--character-title-88, rgba(0, 0, 0, 0.88));
        
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        cursor: pointer;
      }

      &>:first-child.toast-action {
        color: rgba(0, 0, 0, 0.88);
        border: 1px solid rgba(0, 0, 0, 0.04);
        // background: var(--button-color-bg-container, #FFF);
        background: #FFF;
        /* drop-shadow/button-secondary */
        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
        margin-right: 8px;
      }

      &>:last-child.toast-action {
        background: #355691;
        color: #fff;
        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
        border: 0px;
      }
    }
  }
}