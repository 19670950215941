.routeCommon-aisGroupItem {
	width: 100%;
	gap: 8px;
	display: flex;
	flex-direction: column;
	padding: 6px 4px 4px;
	border: 1px solid transparent;
	:global {
		.aisGroupItem-header {
			width: 100%;
			justify-content: space-between;
			align-items: center;
			display: flex;
			.aisGroupItem-groupEdit {
				gap: 6px;
				display: flex;
				align-items: center;
				.groupEdit-expandIcon {
					transition: all 300ms ease-out;
					transform: rotateZ(-90deg);
				}
				& > :nth-child(2) {
					width: 10px;
					height: 10px;
					border-radius: 5px;
				}
				& > :nth-child(3) {
					color: #333;
					font-size: 14px;

					font-weight: 500;
					line-height: 20px;
				}
			}
			.aisGroupItem-operation {
				display: flex;
				align-items: center;
				gap: 8px;
			}
			.aisGroupItem-icon {
				font-size: 16px;
				cursor: pointer;
			}
		}
		.aisGroupItem-container {
			display: flex;
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 8px;
			& > .dragging {
				opacity: 0.4;
			}
		}
	}
}

.routeCommon-aisGroupItem--expand {
	:global {
		.aisGroupItem-header {
			.aisGroupItem-groupEdit {
				.groupEdit-expandIcon {
					transform: rotateZ(-180deg);
				}
			}
		}
		.aisGroupItem-container {
			display: none;
		}
	}
}
