.common-select--list {
	width: 300px;

	:global {
		.select--popup--header {
			& > * {
				// grid-template-columns: repeat(auto-fit, calc((100% - 12px)/2));
				gap: 0px;
				grid-template-columns: repeat(auto-fit, 100%);
			}
		}

		.select--popup--container {
			.select--popup--scrollArea {
				.select--popup--item {
					gap: 0px;
					grid-template-columns: repeat(auto-fit, 100%);
					// grid-template-columns: repeat(auto-fit, calc((100% - 12px)/2));
				}
			}
		}
	}
}
