.database-contactEdit {
  width: 468px;
  height: 100%;
  background: #FFF;
  box-shadow: -4px 0px 20px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;

  :global {
    .contactEdit-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 68px;
      margin-bottom: 16px;
      border-bottom: 1px solid #D9D9D9;
      padding-bottom: 16px;

      .header-title {
        display: flex;
        &>* {
          color: #ADADAD;
          text-align: center;
          font-size: 20px;
          font-weight: 450;
          line-height: 22px;

          &.businessType {
            color: rgba(0, 0, 0, 0.88);
          }
        }
      }

      .header-operator {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        .operator-close {
          width: 16px;
          height: 16px;
          display: flex;
          cursor: pointer;
        }

        .operator-save {}
      }
    }

    .contactEdit-container {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, calc((100% - 20px)/2));
      grid-template-rows: repeat(auto-fit, 56px);
      gap: 8px 20px;
      .contactEdit-item {
        display: flex;
        align-items: flex-end;
        .input-container {
          width: 149px;
          .input {
            border-right: 0px;
            border-radius: 6px 0px 0px 6px;
          }
        }
        .cascader-container {
          width: calc(100% - 149px);
          .ant-select-selector {
            border-radius: 0px 6px 6px 0px;
          }
        }
      }
    }

    .contactEdit-textarea {
      width: 100%;
      height: 118px;
      display: flex;
      flex-direction: column;
      margin-top: 8px;
    }
  }
}