.portRotation-item {
  width: 262px;
  border-radius: 6px;
  border: 1px solid #D9D9D9;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;

  :global {
    .portRotation-item--header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 12px;
      border-bottom: 1px solid #D9D9D9;

      .port-name {
        color: #484848;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        display: flex;
        align-items: center;

        &::before {
          border-radius: 11px;
          background: #254377;
          content: "";
          display: inline-block;
          width: 6px;
          height: 6px;
          margin-right: 4px;
        }
      }

      .header-close {
        font-size: 16px;
      }
    }

    .portRotation-item--container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 4px;

      .portRotation-item--formItem {
        width: 100%;
        height: 56px;
        display: grid;
        align-items: flex-end;
        grid-template-rows: repeat(auto-fit, 56px);

        &.full {
          grid-template-columns: repeat(auto-fit, 100%);
        }

        &.eightyPercent {
          grid-template-columns: 161px 71px;
          gap: 4px;
        }

        &.bisector {
          grid-template-columns: repeat(auto-fit, calc((100% - 4px)/2));
          gap: 4px;
        }
        input {
          min-width: unset;
        }

      }
    }
    :nth-child(2) {
      margin-bottom: 0px;
    }
  }
}