.invFreight-basics {
	:global {
		.invoicesForm-contain {
			width: 100%;
			gap: 16px 8px;
			display: grid;
			align-items: flex-end;
			grid-template-columns: 350px 200px 200px;
		}
	}
}
