.fleet-vesselSelect {
  height: 270px;
  width: 558px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 6px;

  :global {
    .vesselSelect-common {
      display: grid;
      width: 100%;
      grid-template-columns: 165px 100px 71px 71px 71px;
      gap: 8px;
      grid-template-rows: repeat(auto-fit, 22px);

      &>* {
        display: grid;
        width: 100%;
        grid-template-columns: 165px 100px 71px 71px 71px;
        gap: 8px;
        grid-template-rows: repeat(auto-fit, 22px);

        .vesselSelect-item {
          color: #707070;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }

    .vesselSelect-header {
      width: 100%;
      height: 38px;
      border-radius: 6px 6px 0px 0px;
      background-color: #EFF1F5;
      padding: 8px 16px;
      border-bottom: 1px solid rgba(217, 217, 217, 0.87);

      .vesselSelect-item {
        color: #484848;
        font-weight: 500;
      }
    }

    .vesselSelect-container {
      width: 100%;
      height: calc(100% - 76px);
      overflow: hidden auto;
      background: #FFF;
      display: flex;
      flex-direction: column;
      padding: 12px 16px 0px;
      gap: 8px;

      &>* {
        padding-bottom: 8px;
        border-bottom: 1px solid #E6E6E6;
      }
    }

    .vesselSelect-footer {
      width: 100%;
      padding: 8px 0px;
      border-radius: 0px 0px 6px 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;

      &>:first-child {
        color: #467996;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
      }
      &>:last-child {
        font-size: 13px;
      }
    }
  }
}

.common-vesselSelect--Popup {
  height: 270px;
  width: 558px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  position: relative;
  :global{
    .vesselSelect--popup--header {
      width: 100%;
      height: 38px;
      border-radius: 6px 6px 0px 0px;
      background-color: #EFF1F5;
      padding: 8px 16px;
      border-bottom: 1px solid rgba(217, 217, 217, 0.87);
      display: grid;
      gap: 12px;
      grid-template-rows: repeat(auto-fit, 22px);
      grid-template-columns: 165px 100px 71px 71px 71px;
      &>* {
        color: #484848;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .vesselSelect--popup--container {
      padding: 8px 16px;
      width: 100%;
      height: calc(100% - 76px);
      border-radius: 0px 0px 6px 6px;
      overflow: hidden auto;
      background: #FFF;
      .vessel--popup--scrollArea {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        .vessel--popup--item {
          width: 100%;
          height: 31px;
          display: grid;
          gap: 12px;
          grid-template-columns: 165px 100px 71px 71px 71px;
          grid-template-rows: repeat(auto-fit, 22px);
          border-bottom: 1px solid #E6E6E6;
          padding-bottom: 8px;
          &>* {
            color: #707070;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .vesselSelect--popup--loadMore {
      width: 100%;
      height: 38px;
      padding: 0px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      gap: 6px;
      color: #467996;
      &>:first-child {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
      &>:last-child {
        font-size: 16px;
        transform: rotateZ(-90deg);
      }
    }
  }
}