.routeCommon-legend {
	display: none;
	flex-direction: column;
	gap: 10px;
	width: 100%;
	align-items: flex-end;
	:global {
		.routeCommon-legend-item {
			display: flex;
			gap: 10px;
			align-items: center;
			.routeCommon-legend-label {
				font-size: 12px;
				font-weight: bolder;
			}
		}
	}
}

.routeCommon-legend--active {
	display: flex;
}
