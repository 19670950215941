.movement-insureForm {
	width: 870px;
	display: flex;
	flex-direction: column;
	gap: 12px;
	background-color: #fff;
	padding: 20px;
	border-radius: 6px;
	:global {
		.insureForm {
			width: 100%;
			.header--title {
				color: #484848;
				font-size: 16px;
				font-weight: 500;
				line-height: 19px;
				display: flex;
				align-items: center;

				&::before {
					content: "";
					display: inline-block;
					margin-right: 8px;
					border-radius: 32px;
					background: #355691;
					width: 6px;
					height: 6px;
				}
			}
		}
	}
}
