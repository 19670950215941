@font-face {
  font-family: "font_family"; /* Project id 4454671 */
  src: url('iconfont.eot?t=1741080593052'); /* IE9 */
  src: url('iconfont.eot?t=1741080593052#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('iconfont.woff2?t=1741080593052') format('woff2'),
       url('iconfont.woff?t=1741080593052') format('woff'),
       url('iconfont.ttf?t=1741080593052') format('truetype'),
       url('iconfont.svg?t=1741080593052#font_family') format('svg');
}

.font_family {
  font-family: "font_family" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fleet-368typhoon:before {
  content: "\e74b";
}

.fleet-kongshuju:before {
  content: "\e620";
}

.fleet-youlun:before {
  content: "\e653";
}

.fleet-message1:before {
  content: "\e60c";
}

.fleet-a-Labprofile:before {
  content: "\e6a3";
}

.fleet-bl1:before {
  content: "\e6a4";
}

.fleet-list-view:before {
  content: "\e6a2";
}

.fleet-bl:before {
  content: "\e6a0";
}

.fleet-pnl:before {
  content: "\e6a1";
}

.fleet-ENC:before {
  content: "\e69f";
}

.fleet-typhoon70:before {
  content: "\e698";
}

.fleet-typhoon_70:before {
  content: "\e699";
}

.fleet-typhoon58:before {
  content: "\e69a";
}

.fleet-typhoon50:before {
  content: "\e69b";
}

.fleet-typhoon42:before {
  content: "\e69c";
}

.fleet-typhoon_32:before {
  content: "\e69d";
}

.fleet-directions:before {
  content: "\e69e";
}

.fleet-swell:before {
  content: "\e68e";
}

.fleet-sigwave:before {
  content: "\e68f";
}

.fleet-loadline:before {
  content: "\e690";
}

.fleet-typhoon:before {
  content: "\e691";
}

.fleet-pressure:before {
  content: "\e692";
}

.fleet-ports:before {
  content: "\e693";
}

.fleet-hrawarzones:before {
  content: "\e694";
}

.fleet-current:before {
  content: "\e695";
}

.fleet-ecazones:before {
  content: "\e696";
}

.fleet-wind:before {
  content: "\e697";
}

.fleet-forward:before {
  content: "\e68b";
}

.fleet-rewind:before {
  content: "\e68c";
}

.fleet-play:before {
  content: "\e68d";
}

.fleet-pause:before {
  content: "\e687";
}

.fleet-line:before {
  content: "\e688";
}

.fleet-point_local:before {
  content: "\e689";
}

.fleet-circle1:before {
  content: "\e68a";
}

.fleet-area:before {
  content: "\e683";
}

.fleet-leg:before {
  content: "\e684";
}

.fleet-port1:before {
  content: "\e685";
}

.fleet-ais:before {
  content: "\e686";
}

.fleet-port_background:before {
  content: "\e682";
}

.fleet-drag:before {
  content: "\e681";
}

.fleet-close2:before {
  content: "\e67c";
}

.fleet-warn1:before {
  content: "\e67d";
}

.fleet-route1:before {
  content: "\e67e";
}

.fleet-line_chart:before {
  content: "\e67f";
}

.fleet-edit2:before {
  content: "\e680";
}

.fleet-add1:before {
  content: "\e67b";
}

.fleet-ship:before {
  content: "\e679";
}

.fleet-search:before {
  content: "\e67a";
}

.fleet-arrow_right:before {
  content: "\e678";
}

.fleet-arrow_left:before {
  content: "\e677";
}

.fleet-legend_show:before {
  content: "\e673";
}

.fleet-legend_hide:before {
  content: "\e674";
}

.fleet-show1:before {
  content: "\e675";
}

.fleet-hide1:before {
  content: "\e676";
}

.fleet-circle:before {
  content: "\e671";
}

.fleet-straight:before {
  content: "\e672";
}

.fleet-a-perreport:before {
  content: "\e665";
}

.fleet-biinsights:before {
  content: "\e666";
}

.fleet-marketinsights:before {
  content: "\e664";
}

.fleet-sorter:before {
  content: "\e661";
}

.fleet-sorter-up:before {
  content: "\e662";
}

.fleet-sorter-down:before {
  content: "\e663";
}

.fleet-filter1:before {
  content: "\e65f";
}

.fleet-plus:before {
  content: "\e655";
}

.fleet-minus:before {
  content: "\e656";
}

.fleet-history:before {
  content: "\e652";
}

.fleet-shortcut:before {
  content: "\e650";
}

.fleet-message:before {
  content: "\e64f";
}

.fleet-more:before {
  content: "\e651";
}

.fleet-bell:before {
  content: "\e7c4";
}

.fleet-a-slideup:before {
  content: "\e64e";
}

.fleet-check:before {
  content: "\e64b";
}

.fleet-routeing:before {
  content: "\e64c";
}

.fleet-invoices:before {
  content: "\e64d";
}

.fleet-send:before {
  content: "\e649";
}

.fleet-comparison:before {
  content: "\e64a";
}

.fleet-update1:before {
  content: "\e648";
}

.fleet-uselaber:before {
  content: "\e647";
}

.fleet-deploy:before {
  content: "\e646";
}

.fleet-spread-out:before {
  content: "\e645";
}

.fleet-warn:before {
  content: "\e644";
}

.fleet-update:before {
  content: "\e642";
}

.fleet-setting:before {
  content: "\e643";
}

.fleet-avatar:before {
  content: "\e641";
}

.fleet-hide:before {
  content: "\e63e";
}

.fleet-show:before {
  content: "\e63f";
}

.fleet-save:before {
  content: "\e640";
}

.fleet-add-square:before {
  content: "\e63b";
}

.fleet-copy:before {
  content: "\e63c";
}

.fleet-arrow-right:before {
  content: "\e63d";
}

.fleet-relational-graph:before {
  content: "\e864";
}

.fleet-user:before {
  content: "\e61c";
}

.fleet-peoples:before {
  content: "\e6d4";
}

.fleet-Calender:before {
  content: "\e8b4";
}

.fleet-email:before {
  content: "\e624";
}

.fleet-phone:before {
  content: "\e603";
}

.fleet-particular:before {
  content: "\e639";
}

.fleet-chart:before {
  content: "\e63a";
}

.fleet-position:before {
  content: "\e638";
}

.fleet-excel:before {
  content: "\e637";
}

.fleet-collect:before {
  content: "\e635";
}

.fleet-collected:before {
  content: "\e636";
}

.fleet-back:before {
  content: "\e634";
}

.fleet-delete:before {
  content: "\e633";
}

.fleet-download:before {
  content: "\e632";
}

.fleet-port:before {
  content: "\e630";
}

.fleet-arrow:before {
  content: "\e631";
}

.fleet-add:before {
  content: "\e62f";
}

.fleet-close:before {
  content: "\e62e";
}

.fleet-filter:before {
  content: "\e62d";
}

.fleet-database:before {
  content: "\e628";
}

.fleet-monitoring:before {
  content: "\e629";
}

.fleet-perf-report:before {
  content: "\e62a";
}

.fleet-estimation:before {
  content: "\e62b";
}

.fleet-a-voyageManagement:before {
  content: "\e62c";
}

