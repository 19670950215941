.handoverForm-performance {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  :global{
    .performance-textarea {
      width: 100%;
      &.performance--clause {
        height: 131px;
      }
      &.performance--goodWeather {
        height: 185px;
      }
    }

    .performance-goodWeatherRef {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}