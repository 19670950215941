.voyageManage-attach-upload {
	padding: 16px;
	background-color: #fff;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 462px;
	position: relative;
	:global {
		.attach-upload-header {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-radius: 6px 6px 0px 0px;
			.header-title {
				color: #484848;
				font-size: 20px;
				font-weight: 500;
				line-height: normal;
				display: flex;
				align-items: center;

				&::before {
					content: "";
					width: 6px;
					height: 14px;
					border-radius: 38px;
					background: #355691;
					display: inline-block;
					margin-right: 8px;
				}

				/* 140% */
			}
			.header-close {
				font-size: 14px;
				cursor: pointer;
			}
		}
		.attach-upload-container {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 8px;
			.uploadfile-item {
				width: 100%;
				border-radius: 4px;
				border: 1px solid #d9d9d9;
				background: #fff;
				padding: 9px 8px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.uploadfile-info {
					display: flex;
					gap: 8px;
					align-items: center;

					& > :first-child {
						border-radius: 6px;
						width: 48px;
						height: 48px;
					}

					& > :last-child {
						color: #355691;
						font-size: 14px;
						font-weight: 400;
						line-height: 22px;
						width: 300px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						/* 157.143% */
					}
				}

				.uploadfile-remove {
					font-size: 14px;
					cursor: pointer;
				}
			}
		}
		.attach-upload-footer {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 10px;
		}
	}
}
