.legend-current {
	:global {
		.legend-current-container {
          min-width: 250px;
			background: linear-gradient(
				to right,
				#404d90,
				#404d90,
				#404d90,
				#404d90,
				#3d796e,
				#328c32,
				#8c8531,
				#8f7332,
				#753444,
				#6b4383,
				#435d85,
				#497a84,
				#73878b,
				#909090,
				#909090
			);
			display: flex;
			border-radius: 15px;
      align-items: center;
			& > * {
				height: 22px;
				width: calc(100% / 7);
				text-align: center;
				color: #fff;
        font-size: 12px;
        line-height: 22px;
        font-weight: 400;
			}
			& > :first-child {
				border-radius: 15px 15px 0px 0px;
			}
			& > :last-child {
				border-radius: 0px 0px 15px 15px;
			}
		}
	}
}
