.handoverForm-bunkerAndPerformance {
  :global {
    .handoverForm-table {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-radius: 6px;
      border: 1px solid var(--Table_Outline, #D2DAE4);

      .table-common {
        display: grid;
        width: 100%;
        grid-template-rows: repeat(auto-fit, 38px);

        input {
          min-width: unset;
        }

        &>* {
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--character-title-85, rgba(0, 0, 0, 0.85));
          text-align: center;
          font-size: 12px;
          font-weight: 400;
          line-height: 22px;
          position: relative;
          border-bottom: 1px solid var(--Conditional-divider, rgba(0, 0, 0, 0.06));
        }

        &.thead {
          &>* {
            padding: 7px 16px;
            background: #EFF1F5;
          }
          &>:not(:last-child) {
            &::after {
              position: absolute;
              right: 0px;
              top: 50%;
              transform: translateY(-50%);
              width: 1px;
              height: 22px;
              background: #E7E9EC;
              display: inline-block;
              content: "";
            }
          }
          &>:first-child {
            border-radius: 6px 0px 0px 0px;
          }
  
          &>:last-child {
            border-radius: 0px 6px 0px 0px;
          }
        }

        &.tbody {
          grid-template-rows: repeat(auto-fit, 42px);
          &>* {
            padding: 4px 8px;


          }
        }
      }

      &.bunker-clause {
        .table-common {
          grid-template-columns: 118px 114px 114px 114px 114px 146px 146px;
        }
      }

      &.performance {
        .table-common {
          grid-template-columns: 118px 93px 106px 106px 106px 106px 231px;
        }
      }
    }
  }
}