.handoverForm-bunkerClause {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  :global{
    .bunkerClause-textarea {
      width: 100%;
      height: 117px;
    }
  }
}