.database-header {
  width: 100%;
  height: 49px;
  background: #FFF;
  display: flex;
  flex-direction: column;
  position: relative;
  :global{
    .database-header-tabs {
      height: 48px;
    }
    .database-header-line {
      width: 100%;
      height: 1px;
      background: #D9D9D9;;
    }
  }
}