.fleet-aisData {
  width: 100%;
  display: flex;
  flex-direction: column;

  :global {
    .aisData-header {
      width: 100%;
      height: 32px;
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;

      .aisData-toolbar {
        display: flex;
        align-items: center;
        gap: 11px;
        .aisData-search {
          align-items: center;

          .aisData-input {
            // width: 105px;
            width: 65px;
            border-radius: 40px;
            border: 1px solid #D9D9D9;

            input {
              min-width: unset;
            }
          }
        }
      }
    }

    .aisData-lineChart {
      width: 100%;
      height: 187px;
      border-radius: 6px;
      border: 1px solid rgba(217, 217, 217, 0.87);
      background: #F5F7F9;
      margin-bottom: 12px;
      padding: 21px 53px 0px 53px;
      position: relative;

      .lineChart {
        width: 100%;
        height: 100%;
      }

      .lineChart-actions {
        position: absolute;
        top: 11px;
        right: 21px;
        display: flex;
        align-items: center;

        span {
          color: #484848;
          
          font-size: 12px;
          font-weight: 400;
          line-height: 22px;
          display: flex;
          align-items: center;
        }

        &>span:first-child {
          margin-right: 16px;

          &::before {
            width: 10px;
            height: 10px;
            background-color: #FF8632;
            border-radius: 5px;
            content: "";
            display: inline-block;
            margin-right: 4px;
          }
        }

        &>span:last-child {
          &::before {
            width: 10px;
            height: 10px;
            background-color: #355691;
            border-radius: 5px;
            content: "";
            display: inline-block;
            margin-right: 4px;
          }
        }
      }
    }
  }
}