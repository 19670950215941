.movement-taskList {
	width: 100%;
	background: #fff;
	box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.05);
	padding: 10px 50px;
	height: 83px;
	display: flex;
	gap: 26px;

	:global {
		.taskList-header {
			width: 150px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			position: relative;
			.taskList-cursor {
				display: flex;
				gap: 8px;
				align-items: center;

				& > :first-child {
					font-size: 20px;
					transition: transform 300ms ease-out;
					cursor: pointer;
					&.launch {
						transform: rotateZ(180deg);
					}
				}

				& > :last-child {
					color: var(--color-text-body-1, #151515);
					font-size: 16px;
					font-weight: 510;
					line-height: 24px;
				}
			}
			.taskList-stage {
				width: 100%;
				display: grid;
				grid-template-columns: 16px calc(100% - 24px);
				gap: 8px;
			}
		}

		.taskList-container {
			width: calc(100% - 176px);
			overflow: auto hidden;
			&::-webkit-scrollbar {
				width: 0px;
				height: 5px;
				// display: none;
			}

			&::-webkit-scrollbar-corner {
				background-color: rgba(41, 42, 44, 1);
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 6px;
				background-color: rgba(53, 86, 145, 0.18);
				border: 1px solid rgb(219, 219, 219);
				cursor: pointer;
			}

			&::-webkit-scrollbar-track {
				border-radius: 4px;
			}

			.taskList-scrollArea {
				display: inline-flex;
				gap: 8px;
				padding-bottom: 4px;
				height: 100%;

				.taskList-item {
					display: flex;
					gap: 2px;
					border-radius: 4px;
					border: 1px solid rgba(0, 0, 0, 0.1);
					padding: 4px 24px;
					cursor: pointer;
					flex-direction: column;
					transition: all 300ms ease-in-out;
					.taskList-item--portInfo {
						display: flex;
						align-items: center;
						gap: 8px;
						& > :first-child {
							color: var(--color-text-body-1, #151515);
							font-size: 14px;
							font-weight: 510;
							line-height: 22px;
							/* 157.143% */
							display: flex;
							align-items: center;
							white-space: nowrap;

							&::before {
								content: "";
								display: inline-block;
								margin-right: 8px;
								width: 10px;
								height: 10px;
								background: #404040;
							}
						}
						& > :last-child {
							color: var(--color-text-body-2, #7d7d7d);

							font-size: 12px;
							font-weight: 400;
							line-height: 20px;
							white-space: nowrap;
						}
					}

					& > :last-child {
						color: var(--color-text-body-2, #7d7d7d);

						font-size: 12px;
						font-weight: 400;
						line-height: 20px;
					}

					&.current-stage {
						border-color: transparent;
						background: #fa8d18;
						.taskList-item--portInfo {
							& > :first-child {
								color: #fff;

								&::before {
									background: #fff;
								}
							}
							& > :last-child {
								color: #fff;
							}
						}
						& > :last-child {
							color: #fff;
						}
					}

					&.active {
						border-color: transparent;
						background: rgb(53, 86, 145);
						.taskList-item--portInfo {
							& > :first-child {
								color: #fff;
								&::before {
									background: #fff;
								}
							}
							& > :last-child {
								color: #fff;
							}
						}
						& > :last-child {
							color: #fff;
						}
					}

					&.active {
						border-color: transparent;
						background: rgb(53, 86, 145);
						.taskList-item--portInfo {
							& > :first-child {
								color: #fff;

								&::before {
									background: #fff;
								}
							}
							& > :last-child {
								color: #fff;
							}
						}
						& > :last-child {
							color: #fff;
						}
					}
				}

				.taskList-lines {
					background: #3b3b3b;
					width: 144.4px;
					height: 2px;
					margin-top: 12px;
				}
			}
		}
	}
}
