.fleet-radio {
  display: flex;
  height: 32px;
  align-items: center;
  :global {
    .radio-icon {
      width: 16px;
      height: 16px;
      display: flex;
      cursor: pointer;
      user-select: none;
    }

    .radio-label {
      color: #484848;
      
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      /* 183.333% */
      margin-left: 10px;
      user-select: none;
      cursor: pointer;
    }
  }
}