.fleet-category {
  width: 948px;
  // height: 484px;
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 6px;
  background: #FFF;
  padding: 16px;

  :global {
    .category-operate {
      width: 100%;
      display: flex;
      height: 32px;
      margin-bottom: 12px;
      color: #707070;
      
      font-size: 14px;
      font-weight: 500;
      align-items: center;
      gap:12px;
      
    }

    .category-select {
      width: 100%;
      height: calc(100% - 44px);
      display: flex;
      overflow: auto hidden;

      :last-child {
        margin-right: 0px;
      }

      &::-webkit-scrollbar {
        width: 0px;
        height: 4px;
        // display: none;
      }

      &::-webkit-scrollbar-corner {
        background-color: none;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: rgba(53, 86, 145, 0.18);
        border: 1px solid rgb(219, 219, 219);
      }

      &::-webkit-scrollbar-track {
        border-radius: 2px;
      }

      scrollbar-width: none;
      /* Firefox */
      -ms-overflow-style: none;
    }
  }
}

.fleet-category-checkboxGroup {
  margin-right: 32px;

  :global {
    .checkbox-all {
      margin-bottom: 12px;

      &>:first-child {
        margin-right: 8px;
      }

      &>:last-child {
        white-space: nowrap;
        padding: 0px;
        color: rgba(72, 72, 72, 0.88);

        
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }

    }

    .checkbox-group {
      padding-left: 8px;
      position: relative;

      &::before {
        width: 1px;
        height: 100%;
        background: #D9D9D9;
        content: '';
        position: absolute;
        left: 8px;
        top: 0px;
      }

      &>label {
        margin-bottom: 10px;
        display: flex;
        width: 100%;
        padding-left: 14px;

        &>:first-child {
          margin-right: 8px;
        }

        &>:last-child {
          white-space: nowrap;
          padding: 0px;
          color: rgba(72, 72, 72, 0.88);

          
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          /* 157.143% */
        }
      }

      &>label:last-child {
        margin-bottom: 0px;
      }
    }
  }
}