.fleet-basic {
  margin-left: 10px;
  :global {
    .basic-item {
      height: 50px;
      align-items: center;
      width: 100%;
      .label {
        font-size: 16px;
        line-height: 50px;
        width: 180px !important;
        text-align: right;
      }
      .basic-item-inner{
        width:100%;
      }
    }
  }
}
