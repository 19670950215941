.handoverForm-bunkerPlan {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  :global {
    .bunkerPlan-container {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, calc((100% - 24px)/4));
      gap: 8px;
      grid-template-rows: repeat(auto-fit, 56px);

      &.time-select {
        grid-template-columns: repeat(auto-fit, calc((100% - 16px)/3));
      }
    }
    .bunkerPlan-remark--textarea {
      width: 100%;
      height: 76px;
    }
  }
}