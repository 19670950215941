.voyagemanage-header {
  width: 100%;
  height: 49px;
  background: #FFF;
  display: flex;
  flex-direction: column;
  :global{
    .voyagemanage-header-tabs {
      height: 48px;
    }
    .voyagemanage-header-line {
      width: 100%;
      height: 1px;
      background: #D9D9D9;;
    }
  }
}