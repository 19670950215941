.preOperation-voyageItem {
	width: 100%;
	border-radius: 6px;
	border: 1px solid #d9d9d9;
	background: #fff;
	padding: 13px 16px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	gap: 8px;

	:global {
		.voyageItem-container {
			width: 100%;
			gap: 8px;
			display: grid;
			grid-template-columns: repeat(auto-fit, calc((100% - 8px) / 2));
			grid-template-rows: 22px;

			& > * {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				color: #707070;
				font-size: 14px;
				font-weight: 400;
				line-height: 22px;
				align-self: flex-end;
			}

			& > :last-child {
				text-align: right;
			}

			&.voyageItem--userInfo {
				padding-bottom: 8px;
				border-bottom: 1px solid #d9d9d9;

				& > .user-info {
					display: flex;
					gap: 6px;

					& > :first-child {
						font-size: 14px;
						color: #355691;
					}

					& > :last-child {
						width: calc(100% - 20px);
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						color: #707070;
						font-size: 14px;
						font-weight: 400;
						line-height: 22px;
						text-align: left;
						/* 157.143% */
					}
				}

				.voyageItem-operator {
					display: flex;
					gap: 4px;
					align-items: center;
					justify-content: flex-end;
					.operator-delete {
						font-size: 14px;
						color: #ee605b;
					}
					& > .voyageItem-voyageType {
						padding: 0px 4px;
						border-radius: var(--spacing-1, 2px);
						color: var(--color-bg-accent-fleetblue-bolder, #355691);
						font-size: 14px;
						font-weight: 400;
						line-height: 22px;
						width: fit-content;
						justify-self: flex-end;
						&.TCTC {
							background: rgba(9, 209, 125, 0.12);
							color: #06b169;
						}
						&.TCVC {
							background: var(--color-bg-accent-fleetblue-subtler, #ebeef4);
							color: var(--color-bg-accent-fleetblue-bolder, #355691);
						}
					}
				}
			}

			&.voyageItem--voyageInfo {
				grid-template-columns: repeat(auto-fit, calc((100% - 16px) / 3));
				& > :nth-child(2) {
					text-align: right;
				}
			}
		}
	}
}

.preOperation-voyageItem--active {
	border-color: #355691;
	background: linear-gradient(0deg, rgba(53, 86, 145, 0.04) 0%, rgba(53, 86, 145, 0.04) 100%), #fff;
	box-shadow: 0px 0px 0px 3px rgba(53, 86, 145, 0.15);
}
