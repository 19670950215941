.movement-table {
  // width: 1186px;
  width: 100%;
  height: 166px;
  overflow: auto;

  :global {
    .table-layout {
      width: max-content;
      border-collapse: collapse;

      .table-thead {
        tr {
          th {
            position: sticky;
            top: 0;
            height: 35px;
            border-bottom: 1px solid var(--color-line-table-line, #E7E9EC);
            background: #F8F8F9;
            padding: 7px 12px 7px 16px;
            text-align: left;
            z-index: 1;
            color: var(--color-text-body-1, #151515);
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            white-space: nowrap;

            &.need-verity {
              &>span {
                &::before {
                  content: "*";
                  margin-right: 2px;
                  display: inline-block;
                  color: #F00;
                }
              }
            }

            &.sorted {
              &>.sorted-container {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                &>:last-child {
                  cursor: pointer;
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }

          &>:not(:last-child) {
            &::after {
              position: absolute;
              right: 0px;
              top: 50%;
              transform: translateY(-50%);
              width: 1px;
              height: 16px;
              background: #E7E9EC;
              display: inline-block;
              content: "";
            }
          }
        }
      }

      .table-tbody,
      .table-tfoot {
        tr {
          td {
            background-color: #fff;
            padding: 6px 12px 6px 12px;
            border-bottom: 1px solid #F0F0F0;
            height: 39px;
            color: var(--color-text-Gray-95, #222);
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            /* 157.143% */
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            .td-actions {
              display: flex;
              gap: 4px;
              max-width: 130px;

              .fleet-delete {
                font-size: 14px;
                color: #EE605B;
                cursor: pointer;
              }
            }

            .td-input {
              height: 28px;
              border-color: transparent;
              transition: 200ms all ease-in-out;

              input {
                font-size: 14px;
                min-width: unset;
              }

              &:focus-within {
                border-color: #D9D9D9;
              }

              &:hover {
                border-color: #D9D9D9;
              }
            }

            .td-select {
              height: 28px;

              .ant-select-selector {
                height: 28px;
                border-radius: 4px;
                border: 1px solid var(--Neutral-5, #D9D9D9);
                opacity: 0.8;

                /* drop-shadow/button-secondary */
                box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

                input {
                  height: 100%;
                }

                .ant-select-selection-item {
                  line-height: 28px;
                  font-size: 14px;
                  padding-inline-end: 12px;
                }
              }
            }
          }

          &.summary {
            td {
              position: sticky;
              bottom: 0;
              padding: 2px 12px 2px 16px;
              height: 28px;
              background: #F8F8F9;
              color: var(--color-text-Gray-95, #222);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              text-align: center;
              border: 0px;
              /* 157.143% */
              &>* {
                padding-left: 12px;
              }
            }

            &>:not(:last-child) {
              &::after {
                position: absolute;
                right: 0px;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 16px;
                background: #E7E9EC;
                display: inline-block;
                content: "";
              }
            }
          }

          &.tbody-blank {
            max-height: 45px;
          }
        }

      }

      .table-tfoot {
        position: sticky;
        bottom: 0px;
        left: 0px;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 0px;
    height: 5px;
  }

  &::-webkit-scrollbar-corner {
    background-color: rgba(41, 42, 44, 1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: rgba(53, 86, 145, 0.18);
    border: 1px solid rgb(219, 219, 219);
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }

  // scrollbar-width: none;
  // /* Firefox */
  // -ms-overflow-style: none;
}

.movement-table--bordered {
  border-radius: 4px;
  border: 1px solid #E0E6F0;
}

.movement-table--summary {
  display: flex;
  :global{
    .table-layout {
      flex-grow: 1;
      
    }
  }
}