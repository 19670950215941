.fleet-upload-item {
  width: 100%;
  height: 66px;
  border-radius: 6px;
  border: 1px solid #D9D9D9;
  background: #FFF;
  padding: 9px 8px;
  margin-bottom: 4px;
  display: flex;
  position: relative;

  :global {
    .upload-file {
      display: flex;
      width: calc(100% - 56px);
      margin-right: 8px;
      height: 100%;

      .file-icon {
        width: 48px;
        height: 100%;
        display: flex;
        margin-right: 8px;
      }

      .file-text {
        width: calc(100% - 56px);
        height: 100%;
        color: #355691;
        
        font-size: 14px;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 3;
        // line-height: 22px; /* 157.143% */
      }
    }

    .upload-actions {
      width: 48px;
      height: 100%;
      display: flex;
      align-items: center;

      .upload-action__preview {
        width: 16px;
        height: 16px;
        margin-right: 13px;
        cursor: pointer;
      }

      .upload-action__delete {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}

.fleet-upload-item__error {
  border: 1px solid #FF4D4F;
}

.fleet-upload-item__uploading {
  background: rgba(0, 0, 0, 0.40);

  :global {
    .uploading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #FFF;

      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
    }
  }
}

.fleet-upload {
  width: 100%;
  display: flex;
  flex-direction: column;

  :global {
    .upload-select {
      &>input {
        display: none;
      }
    }

    .upload-list {
      width: 100%;
      display: flex;
      flex-direction: column;

      .list-divider {
        width: 100%;
        height: 1px;
        background: #D9D9D9;
        margin-bottom: 11px;
        margin-top: 20px;
      }
    }

    .ant-upload-drag {
      background-color: unset;
      border: 0px;

      .upload-dragger {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .dragger-icon {
          width: 48px;
          height: 48px;
          margin-bottom: 20px;
        }

        .dragger-title {
          color: rgba(0, 0, 0, 0.88);
          text-align: center;
          
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 4px;
        }

        .dragger-text {
          color: rgba(0, 0, 0, 0.45);
          text-align: center;
          
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }

    div.ant-upload {
      margin-bottom: 20px;

      &>.ant-upload-btn {
        padding: 0px
      }
    }

    .ant-upload-list {
      &::before {
        width: 100%;
        height: 1px;
        background: #D9D9D9;
        margin-bottom: 11px;
      }
    }
  }
}