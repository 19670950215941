.movement-taskDetails-comment {
  width: 414px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 11px;
  position: relative;

  :global {
    .comment-header {
      .comment-header--remark {
        width: fit-content;
        background: #E0E6F0;
        padding: 4px 15px;
        border-radius: 4px;
        background: #E0E6F0;
        color: #151515;
        font-size: 16px;
        font-weight: 510;
        line-height: 24px;
        /* 150% */
      }
    }

    .comment-container {
      width: 100%;
      height: calc(100% - 43px);
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.10);
      background: #F8F8F9;
      padding: 14px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .comment-container--taskTitle {
        color: #787878;
        font-size: 14px;
        font-weight: 510;
        line-height: 22px;
        height: 22px;
      }

      .comment-container--commentlist {
        width: 100%;
        height: calc(100% - 127px);
        overflow: hidden auto;

        .commentlist-scrollArea {
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 100%;
        }

        &::-webkit-scrollbar {
          width: 0px;
          height: 4px;
          // display: none;
        }

        &::-webkit-scrollbar-corner {
          background-color: none;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          background-color: rgba(53, 86, 145, 0.18);
          border: 1px solid rgb(219, 219, 219);
        }

        &::-webkit-scrollbar-track {
          border-radius: 2px;
        }
      }

      .comment-container--send {
        background: #FFF;
        width: 100%;
        height: 85px;
        .textarea {
          padding: 4px 8px;
          border-radius: 4px;
          border: 1px solid rgba(0, 0, 0, 0.20);
          color: #333;
        }
        .comment-send--btn {
          background: #355691;
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 16px;
          position: absolute;
          right: 8px;
          bottom: 8px;
          z-index: 1;
          transform: rotateZ(-45deg);
          cursor: pointer;
          .font_family {
            font-size: 18px;
            color: #fff;
          }
          &.disabled {
            cursor: not-allowed;
            opacity: .4;
          }
        }
      }

    }
  }
}