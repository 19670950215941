.fleet-select {
  height: 32px;
  display: flex;
  align-items: center;
  border: 1px solid #D9D9D9;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 5px 12px;
  // position: relative;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

.fleet-select-options {
  display: none;
  // position: fixed;
  // top: 0px;
  // // left: 0px;
  // right: 0px;
  // // bottom: 0px;
  // // width: 100%;
  // z-index: 99;
  border-radius: 6px;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);

}

.fleet-select-options--open {
  display: inline-table;
}