.movement-summary {
  width: 100%;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;

  :global {
    .summary-contianer {
      width: 100%;
      display: grid;
      gap: 6px;
      grid-template-columns: repeat(auto-fit, calc((100% - 36px)/ 7));
      grid-template-rows: repeat(auto-fit, 72px);

      &>* {
        border-radius: 4px;
        background: #F5F7F9;
        padding: 10px 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &>.summary-label {
          color: var(--color-text-body-2, #7D7D7D);
          font-size: 12px;
          font-weight: 510;
          line-height: 20px;
          /* 166.667% */
        }

        .summary-value {
          color: var(--color-text-body-1, #151515);
          font-size: 20px;
          font-weight: 500;
          line-height: 22px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &::before {
            color: inherit;
            font-size: 20px;
            font-weight: 500;
            line-height: 22px;
            margin-right: 2px;
            content: "$";
            display: inline-block;
          }
          &.highest {
            color: var(--color-bg-accent-Orange-bolder, #FA8D18);
          }
        }
      }
    }
  }

}

.movement-summary-tctc {
  :global {
  .summary-contianer {
     grid-template-columns: repeat(auto-fit, calc((100% - 30px)/ 6));
  }
  }
}