.invoicesVoyage-list {
  width: 362px;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 18px 16px;
  background: var(--color-elevation-surface-overlay, #F7F8FA);

  :global {
    .voyageList {
      width: 100%;
      overflow: hidden auto;
      height: calc(100% - 85px);

      .voyageList-scrollArea {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 12px 0px;

        .active {
          border: 1px solid var(--color-border-card-pressed, #355691);
          background: var(--color-elevation-surface-overlay-pressed, #EBEEF4);
          box-shadow: 0px 0px 0px 3px rgba(53, 86, 145, 0.15);
        }
      }
    }
  }
}