.preOperation-voyageList {
	height: 100%;
	width: 397px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 28px 20px 20px;

	:global {
		.voyageList-header {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 24px;

			.fleet-title {
				color: #484848;
				font-size: 24px;
				font-weight: 500;
				line-height: 28px;
				margin-left: 11px;

				&::before {
					width: 8px;
					height: 18px;
				}
			}

			.voyageList-header--filter {
				width: 100%;
				gap: 8px;
				display: grid;
				grid-template-columns: 268px 1fr;

				.voyageList-header--add {
					& > .fleet-button--icon {
						transform: rotateZ(90deg);
					}
				}

				.voyageList-header--add--overlay {
					padding: 0px;
				}

				&.role-add-empty {
					grid-template-columns: repeat(auto-fit, 100%);
					gap: 0px;
				}
			}
		}

		.voyageList-container {
			width: 100%;
			height: calc(100% - 108px);
			overflow: hidden auto;
			position: relative;
			padding-right: 4px;
			&.role-add-empty {
				height: calc(100% - 108px);
			}
			.voyageList-scrollArea {
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: 8px;
			}

			&::-webkit-scrollbar {
				width: 4px;
				height: 0px;
			}

			&::-webkit-scrollbar-corner {
				background-color: rgba(41, 42, 44, 1);
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 6px;
				background-color: rgba(53, 86, 145, 0.18);
				border: 1px solid rgb(219, 219, 219);
				cursor: pointer;
			}

			&::-webkit-scrollbar-track {
				border-radius: 4px;
			}
		}
	}
}
