.estimation-list {
  padding: 27px 19px 30px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 12px;

  :global {
    .estimation-table {
      width: 100%;
      height: calc(100% - 70px);

      .td-operate {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        .operate-btn {
          font-size: 12px;
        }
        .operate-icon {
          font-size: 14px;
          cursor: pointer;
          color: #EE605B;
        }
      }
    }
  }
}