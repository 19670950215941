.headFixture-basic {
  :global{
    .basic-form {
      display: grid;
      grid-template-columns: repeat(auto-fit,428px);
      grid-template-rows: repeat(auto-fit,56px);
      gap: 0px 8px;
      margin-top: 12px;
    }
  }
}