.database-vesselArchives {
  :global{
    .vesselArchives-toolbar {

      .toolbar-actions {
        display: flex;
        gap: 10px;

        .font_family {
          margin-right: 8px;
          font-size: 14px;
        }
      }
    }
    .database-table {
      .td-operate {
        gap: 10px;
        display: flex;
        align-items: center;

        &>:first-child {
          cursor: pointer;
          color: #355691;
          font-size: 12px;
          font-weight: 400;
          line-height: 22px;
          /* 183.333% */
        }
      }
    }
  }
}