.fleet-latitudeSelect {
  width: 595px;
  padding: 8px 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  display: flex;
  border-radius: 6px;
  background: #FFF;
  gap: 8px;

  :global {
    .latitudeSelect-header {
      width: 100%;
      display: flex;
      gap: 139px;

      &>.header-item {
        display: flex;
        gap: 8px;
        align-items: center;

        &>:first-child {
          color: #484848;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }

        .latitudeSelect-tabbar {
          height: fit-content;

          &>* {
            line-height: normal;
            width: 25px;
          }
        }
      }
    }

    .latitudeSelect-container {
      width: 100%;
      display: flex;
      height: 32px;

      .latitudeSelect-wrap {
        width: calc(100% - 77px);
        margin-right: 8px;
        height: 100%;
        position: relative;

        .latitudeSelect-input {
          position: absolute;
          height: 100%;
          opacity: 0;
          width: 80px;
          left: 0px;
          top: 0px;
          z-index: 2;
          padding: 0px;

          input {
            min-width: unset;
          }

          &.focus {
            z-index: 4;
          }

          &.blur {
            z-index: 2;
          }
        }

        .latitudeSelect-content {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0px;
          top: 0px;
          display: flex;
          z-index: 3;

          .latitudeSelect-item {
            display: flex;
            width: 80px;
            height: 100%;
            margin-right: 4px;
            position: relative;

            &>span {
              margin-right: 4px;
              height: 100%;
              width: 68px;
              display: flex;
              border-radius: 6px;
              border: 1px solid rgb(217, 217, 217, .6);
              background: #FFF;
              padding: 5px 12px;
              color: rgba(0, 0, 0, 0.25);

              font-size: 14px;
              font-weight: 400;
              line-height: 22px;
              cursor: pointer;
            }

            &::after {
              color: #484848;

              font-size: 20px;
              font-weight: 400;
              line-height: 22px;
              display: inline-block;
            }

            &.active {
              &>span {
                border: 1px solid #254377;

                // animation: flicker 2s infinite alternate;
                &::after {
                  content: '';
                  width: 1px;
                  height: 100%;
                  background-color: #000;
                  animation: flicker 0.5s infinite alternate;
                }
              }
            }
          }

          &>.latitudeSelect-item:nth-child(1) {
            &::after {
              content: "°";
            }
          }

          &>.latitudeSelect-item:nth-child(2) {
            &::after {
              content: "'";
            }
          }

          &>.latitudeSelect-item:nth-child(3) {
            &::after {
              content: '"';
            }
          }

          &>.latitudeSelect-item:nth-child(4) {
            &::after {
              content: "°";
            }
          }

          &>.latitudeSelect-item:nth-child(5) {
            &::after {
              content: "'";
            }
          }

          &>.latitudeSelect-item:nth-child(6) {
            &::after {
              content: '"';
            }
          }

          &>.latitudeSelect-item:last-child {
            margin-right: 0px;
          }
        }
      }

      .latitudeSelect-action {
        width: 69px;
        height: 100%;
        align-items: center;
        justify-content: center;

        .latitudeSelect-icon {
          width: 16px;
          height: 16px;
          display: flex;
        }
      }
    }
  }
}