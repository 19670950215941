.route-areaSelectList {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: #ffffff;
	border-radius: 4px;
	padding-top: 6px;
	gap: 6px;
	:global {
		.areaSelectList-header {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			& > :first-child {
				color: rgba(30, 30, 30, 1);
				font-size: 13px;
				font-weight: 400;
				line-height: 22px;
				background: rgba(40, 83, 195, 0.1);
				border-radius: 4px;
				padding: 0px 4px;
			}
			& > :last-child {
				color: #2853c3;
				font-size: 13px;
				font-weight: 400;
				line-height: 20px;
				cursor: pointer;
			}
		}
		.areaSelectList-container {
			width: 100%;
			height: calc(100% - 28px);
			overflow: hidden auto;
			&::-webkit-scrollbar {
				width: 5px;
			}
			&::-webkit-scrollbar-thumb {
				width: 5px;
				border-radius: 4px;
				background-color: rgba(192, 197, 207, 0.8);
			}
		}
	}
}
