.voyageManage-handoverForm {
  width: 100%;
  overflow: hidden auto;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;


  :global {
    .handoverForm-container {
      width: 893px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .handoverForm--common {
        width: 100%;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        background: #FBFBFB;
        display: flex;
        flex-direction: column;
        padding: 12px;
        gap: 16px;

        .handoverForm--common--header {
          width: 100%;
          color: var(--color-text-body-1, #151515);
          font-size: 16px;
          font-weight: 590;
          line-height: 24px;
          padding-bottom: 8px;
          border-bottom: 1px solid #E7E9EC;

          &.secondary {
            color: var(--color-text-body-3, #A3A3A3);
          }
        }

        .handoverForm--common--remarks {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 2px;

          .handoverForm--remarks--header {
            width: 100%;
            gap: 16px;
            display: flex;
            align-items: center;

            &>:first-child {
              color: #484848;
              font-size: 12px;
              font-weight: 400;
              line-height: 22px;
              display: flex;
              align-items: center;

              &::before {
                content: "*";
                margin-right: 2px;
                display: inline-block;
                color: #F00;
              }

              /* 183.333% */
            }
          }

          .handoverForm--remarks--textarea {
            width: 100%;
            background: rgba(250, 250, 250, 0.60);
            height: 76px;
          }
        }
      }
    }

    .handoverForm-anchor {
      position: sticky;
      right: -40px;
      display: flex;
      top: 0px;
      gap: 12px;
      height: fit-content;
      align-items: center;

      .anchor-scrollBar--scrollArea {
        width: 6px;
        height: 132px;
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 3px;
        .anchor-scrollBar {
          display: inline-block;
          width: 6px;
          height: 19px;
          border-radius: 3px;
          background-color: #355691;
          transition: all 300ms ease-in-out;
        }
      }

      .anchor-list {
        gap: 10px;
        display: flex;
        flex-direction: column;
        width: 100px;
        &>* {
          overflow: hidden;
          color: rgba(173, 173, 173, 0.50);
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 16px;
          font-weight: 510;
          line-height: 19px;
          cursor: pointer;
          transition: all 300ms ease-in-out;
          &.active {
            color: #355691;
          }
        }
      }
    }
  }
}