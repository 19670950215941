.fleet-input {
	height: 32px;
	display: flex;
	align-items: center;
	border: 1px solid #d9d9d9;
	background-color: rgb(255, 255, 255);
	border-radius: 6px;
	// padding: 8px 10px;
	padding: 5px 12px;

	:global {
		.input-prefix {
			width: 16px;
			background-color: rgb(255, 255, 255);
			background-color: inherit;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;

			img {
				height: 16px;
			}
		}
		.input-icon {
			font-size: 16px;
			line-height: 1;
			cursor: pointer;
		}
		.font_family {
			font-size: 16px;
			line-height: 1;
			cursor: pointer;
		}

		input {
			height: 100%;
			width: 100%;
			// color: rgba(0, 0, 0, 0.25);

			min-width: 110px;
			font-size: 14px;
			font-weight: 500;
			border: 0px;
			outline: none;

			&::placeholder {
				color: rgba(0, 0, 0, 0.25);
			}

			&.prefix {
				width: calc(100% - 26px);
				margin-left: 10px;
			}
			&.suffix {
				width: calc(100% - 26px);
				margin-right: 10px;
			}

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
			}

			&[type="number"] {
				-moz-appearance: textfield;
			}
		}
	}
}

.fleet-verity {
	display: flex;
	flex-direction: column;

	:global {
		.verity-header {
			width: 100%;
			display: flex;
			margin-bottom: 2px;
			align-items: center;
			.verity-text {
				color: #484848;

				font-size: 12px;
				font-weight: 400;
				line-height: 22px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;

				&::before {
					content: "\*";
					color: #f00;
					text-align: center;

					font-size: 12px;
					font-weight: 400;
					line-height: 22px;
					margin-right: 2px;
					/* 183.333% */
				}

				&.disabled {
					&::before {
						display: none;
					}
				}
			}
			.verity-icon {
				width: 12px;
				height: 12px;
				display: flex;
				margin-left: 2px;
				cursor: pointer;
			}
		}
	}
}

.disabled {
	background: #f2f2f2;
}
