.fleet-popover {
	display: none;
	z-index: 99;
	&.open {
		display: block;
	}
	.fleet-popover-content {
		width: fit-content;
		height: fit-content;
		padding: 6px;
		border-radius: 6px;
		box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
		background-color: #fff;
	}
}

.fleet-popover-trigger {
	width: auto;
	height: auto;
	display: inline-flex;
}
