.database-commonEdit {
  height: 100%;
  width: 648px;
  overflow: hidden;
  background: #fff;
  padding: 18px 16px 0px;
  box-shadow: -4px 0px 20px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 13px;

  :global {
    .commonEdit-header {
      width: 100%;
      height: 54px;
      display: flex;
      justify-content: space-between;

      &>* {
        display: flex;
        height: 100%;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
      }

      &>:first-child {

        &>:first-child {
          color: var(--color-text-body-1, #151515);
          font-size: 20px;
          font-weight: 510;
          line-height: 28px;
          /* 140% */
        }

        &>:last-child {
          align-items: flex-end;
          color: var(--color-text-body-3, #A3A3A3);
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          /* 166.667% */
        }
      }

      &>:last-child {
        align-items: flex-end;

        .header-close {
          width: 16px;
          height: 16px;
          display: flex;
        }
      }
    }

    .commonEdit-container {
      width: 100%;
      height: calc(100% - 67px);
      overflow: hidden auto;

      .commonEdit-scrollArea {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .commonEditItem {
          width: 100%;
          display: flex;
          flex-direction: column;
          .commonEditItem-label {
            color: var(--color-text-body-1, #151515);

            font-size: 14px;
            font-weight: 510;
            line-height: 22px;
            /* 157.143% */
            width: 100%;
            padding-bottom: 8px;
            margin-bottom: 8px;
            border-bottom: 1px solid #d9d9d9;
          }

          .commonEditItem-container {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fit, calc((100% - 16px)/3));
            grid-template-rows: repeat(auto-fit, 56px);
            gap: 8px;
          }
        }
      }
    }
  }
}