.fleet-tooltip {
  .fleet-popover-content {
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    line-height: 22px;
    font-size: 14px;
  }

  &>svg {
    fill: rgba(0, 0, 0, 0.85);
    // stroke: rgba(0, 0, 0, 0.85);
  }
}