.common-infinityScroll {
	overflow: hidden auto;
	width: 100%;
	height: 100%;
	position: relative;
	:global {
		.infinityScroll-scrollArea {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 6px;
		}
	}
}
