.routeCommon-vesselCard-operator {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, calc((100% - 6px) / 2));
	position: relative;
	gap: 6px;
	:global {
		.operator-item {
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			gap: 6px;
			border: 1px solid rgba(217, 217, 217, 1);
			padding: 6px;
			border-radius: 4px;
			& > :last-child {
				color: rgba(30, 30, 30, 1);
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				/* 183.333% */
			}
			&.save {
				width: 100%;
				.operator-item-icon {
					color: #d9d9d9;
				}
				&.active {
					.operator-item-icon {
						color: #ff8632;
					}
				}
			}
		}
	}
}

.routeCommon-vesselCard-tracking {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 6px;
	:global {
		.tracking-item {
			display: flex;
			width: 100%;
			justify-content: space-between;
			align-items: center;
			.tracking-item--label {
				color: rgba(117, 117, 117, 1);
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
			}
			.tracking-item--value {
				color: rgba(30, 30, 30, 1);
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				&.shipStatus {
					color: rgba(39, 158, 20, 1);
				}
			}
		}
	}
}
