.invoices-views-commonHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-bottom: 1px solid #E7E9EC;
  padding-bottom: 12px;

  :global {
    .commonHeader-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header-container {
        display: flex;
        gap: 12px;
        align-items: center;

        .header-title {
          color: var(--color-text-body-1, #151515);
          font-size: 24px;
          font-weight: 590;
          line-height: 32px;
        }

        .header-tags {
          padding: 0px 4px;
          border-radius: var(--spacing-1, 2px);
          background: var(--color-bg-accent-lime-subtler, #F6FBE7);
          color: var(--color-bg-accent-lime-bolder, #7BA70D);
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }
      }

      .header-updateDate {
        color: var(--color-text-body-3, #A3A3A3);
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }

    .commonHeader-container {
      color: var(--color-text-body-3, #A3A3A3);
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.invoices-views-commonHeader--desc {
  gap: 8px;
  padding-bottom: 8px;
}