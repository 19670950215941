.estimation-portRotation {
  padding: 20px 24px;
  border-radius: 6px;
  background: #FFF;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 12px;
  :global {
    .portRotation-container {
      display: flex;
      gap: 12px;
      .portRotation-add {
        height: 648.5px;
        width: 262px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        border: 1px dashed rgba(53, 86, 145, 0.39);
        background: rgba(53, 86, 145, 0.10);
        
        .add-container {
          font-size: 58px;
          color: rgba(53, 86, 145, 0.20);
          cursor: pointer;
        }
      }
    }
  }
}