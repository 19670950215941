.headFixture-bunkerClause {
  :global {
    .bunkerClause-bod {
      width: 100%;
      height: 130px;
      display: flex;
      flex-direction: column;
      border-radius: 6px;
      // --Table_Outline
      border: 1px solid #D2DAE4;
      margin-bottom: 16px;

      .bob-common {
        &>:nth-child(1).bob-item {
          width: 118px;
        }
        &>:nth-child(2).bob-item {
          width: 113px;
        }

        &>:nth-child(3).bob-item {
          width: 114px;
        }

        &>:nth-child(4).bob-item {
          width: 113px;
        }

        &>:nth-child(5).bob-item {
          width: 113px;
        }

        &>:nth-child(6).bob-item {
          width: 145px;
        }

        &>:last-child.bob-item {
          width: 146px;
        }
      }

      .bod-header {
        width: 100%;
        height: 37px;
        // --conditional-divider
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        background: #EFF1F5;
        display: flex;
        align-items: center;
        border-radius: 6px 6px 0px 0px;

        .bob-item {
          padding: 7px 16px;
          height: 100%;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.88);
          text-align: center;
          
          font-weight: 450;
          line-height: 22px;
          position: relative;
          /* 183.333% */
          // border-bottom: 1px solid var(--conditional-divider, rgba(0, 0, 0, 0.06));
          &::after {
            content: "";
            display: inline-block;
            width: 1px;
            background: rgba(0, 0, 0, 0.06);
            height: 22px;
            right: 0px;
            position: absolute;
          }
        }

        &>:nth-child(1).bob-item {
          border-radius: 6px 0px 0px 0px;
          /* 183.333% */
        }

        &>:last-child.bob-item {
          border-radius: 0px 0px 0px 6px;
          &::after {
            display: none;
          }
        }
      }

      .bod-content {
        width: 100%;
        display: flex;
        // --conditional-divider
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        height: 41px;

        .bob-item {
          width: 114px;
          height: 100%;
          align-items: center;
          padding: 4px 8px;
          input{
            min-width: unset;
          }
        }

        &>:first-child.bob-item {
          // color: var(--character-title-85, rgba(0, 0, 0, 0.85));
          color: rgba(0, 0, 0, 0.85);
          
          font-size: 12px;
          font-weight: 400;
          line-height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          /* 183.333% */
        }
      }
    }

    .bunkerClause-textarea {
      width: 100%;
      height: 112px;
      // padding: 16px;
      margin-bottom: 24px;
      border-radius: 6px;
      // border: 1px solid rgba(53, 86, 145, 0.15);

      .textarea {
        background: #F5F7F9;
        // border: 0px;
        // color: var(--Main-Text, #484848);
        textarea {
          line-height: normal;
          background: #F5F7F9;
          // color: var(--Main-Text, #484848);
          color:#484848 ;
          
          font-size: 14px;
          font-weight: 400;
        }
      }
    }

    .bunkerClause-goodWeather {
      height: 176px;
      margin-bottom: 0px;
    }
  }
}