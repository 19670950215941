.movement-tctc-vesselBasic {
	width: 100%;
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	gap: 12px;
	padding: 16px;

	:global {
		.vesselBasic-header {
			gap: 12px;
			justify-content: flex-start;
			align-items: center;

			.vesselBasic-voyageNo {
				display: flex;
				gap: 6px;
				align-items: center;

				& > :first-child {
					color: #707070;
					font-size: 12px;
					font-weight: 400;
					line-height: 20px;
					/* 166.667% */
				}

				& > :last-child {
					font-size: 12px;
					cursor: pointer;
				}
			}
		}

		.vesselBasic-container {
			width: 100%;
			display: grid;
			grid-template-columns: repeat(auto-fit, calc((100% - 36px) / 4));
			grid-template-rows: repeat(auto-fit, 32px);
			gap: 12px;
			.vesselBasic-item-redel {
				width: 100%;
				display: grid;
				grid-template-columns: 234px calc(100% - 240px);
				grid-template-rows: repeat(auto-fit, 32px);
				gap: 6px;

				& > :last-child {
					color: #d10e0e;
					font-size: 14px;
					font-weight: 400;
					line-height: 22px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: var(--radius-m, 4px);
					border: 1px solid var(--color-border-input-disable, #ededed);
					background: var(--color-bg-input-disable, #f3f3f3);
					white-space: nowrap;
				}
			}
		}

		.vesselBasic-item {
			width: 100%;
			display: grid;
			grid-template-columns: repeat(auto-fit, calc((100% - 12px) / 2));
			gap: 12px;
			grid-template-rows: repeat(auto-fit, 32px);

			.vesselBasic-item--container {
				display: grid;
				gap: 6px;
				grid-template-rows: repeat(auto-fit, 32px);

				&.broker-container {
					grid-template-columns: repeat(auto-fit, calc((100% - 18px) / 4));
				}

				&.rate-container {
					grid-template-columns: repeat(auto-fit, calc((100% - 6px) / 2));
				}

				&.redel-container {
					grid-template-columns: 234px calc(100% - 240px);

					& > :last-child {
						color: #d10e0e;
						font-size: 14px;
						font-weight: 400;
						line-height: 22px;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: var(--radius-m, 4px);
						border: 1px solid var(--color-border-input-disable, #ededed);
						background: var(--color-bg-input-disable, #f3f3f3);
					}
				}
				input {
					min-width: unset;
				}
			}
		}
	}
}
