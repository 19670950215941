.routeCommon-list {
	width: 300px;
	min-width: 300px;
	height: calc(100% - 110px);
	overflow: hidden;
	transition: all 0.3s;
	position: absolute;
	top: 21px;
	left: 22px;
	z-index: 98;
	box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.06);
	:global {
		.routeCommon-list-common {
			width: 100%;
			flex-direction: column;
			gap: 12px;
			height: 100%;
			.routeCommon-list-header {
				box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
			}
			.routeCommon-list-container {
				width: 100%;
				height: calc(100% - 44px);
				border-radius: 6px;
				border: 1px solid rgba(217, 217, 217, 0.6);
				opacity: var(--sds-size-stroke-border);
				background: #fff;
				// box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
				padding: 14px;
				transition: all 300ms ease-in-out;
			}
		}
	}
}

.routeCommon-list--hidden {
	height: fit-content;
	:global {
		.routeCommon-list-common {
			.routeCommon-list-container {
				display: none;
			}
		}
	}
}
