.freight-base {
  :global {
    .invoices-base--header {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, calc((100% - 24px) / 4));
      grid-template-rows: repeat(auto-fit, 56px);
      gap: 8px;
    }
  }
}