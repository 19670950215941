.voyagemanage-preOperation {
  display: flex;
  position: relative;
  min-width: 1440px;

  :global {
    .preOperation-voyage {
      width: calc(100% - 397px);
      height: 100%;
      border-left: 1px solid #D9D9D9;
    }
  }
}