.handoverForm-headFixture {
  :global {
    .headFixture-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .headFixture-basicInfo {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, calc((100% - 24px)/4));
        grid-template-rows: repeat(auto-fit, 56px);
        gap: 2px 8px;

        &.est-redel {
          grid-template-columns: 330px 210px 210px 1fr;
        }
      }

      .headFixture-shipownerRemark {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2px;

        .shipownerRemark--header {
          width: 100%;
          gap: 16px;
          display: flex;
          align-items: center;

          &>:first-child {
            color: #484848;
            font-size: 12px;
            font-weight: 400;
            line-height: 22px;
            display: flex;
            align-items: center;

            &::before {
              content: "*";
              margin-right: 2px;
              display: inline-block;
              color: #F00;
            }

            /* 183.333% */
          }
        }

        .shipownerRemark--textarea {
          width: 100%;
          background: rgba(250, 250, 250, 0.60);
          height: 76px;
        }
      }
    }
  }
}