.movement-taskDetails-taskItem {
  width: 100%;
  border-radius: 6px;
  border: 1px solid #E6EBF0;
  background: #FFF;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  transition: all 300ms ease-in-out;
  cursor: pointer;

  :global {
    .taskItem-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .taskItem-select {
        height: unset;
        width: fit-content;
        cursor: pointer;
      }

      .taskItem-dialog--open {
        width: 22px;
        height: 22px;
        border-radius: 2px;
        border: 1px solid #DEDEDE;
        display: flex;
        align-items: center;
        justify-content: center;

        .font_family {
          font-size: 14px;
        }
      }
    }

    .taskItem-content {
      color: #787878;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
    }

    .taskItem-level {
      width: 100%;
      display: flex;
      gap: 12px;
      align-items: center;

      .taskItem-level--label {
        color: var(--Normal-Text, #ADADAD);
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
      }

      .taskItem-level--tabs {
        display: flex;
        gap: 4px;

        &>* {
          border-radius: 2px;
          border: 1px solid rgba(137, 137, 137, 0.22);
          background: rgba(99, 99, 99, 0.06);
          padding: 1px 6px;
          color: #858585;
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          cursor: pointer;

          &.low {
            background: rgba(46, 195, 52, 0.28);
            color: #0CA112;
          }

          &.medium {
            color: #FA8D18;
            background: rgba(250, 141, 24, 0.28);
          }

          &.high {
            background: #fcdcdc;
            color: #EC0404;
          }
        }
      }
    }

    .taskItem-footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .taskItem-footer--deadLine {
        display: flex;
        gap: 6px;
        align-items: center;

        .deadLine--dateSelect {
          padding: 0px;
          border: 0px;
          box-shadow: none;
          background-color: inherit;
          width: 123px;

          .ant-picker-suffix {
            display: none;
          }

          input {
            color: var(--Normal-Text, #ADADAD);
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            /* 157.143% */
            size: 16;
          }
        }

        .font_family {
          font-size: 16px;
          color: #ADADAD;
        }
      }

      .taskItem-footer--comment {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 3px;

        &>:last-child {
          color: var(--Normal-Text, #ADADAD);
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          /* 157.143% */
        }

        .font_family {
          font-size: 14px;
          color: #ADADAD;
        }
      }
    }


  }
}