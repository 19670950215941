.vessel-filter {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;
  :global{
    .filter-label{
      color: #707070;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
    }
    .filter-container {
      display: flex;
      height: 32px;
      gap: 8px;
      &>*:not(:first-child){
        width: 160px;
      }
      &>:first-child {
        width: 254px;
      }
      .tabs {
        width: 178px;
        margin-bottom: 0px;
        .tab-item {
          padding: 0px 16px;
          // width: calc(100% / 2);
          width: unset;
        }
      }
    }
  }
}