.invoices-template-common {
  width: 100%;
  border-radius: 6px;
  margin-top: 64px;
  margin-bottom: 20px;
  position: relative;

  :global {
    #invoices-template {
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0px 2.13303px 12.79816px 0px rgba(0, 0, 0, 0.10);
      position: absolute;
      z-index: 1;
      top: 0px;
      left: 0px;
      width: 930px;
      height: 2000px;
    }

    #invoices-mark {
      background-color: transparent;
      border-radius: 6px;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 2;
    }

    .export {
      position: absolute;
      z-index: 3;
    }
  }
}