.freightInv-desciption {
	:global {
		.invoicesDetail-form {
			.invoicesForm-ports {
				grid-template-columns: repeat(auto-fit, 408px) !important;
			}

			.invoicesForm-cargos {
				grid-template-columns: 200px 240px 200px 200px;

				.invoicesFormCargo-form {
					display: grid;
					height: 100%;
					grid-template-rows: repeat(auto-fit, 100%);
					grid-template-columns: repeat(auto-fit, calc(100% - 36px)) 32px;
					gap: 4px;

					&.quantity {
						grid-template-columns: repeat(auto-fit, calc(100% - 74px)) 70px;
						align-items: flex-end;

						input {
							min-width: unset;
						}
					}

					.fleet-minus {
						font-size: 32px;
						align-self: flex-end;
						cursor: pointer;
						opacity: 0.2;
					}

					.invoicesForm-icon {
						width: 32px;
						height: 32px;
						display: flex;
						cursor: pointer;
						align-self: flex-end;
					}
				}
			}

			.invoicesForm-freightRate {
				display: grid;
				grid-template-rows: repeat(auto-fit, 100%);
				grid-template-columns: repeat(auto-fit, calc(100% - 66px)) 62px;
				gap: 4px;
				height: 100%;
				align-items: flex-end;

				input {
					max-width: unset;
				}
			}

			.invoicesForm-freight {
				display: flex;
				gap: 4px;
				align-items: flex-end;

				input {
					min-width: unset;
				}

				& > :first-child {
					width: 136px;
				}

				& > :last-child {
					width: 60px;
				}

				.freight-unit {
					.verity-header {
						// justify-content: flex-end;
						padding-left: 12px;
					}
				}
			}

			.invoicesRate-computed {
				color: var(--color-text-placeholder, #c8c8c8);
				font-size: 12px;
				font-weight: 400;
				line-height: 20px;
				/* 166.667% */
			}

			.freightInv-desContainer {
				gap: 8px;
				grid-template-columns: 254px 200px 200px 1fr;
				.desContainer-addCommm {
					input {
						min-width: unset;
					}
				}
				.desContainer-brokerage {
					display: flex;
					gap: 8px;
					input {
						width: 165px;
					}
				}
			}
		}
	}
}
