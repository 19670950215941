.routeCommon-vesselCard-header {
	width: 100%;
	display: flex;
	gap: 8px;
	align-items: center;
	:global {
		.header-warpper {
			width: calc(100% - 24px);
			display: flex;
			gap: 10px;
			align-items: center;
			.header-vesselName {
				color: #1e1e1e;
				font-size: 14px;
				font-weight: 500;
				line-height: 20px;
				display: flex;
				align-items: center;
				gap: 10px;
				&::before {
					display: inline-block;
					content: "";
					width: 6px;
					height: 6px;
					border-radius: 3px;
				}
				&.leg {
					&::before {
						background-color: #1e1e1e;
					}
				}
				&.ais {
					&::before {
						background-color: #5e89f7;
					}
				}
				&.area {
					&::before {
						background-color: #fd881c;
					}
				}
				&.port {
					&::before {
						background-color: #f5cdcd;
					}
				}
			}
			.header-tag {
				font-size: 12px;
				font-weight: 400;
				line-height: 20px;
				/* 166.667% */
				padding: 0px 8px;
				border-radius: 4px;
				&.ais {
					color: rgb(94, 137, 247);
					background-color: rgba(94, 137, 247, 0.3);
				}
				&.port {
					color: #f24a4a;
					background-color: rgba(245, 205, 205, 0.3);
				}
				&.area {
					color: rgb(253, 136, 28);
					background-color: rgba(253, 136, 28, 0.3);
				}
				&.leg {
					color: rgb(30, 30, 30);
					background-color: rgba(30, 30, 30, 0.3);
				}
			}
		}
		.header-close {
			font-size: 16px;
			color: #636363;
		}
	}
}
