.invoicesViews-list {
  width: 195px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--color-line-separator, #E7E9EC);
  border-left: 1px solid var(--color-line-separator, #E7E9EC);
  background: var(--color-elevation-surface-overlay, #F7F8FA);

  :global {
    .invoicesList-scrollArea {
      width: 100%;
      height: calc(100% - 102px);
      overflow: hidden auto;
      position: relative;
    }
  }
}