.movement-vesselBasics {
  width: 680px;
  // height: 192px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;

  :global {
    .vesselBasics-header {
      gap: 12px;
      justify-content: flex-start;
      align-items: center;

      .vesselBasics-voyageNo {
        display: flex;
        gap: 6px;
        align-items: center;

        &>:first-child {
          color: #707070;
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          /* 166.667% */
        }

        &>:last-child {
          font-size: 12px;
          cursor: pointer;
        }
      }
    }

    .vesselBasics-item {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, calc((100% - 12px)/2));
      gap: 12px;
      grid-template-rows: repeat(auto-fit, 32px);

      &.broker {
         grid-template-columns: 1.5fr 1fr;
      }

      .vesselBasics-item--container {
        display: grid;
        gap: 6px;
        grid-template-rows: repeat(auto-fit, 32px);

        &.broker-container {
          grid-template-columns: repeat(auto-fit, calc((100% - 18px)/4));
        }

        &.rate-container {
          grid-template-columns: repeat(auto-fit, calc((100% - 6px)/2));
        }

        &.redel-container {
          grid-template-columns: 234px calc(100% - 240px);

          &>:last-child {
            color: #D10E0E;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: var(--radius-m, 4px);
            border: 1px solid var(--color-border-input-disable, #EDEDED);
            background: var(--color-bg-input-disable, #F3F3F3);
          }
        }
        input {
          min-width: unset;
        }
      }
    }
  }
}