.common-template-popup {
  width: 100%;
  height: 460px;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);

  :global {
    .template-popup {
      width: 100%;
      height: calc(100% - 35px);
      overflow: hidden auto;
      .template-popup-scrollArea {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 4px;
        gap: 2px;
  
        li {
          padding: 6px 8px;
          width: 100%;
          display: flex;
          align-items: center;
          gap: 8px;
          transition: all 300ms ease-in-out;
          cursor: pointer;
  
          &>.label {
            color: var(--color-text-body-1, #151515);
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            width: calc(100% - 20px);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
  
          &>.fleet-close {
            font-size: 12px;
            cursor: pointer;
          }
  
          &:hover {
            border-radius: 4px;
            background: #F8F8F9;
          }
  
          &.active {
            border-radius: 4px;
            background-color: rgba(53, 86, 145,.4);
            color: #F8F8F9;
          }
        }
      }
    }

    .template-popup-loadMore {
      width: 100%;
      position: absolute;
      bottom: 0px;
      left: 0px;
      z-index: 1;
      padding: 6px 8px;
      color: var(--color-text-body-1, #355691);
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      border-top: 1px solid #d9d9d9;
      text-align: center;
      cursor: pointer;
    }
  }
}