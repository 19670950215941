.invoices-histotyDuplicate {
  width: 915px;
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
  background: #FFF;
  border-radius: 6px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
  gap: 12px;

  :global {
    .histotyDuplicate-header {
      width: 100%;
      height: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--color-text-body-1, #151515);
      font-size: 20px;
      font-weight: 590;
      line-height: 28px;
      .header-title {
        display: flex;
        gap: 10px;
        height: 100%;
        align-items: center;

        .invoices-no {
          color: var(--color-text-disable, #909090);
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }
      }

      /* 140% */
      .header-close {
        cursor: pointer;
        font-size: 16px;
      }
    }

    .histotyDuplicate-container {
      width: 100%;
      height: 240px;
      overflow: hidden auto;
      border-radius: 6px;
      border: 1px solid var(--color-line-table-line, #E7E9EC);
      position: relative;

      .histotyDuplicateItem-common {
        width: 100%;
        display: grid;
        grid-template-columns: 132px 173px 175px 140px 140px 107px;
        grid-template-rows: repeat(auto-fit, 34px);
        font-size: 14px;
        font-style: normal;
        border-bottom: 1px solid var(--color-line-table-line, #E7E9EC);
        font-weight: 400;
        line-height: 22px;
        &>* {
          padding: 7px 12px 7px 16px;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          color: var(--color-text-body-1, #151515);
        }
      }

      .histotyDuplicateItem-header {
        background: var(--color-bg-table-top, #EFF1F6);
        font-size: 12px;
        line-height: 20px;
        border-radius: 6px 6px 0px 0px;
        border-bottom: 1px solid var(--color-line-table-line, #E7E9EC);
        position: sticky;
        top: 0px;
      }
    }

    .histotyDuplicate-footer {
      width: 100%;
      height: 32px;
      display: flex;
      justify-content: flex-end;
      gap: 12px;
    }
  }
}