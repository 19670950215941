.routeCommon-map-vesselPopup {
	min-width: 235px;
	padding: 8px 10px;
	display: flex;
	flex-direction: column;
	gap: 4px;
	border-radius: 4px;
	background: rgba(0, 0, 0, 0.65);
	:global {
		.vesselPopup-item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px;
			& > :first-child {
				color: rgba(255, 255, 255, 0.7);
			}
			& > :last-child {
				color: #fff;
			}
			&.status {
				& > :last-child {
					color: #09ff3f;
				}
			}
			&.speed {
				& > :last-child {
					& > :first-child {
						color: #ffa901;
					}
				}
			}
		}
	}
}
