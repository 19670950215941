.monitoring-aisData {
	width: 1088px;
	display: none;
	flex-direction: column;
	padding: 16px;
	border-radius: 4px;
	background-color: #fff;
	box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
	height: fit-content;
	gap: 5px;
	position: absolute;
	top: 70px;
	z-index: 1;
  left: 695px;
	:global {
		.monitoring-aisData--header {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.header--container {
				display: flex;
				gap: 16px;
				align-items: center;
				.aisData-search {
					align-items: center;
					margin-right: 11px;

					.aisData-input {
						// width: 105px;
						width: 65px;
						border-radius: 40px;
						border: 1px solid #d9d9d9;

						input {
							min-width: unset;
						}
					}
				}
			}
		}
	}
}

.monitoring-aisData--active {
	display: flex;
}
