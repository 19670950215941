.movement-currentPlan {
	width: 100%;
	border-radius: 4px;
	background: #fff;
	// box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	gap: 12px;

	:global {
		.movement-details--header {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.details--header-left {
			display: flex;
			align-items: end;
			gap: 15px;
		}
		.currentPlan-header-detail {
			color: #636363;
			font-size: 12px;
		}
		.voyage-message-line {
			padding: 0 16px;
			overflow-x: auto; /* 允许横向内容溢出时出现滚动条 */
			overflow-y: hidden;
			white-space: nowrap; /* 不换行 */
			&::-webkit-scrollbar {
				width: 0px;
				height: 6px;
			}
			&::-webkit-scrollbar-corner {
				background: #cfcfcf;
			}
			&::-webkit-scrollbar-thumb {
				border-radius: 6px;
				background: #cfcfcf;
			}
			&::-webkit-scrollbar-track {
				border-radius: 4px;
			}
			.message-line-stepBox {
				flex: 1;
			}
			.message-line-stepBox:last-child {
				flex: none !important;
			}
			.message-line-step {
				width: 100%;
				max-height: 70px;
				overflow: hidden;
				white-space: nowrap;
				.step-title {
					margin: 5px 0;
					font-size: 14px;
					font-weight: 700;
					color: #333;
					.estimatePort-style {
						display: inline-block;
						width: 18px;
						height: 18px;
						margin-left: 8px;
						font-size: 12px;
						font-weight: 500;
						line-height: 18px;
						color: #fff;
						text-align: center;
						background-color: rgb(53 86 145 / 86%);
						border-radius: 49px;
					}
				}
				.step-content {
					font-size: 12px;
					line-height: 20px;
					color: #aeaeb2;
				}
			}

			.el-step__line {
				position: absolute;
				top: 11px;
				right: 0;
				left: 0;
				height: 0;
				background-color: transparent;
				border-color: inherit;
				&.not-arrived {
					border-top: 2px dashed #637daa;
				}
				&.isFuture {
					border-top: 2px dashed #9235ef;
				}
				&.arrived {
					border-top: 2px solid #ff8632;
				}

				.el-step__line-inner {
					width: 0%;
					border-width: 0;
					transition-delay: -600ms;
				}
			}
			.el-step__icon {
				position: relative;
				z-index: 1;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				padding: 0 5px;
				height: 18px;
				box-sizing: border-box;
				background: var(--el-bg-color);
				transition: 0.15s ease-out;
			}
			.el-step__icon.is-text {
				border-radius: 49px;
				border: 2px solid;
				color: #f8f8f8;
				background-color: #637daa;
				border-color: #637daa;

				.el-step__icon-inner {
					font-size: 12px;
					font-weight: 700;
					user-select: none;
				}

				&.isFuture {
					background-color: #9235ef;
					border-color: #9235ef;
				}
				&.active {
					background-color: #ff8632;
					border-color: #ff8632;
				}
			}
			.icon-ship {
				position: relative;
				font-size: 25px;
				color: #9235ef;
			}
		}
		.voyage-message-container {
			display: flex;
			gap: 8px;
			align-items: center;
			overflow: auto;
			padding-bottom: 5px;
			&::-webkit-scrollbar {
				width: 0px;
				height: 5px;
			}

			&::-webkit-scrollbar-corner {
				background-color: none;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 2px;
				background-color: rgba(53, 86, 145, 0.18);
				border: 1px solid rgb(219, 219, 219);
			}

			&::-webkit-scrollbar-track {
				border-radius: 2px;
			}

			.voyage-message-box {
				border-radius: 4px;
				display: flex;
				flex-direction: column;
				gap: 4px;
				border: 1px solid #d9d9d9;
				padding: 8px 12px;
				.voyage-message-item {
					width: 360px;
					display: flex;
					justify-content: space-between;
					color: #636363;
					font-size: 13px;
				}
			}
		}
		.routeGraph-container {
			display: flex;
			flex-direction: column;
			gap: 12px;
			.routeGraph-map {
				height: 380px;
				position: relative;
				.routeGraph {
					width: 100%;
					height: 100%;
					border-radius: 6px;
					border: 1px solid #d9d9d9;
					overflow: hidden;
				}
				.coordinates-container {
					position: absolute;
					bottom: 20px;
					right: 20px;
					color: #fff;
					font-weight: 2000;
					background-color: rgba(0, 0, 0, 0.5);
					padding: 5px;
					font-size: 6px;
				}
			}
		}
		.routeGraph-showBar {
			display: flex;
			background-color: #f3f3f3;
			justify-content: center;
			align-items: center;
			color: #467996;
			font-size: 15px;
			font-weight: 500;
			padding: 3px 0;
			cursor: pointer;
			border-radius: 4px;
		}
	}
}
