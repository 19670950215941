.fleet-layout {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  :global {
    .fleet-title {
      color: #484848;

      font-size: 24px;
      font-weight: 500;
      line-height: normal;
      display: flex;
      align-items: center;

      &::before {
        display: inline-block;
        content: "";
        width: 8px;
        height: 18px;
        margin-right: 11px;
        border-radius: 37px;
        background: #355691;
      }
    }
  }
}