.movement-taskDetails {
  width: 1200px;
  padding: 16px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  height: 100%;

  :global {
    .taskDetails-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header-close {
        font-size: 16px;
        cursor: pointer;
      }
    }

    .taskDetails-container {
      width: 100%;
      display: flex;
      gap: 20px;
      height: calc(100% - 44px);
      overflow: hidden;
    }
  }
}