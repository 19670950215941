.fleet-routeCommon {
	width: 100%;
	overflow: hidden;
	position: relative;
	height: calc(100% - 64px);
	min-width: 1200px;
	display: flex;
	:global {
		.routeCommon-container {
			width: 100%;
			height: 100%;
			position: relative;
		}
	}
}

.fleet-routeCommon--collapsed {
	:global {
		.routeCommon-list {
			width: 0px;
			min-width: 0px;
		}
	}
}
