.preOperation-voyageTypeMenu {
	display: flex;
	flex-direction: column;
	padding: 4px;
	border-radius: 6px;
	background: #fff;
	box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
	width: 80px;
	:global {
		.voyageTypeMenu-item {
			padding: 8px;
			height: 32px;
			display: flex;
			align-items: center;
			cursor: pointer;
			gap: 8px;
			color: #484848;
			font-size: 14px;
			font-weight: 400;
			&:hover {
				border-radius: 6px;
				background: rgba(239, 241, 245, 0.6);
			}
		}
	}
}
