.movement-details {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	min-width: 1920px;
	position: relative;
	background: #e9eaed;

	:global {
		.movement-details--header {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.fleet-title {
				color: #151515;
				font-size: 16px;
				font-weight: 510;
				line-height: 24px;

				&::before {
					width: 3px;
					height: 14px;
					border-radius: 38px;
					background: #355691;
					margin-right: 8px;
					display: inline-block;
					content: "";
				}
			}

			.header-title {
				color: var(--color-text-body-1, #151515);
				font-size: 14px;
				font-weight: 400;
				line-height: 22px;
				display: flex;
				align-items: center;

				&::before {
					width: 3px;
					height: 14px;
					border-radius: 38px;
					background: rgba(53, 86, 145, 0.5);
					margin-right: 8px;
					display: inline-block;
					content: "";
				}
			}
		}

		.movementDetails-header {
			width: 100%;
			height: 46px;
			display: flex;
			padding: 0px 20px;
			justify-content: space-between;
			// border-bottom: 1px solid var(--Line_color, #D9D9D9);
			border-bottom: 1px solid #d9d9d9;
			box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
			background: #fff;

			.header-actions {
				height: 100%;
				display: flex;
				gap: 4px;
				align-items: center;

				& > * {
					border: 1px solid #d9d9d9;
					color: #707070;
					font-size: 12px;
				}

				& > :first-child {
					border: 0px;
					border-radius: 16px;
					padding: 8px 0px;
					padding-left: 7px;
					padding-right: 9px;
					margin-right: 32px;
				}

				.rback-icon {
					width: 16px;
					height: 16px;
					display: flex;
				}
			}

			.header-toolbar {
				height: 100%;
				display: flex;
				align-items: center;
				gap: 4px;

				& > * {
					font-size: 12px;
				}

				.toolbar-secondary {
					.font_family {
						color: #355691;
						font-size: 14px;
						& > :first-child {
							color: red;
						}
					}
				}

				.toolbar-line {
					width: 1px;
					height: 20px;
					background: #d9d9d9;
					margin: 0px 15px;
				}

				.status-select {
					width: 128px;
				}
			}
		}

		.movementDetails-container {
			width: 100%;
			height: calc(100% - 46px);
			background: inherit;
			// display: flex;
			overflow: hidden auto;
			// padding: 16px 14px;
			.movementDetails-scrollArea {
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: 12px;
				padding: 12px 12px 93px 12px;
				.movementDetails-voyageDetails {
					width: 100%;
					// height: 1091px;
					height: fit-content;
					display: flex;
					gap: 12px;
					.voyageDetails-container {
						// width: calc(100% - 628px);
						width: 1268px;
						height: 100%;
						height: auto;
						display: flex;
						flex-direction: column;
						gap: 12px;
						.voyageDetails-basicInfo {
							width: 100%;
							display: flex;
							gap: 12px;
						}
					}
				}
			}
		}
	}
}

.statusSelect-warp {
	display: flex;
	gap: 8px;
	align-items: center;
	height: 100%;

	:global {
		.statusSelect-icon {
			width: 16px;
			height: 16px;
		}

		.statusSelect-text {
			color: #484848;
			font-size: 14px;
			font-weight: 400;
			line-height: normal;

			&.Closed {
				color: #ec344f;
			}
		}
	}
}
