.routeCommon-vesselCard-segment {
	width: 100%;
	gap: 10px;
	display: grid;
	grid-template-columns: calc((100% - 62px) / 2) 42px calc((100% - 62px) / 2);
	grid-template-rows: repeat(auto-fit, 44px);
	border-bottom: 1px solid rgba(217, 217, 217, 0.6);
	padding-bottom: 6px;
	:global {
		.segment-container {
			display: flex;
			align-items: center;
			gap: 8px;
			& > :first-child {
				font-size: 16px;
				color: #355691;
				line-height: 22px;
			}

			& > :last-child {
				color: #355691;
				font-size: 14px;
				font-weight: 500;
				line-height: 22px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				width: calc(100% - 24px);
				cursor: pointer;
				/* 157.143% */
			}
		}
		.segment-pointer {
			height: 8px;
			color: #355691;
			align-self: center;
			display: flex;
		}
	}
}
