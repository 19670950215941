.fleet-login {
	display: flex;
	min-width: 1043px;
	user-select: none;

	:global {
		.login-container {
			width: 488px;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			background-image: url("static/image/fleet_loginLine_image.jpg");
			background-position: left;
			background-size: 100% 100%;
			background-repeat: no-repeat;

			.login-warp {
				max-width: 416px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				.login-logo {
					// width: 388px;
					height: 49px;
					margin-bottom: 106px;
				}

				.login-title {
					color: #515151;
					font-size: 28px;
					font-weight: 600;
					margin-bottom: 16px;
				}

				.login-tip {
					font-size: 14px;
					font-weight: 400;
					color: #828282;
					line-height: 22px;
					/* 129.412% */
					margin-bottom: 40px;
				}

				.login-tip-top {
					margin-bottom: 0px;
				}

				.login-form {
					width: 416px;
					padding-top: 14px;

					.login-item {
						display: flex;
						flex-direction: column;
						margin-bottom: 12px;

						&.last-item {
							margin-bottom: 0px;
						}

						& > .label {
							width: 100%;
							margin-bottom: 4px;
							color: #333;

							font-size: 14px;
							font-weight: 400;
							line-height: 24px;
						}

						& > .input {
							width: 100%;
							height: 40px;
							border-radius: 6px;
							// border: 1px solid #D9D9D9;
							// background: #FFF;
						}
					}

					.login-clause {
						width: 100%;
						margin: 24px 0px;
					}

					.login-btn {
						width: 100%;
						height: 56px;
					}
				}
			}
		}

		.logo-container {
			width: calc(100% - 488px);
			height: 100%;
			background-image: url("static/image/fleet_login_image.jpeg");
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
		}
	}
}

@media screen and (min-width: 1280px) {
	.fleet-login {
		:global {
			.login-container {
				width: 790px;
			}

			.logo-container {
				width: calc(100% - 790px);
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.fleet-login {
		min-width: unset;

		:global {
			.login-container {
				width: 100%;
			}

			.logo-container {
				display: none;
			}
		}
	}
}
