.statement-charterersLiability {
	display: flex;
	flex-direction: column;
	gap: 15px;
	position: relative;
	width: 100%;
	height: 100%;
	min-width: 1440px;
	padding: 14px 20px 20px 20px;
	:global {
		.charterersLiability-search {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 56px;
			.search-container {
				display: flex;
				align-items: flex-end;

				gap: 5px;
				.filter-select {
					width: 200px;
				}
			}
			.toolbar-container {
				display: flex;
				height: 100%;
				align-items: flex-end;
				gap: 8px;
			}
		}
		.charterersLiability-table {
			height: 100%;
			.movement-isBack {
				padding: 0px 6px;
				border-radius: var(--spacing-1, 2px);
				color: var(--color-bg-accent-fleetblue-bolder, #355691);
				font-size: 14px;
				font-weight: 400;
				line-height: 22px;
				&.yes {
					background: rgba(9, 209, 125, 0.12);
					color: #06b169;
				}
				&.no {
					background: var(--color-bg-accent-fleetblue-subtler, rgb(234, 224, 146));
					color: var(--color-bg-accent-fleetblue-bolder, #fff);
				}
			}
		}
	}
}
