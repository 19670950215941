.routeCommon-timelineLegendPanel {
	display: flex;
	flex-direction: column;
	gap: 10px;
	z-index: 98;
	left: 22px;
	position: absolute;
	width: calc(100% - 44px);
	bottom: 52px;
	:global {
	}
}
