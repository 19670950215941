// @import "@/asserts/styles/variable.less";

.esDetails-saOrigin {
  min-width: 706px;
  height: 792px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: #FFF;
  // background: @fleet-theme-sys-dark-on-background;
  padding: 20px 32px;

  :global {
    .saOrigin-header {
      height: 32px;
      margin-bottom: 12px;

      .header-container {
        display: flex;
        height: 100%;

        .header-title {
          margin-right: 12px;
        }
      }
    }

    .saOrigin-container {
      width: 100%;
      height: calc(100% - 92px);
      margin-bottom: 12px;
      display: flex;

      .saOrigin-add {
        height: 100%;
        width: 312px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        border: 1px dashed rgba(53, 86, 145, 0.40);
        background: rgba(53, 86, 145, 0.10);

        .add-container {
          border-radius: 16px;
          background: rgba(53, 86, 145, 0.20);
          width: 77.25px;
          height: 77.25px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          img {
            width: 40px;
            height: 40px;
          }
        }
      }
    }

    .saOrigin-footer {
      width: 100%;
      height: 36px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &>:first-child {
        margin-right: 8px;
      }
    }
  }
}

.saOrigin {
  width: 312px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 12px;

  :global {

    .saOrigin-label,
    .saOrigin-originLabel {
      width: 100%;
      // height: 23px;
      padding-bottom: 12px;
      border-bottom: 1px solid #D9D9D9;
      display: flex;
      align-items: center;
      height: 29px;
      justify-content: space-between;

      span {
        color: #484848;
        
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;

        &::before {
          width: 6px;
          height: 6px;
          border-radius: 11px;
          background: #355691;
          display: inline-block;
          content: "";
          margin-right: 6px;
        }
      }

      &>.saOrigin-label__close {
        width: 16px;
        height: 16px;
        cursor: pointer;
        display: flex;
      }

      &>.origin__label {
        &::before {
          background: #FF8632;
        }
      }

      &>.plan__label {
        display: flex;
        &>span {
          margin-right: 8px;
        }
      }
    }

    .saOrigin-form {
      width: 100%;
      height: 240px;
      margin-bottom: 8px;
      padding: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 6px;
      border: 1px solid #D9D9D9;

      .saOrigin-label {
        margin-bottom: 12px;
      }

      .saOrigin-item {
        width: 100%;
        height: 56px;
        margin-bottom: 4px;
        display: flex;

        .saOrigin-formItem-1 {
          width: 142px;
          height: 100%;
          margin-right: 4px;
        }

        .saOrigin-formItem-2 {
          width: 93.3px;
          height: 100%;
          margin-right: 4px;

          input {
            min-width: unset;
          }
        }

        &>:last-child.saOrigin-formItem-1 {
          margin-right: 0px;
        }

        &>:last-child.saOrigin-formItem-2 {
          margin-right: 0px;
        }
      }

      &>:last-child.saOrigin-item {
        margin-bottom: 0px;
      }
    }

    .saOrigin-pl {
      width: 100%;
      height: calc(100% - 248px);
      padding: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 6px;
      border: 1px solid #D9D9D9;

      .pl-item {
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 0px;
        border-bottom: 1px solid #D9D9D9;

        .pl-label {
          color: rgba(0, 0, 0, 0.50);
          
          font-size: 12px;
          font-weight: 400;
          line-height: 22px;
          /* 183.333% */
        }

        .pl-label__unit {
          color: rgba(0, 0, 0, 0.88);
          
          font-size: 12px;
          font-weight: 500;
          line-height: 22px;
          /* 183.333% */
        }

        .pl-value {
          color: rgba(0, 0, 0, 0.50);
          text-align: center;
          
          font-size: 12px;
          font-weight: 400;
          line-height: 22px;
        }

        .pl-value__unit {
          display: flex;

          &>span {
            color: #FF8632;
            
            font-size: 12px;
            font-weight: 600;
            line-height: 22px;
            /* 183.333% */
          }

          &>span:first-child {
            color: #FF8632;
          }

          &>span:last-child {
            color: #355691;
          }
        }
      }

      .pl-item__expense {
        padding-left: 12px;
      }

      &>:last-child.pl-item {
        border-bottom: 0px;
      }
    }
  }
}