.common-newly--company {
  width: 707px;

  :global {
    .newly-container {
      display: grid !important;
      width: 100%;
      grid-template-columns: repeat(auto-fit, calc((100% - 12px) / 2));
      grid-template-rows: repeat(auto-fit, 56px);
      gap: 12px  !important;
    }
  }
}