.fleet-table {
	// height: calc(100% - 45px);
	// background-color: rgb(33, 33, 33);
	// background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
	border-radius: 10px 10px 0px 0px;
	// box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
	// box-shadow: 0 0.00933333rem 0.01333333rem rgb(0 0 0 / 16%), 0 0.02266667rem 0.03466667rem rgb(0 0 0 / 22%), 0 0.04666667rem 0.07066667rem rgb(0 0 0 / 28%), 0 0.09733333rem 0.14666667rem rgb(0 0 0 / 35%), 0 0.26666667rem 0.4rem rgb(0 0 0 / 50%);

	:global {
		.btn-text-danger {
			background-color: #fa2256 !important;
		}

		.btn-text-primary {
			background-color: #15a5eb !important;
		}

		.btn-text-orange {
			background-color: #eeb606 !important;
		}

		.btn-text-process {
			background-color: #24c50c !important;
		}

		.btn-text-edit {
			background-color: #ff6800 !important;
		}

		.btn-text-offline {
			background-color: #536dfe !important;
		}

		// .ant-table-selection {
		//   .ant-checkbox-inner {
		//     border: 1px solid #757B8C;
		//     background: #282C38;
		//   }
		// }
		.ant-spin-nested-loading {
			.ant-spin {
				max-height: unset;
				// background: rgba(23, 24, 31, 0.8);

				.ant-spin-dot {
					z-index: 9999;
					opacity: 1;

					i {
						// background: white;
					}
				}
			}
		}

		.ant-spin-nested-loading {
			height: 100%;

			.ant-spin-container {
				height: 100%;

				.ant-table {
					height: calc(100% - 46px);
					border: 1px solid #d9d9d9;
					scrollbar-color: unset;

					.ant-checkbox {
						// .ant-checkbox-inner{
						//   border: 1px solid #757B8C;
						//   background: #24c50c;
						// }
					}

					.ant-table-container {
						height: 100%;

						.ant-table-header {
							background: #f4f4f4;
							color: #757b8c;

							.ant-table-thead {
								tr {
									:last-child {
										box-shadow: none;
									}

									th {
										background: #f4f4f4;

										border-bottom: 1px solid rgba(0, 0, 0, 0.06);
										color: #454545;

										font-size: 14px;
										font-weight: 500;
										line-height: 22px;
										/* 157.143% */
									}

									& > th:first-child {
										border-radius: 10px 0px 0px 0px;
									}

									th.ant-table-selection-column {
										padding-inline-start: 16px;
										padding-inline-end: 16px;
									}
								}
							}
						}

						.ant-table-body {
							height: calc(100% - 55px);
							max-height: unset !important;
							background: #fff;
							// background: rgba(0,0,0,.1);
							color: rgb(255, 255, 255);
							// scrollbar-width: thin;

							// .ant-table-row:nth-of-type(2n+1) {
							//   background: #282C38;

							//   td {
							//     background: #282C38;
							//   }
							// }

							// tr.ant-table-placeholder {
							//   .ant-table-expanded-row-fixed {
							//     margin: 0px;
							//   }
							// }

							tr {
								background: #fff;
								// background: rgba(0,0,0,.1);

								&:hover {
									td {
										// background: rgb(42 42 42) !important;
										background: #f5f7f9 !important;
										// background: rgb(42 42 42) !important;
									}
								}

								td {
									border-bottom: 1px solid rgba(0, 0, 0, 0.06);
									color: rgba(0, 0, 0, 0.88);
									// color: rgba(53, 86, 145, 0.04);
									// rgba(53, 86, 145, 0.04)

									font-size: 12px;
									font-weight: 400;
									line-height: 22px;
									padding-top: 8px;
									padding-bottom: 8px;
									background: #fff;

									.hidden {
										width: 100%;
										display: flow-root;
										height: 100%;

										overflow: hidden;
										text-overflow: ellipsis;
										white-space: nowrap;
									}
								}
							}

							tr.ant-table-expanded-row {
								& > td {
									padding: 0px;

									.ant-table-expanded-row-fixed {
										padding: 0px;
										margin: 0px;

										.ant-table-fixed-header {
											margin-block: 0px;
											margin-inline: 0px;
											margin-inline-start: 48px;
											border: 0px;

											.ant-table-body {
												height: 100%;

												& > table {
													border-radius: 0px;
												}

												&::-webkit-scrollbar {
													width: 2px;
													height: 5px;
												}

												&::-webkit-scrollbar-corner {
													background-color: none;
												}

												&::-webkit-scrollbar-thumb {
													border-radius: 0px;
													background-color: rgba(53, 86, 145, 0.18);
												}

												&::-webkit-scrollbar-track {
													border-radius: 0px;
												}
											}
										}
									}
								}
							}

							&::-webkit-scrollbar-thumb {
								border-radius: 6px;
								background-color: rgba(53, 86, 145, 0.18);
								border: 1px solid rgb(219, 219, 219);
							}

							&::-webkit-scrollbar {
								width: 2px;
								height: 7px;
							}

							&::-webkit-scrollbar-corner {
								background-color: transparent;
							}

							&::-webkit-scrollbar-track {
								border-radius: 8px;
							}
						}

						.ant-table-tbody-virtual-holder {
							// height: calc(100% - 55px);
							// max-height: unset !important;
							.ant-table-row {
								.ant-table-selection-column {
									// padding-inline: unset;
									padding-inline-start: 0px;
									padding-inline-end: 0px;
								}
								.ant-table-cell {
									font-size: 12px;
									font-weight: 400;
									line-height: 22px;
									padding-top: 8px;
									padding-bottom: 8px;
									background: #fff;
									text-overflow: ellipsis;
									white-space: nowrap;
								}
							}
						}
						.ant-table-sticky-scroll {
							display: none;
						}
					}
				}

				.ant-pagination {
					margin: 0px;
					padding-top: 7px;
					padding-bottom: 7px;
					padding-right: 12px;
					justify-content: center;
					border-radius: 0px 0px 6px 6px;
					border-right: 1px solid #d2dae4;
					border-bottom: 1px solid #d2dae4;
					border-left: 1px solid #d2dae4;
					// .ant-select-selector{
					//   border: 0px;
					// }

					& > li {
						// border: 0px;
						// background: rgb(40, 44, 56);
						// opacity: 50%;
						border-radius: 6px;
						border: 1px solid #d9d9d9;
						background: #fff;

						& > button,
						a,
						span {
							color: rgba(0, 0, 0, 0.88);
						}
					}

					.ant-pagination-options {
						// background: rgba(0, 0, 0, 0.5);
						opacity: 1;
						color: rgba(0, 0, 0, 0.5);
						border-radius: 6px;

						.ant-select-selector {
							// background: rgb(40, 44, 56);
							border: 0px;
						}
					}

					& > li.ant-pagination-prev {
						& > button,
						span {
							// color: #D9D9D9 !important;
						}
					}

					& > li.ant-pagination-prev,
					& > li.ant-pagination-next {
						border: 0px;

						& > button {
							// border: 0px;
							// background: #282c38;
							// border-radius: 6px;
							border-radius: 6px;
							border: 1px solid #d9d9d9;
							background: #fff;
						}
					}

					& > li.ant-pagination-disabled {
						& > button,
						span {
							color: rgba(0, 0, 0, 0.25);
						}
					}

					& > li.ant-pagination-item-active {
						border: 1px solid #355691;
						// opacity: 1;

						button,
						a {
							color: #355691;
						}
					}
				}
			}
		}
	}
}

.fleet-table-empty {
	:global {
		.ant-table-container {
			.ant-table-body {
				table {
					height: 100%;

					.ant-table-tbody {
						height: 100%;
					}
				}
			}
		}
	}
}

.td-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.aw-table-cell {
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.fleet-table-sumary {
	:global {
		.ant-spin-nested-loading {
			height: 100%;

			.ant-spin-container {
				height: 100%;

				.ant-table {
					.ant-table-container {
						height: 100%;
						.ant-table-summary {
							tr {
								background: #fff;
								// background: rgba(0,0,0,.1);

								&:hover {
									td {
										// background: rgb(42 42 42) !important;
										// background: #f5f7f9 !important;
										// background: rgb(42 42 42) !important;
									}
								}

								td {
									border-bottom: 1px solid rgba(0, 0, 0, 0.06);
									color: rgba(0, 0, 0, 0.88);
									// color: rgba(53, 86, 145, 0.04);
									// rgba(53, 86, 145, 0.04)

									font-size: 12px;
									font-weight: 400;
									line-height: 22px;
									padding-top: 8px;
									padding-bottom: 8px;
									background: #fff;

									.hidden {
										width: 100%;
										display: flow-root;
										height: 100%;

										overflow: hidden;
										text-overflow: ellipsis;
										white-space: nowrap;
									}
								}
							}
						}

						.ant-table-body {
							height: calc(100% - 94px);
						}
					}
				}
			}
		}
	}
}
