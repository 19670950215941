.cargoBook-portRotation {
  :global{
    .form-container {
      margin-bottom: 8px;
      .portRotation-formItem {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        &>* {
          input {
            min-width: unset;
          }
        }
        .formItem-input-1 {
          width: 103px;
        }
        .formItem-input-2 {
          width: 136px;
        }
        .formItem-input-3 {
          width: 69px;
        }
        .formItem-icon {
          width: 32px;
          height: 32px;
          display: flex;
          cursor: pointer;
        }
      }
    }
    :last-child {
      margin-bottom: 0px;
    }
  }
}