.route-areaList {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 8px;
	display: none;
	:global {
		.areaList-wrapper {
			width: 100%;
			height: calc(100% - 44px);
			overflow: hidden;
			display: grid;
			grid-template-columns: repeat(auto-fit, 100%);
			grid-template-rows: calc(63% - 2.5px) calc(37% - 2.5px);
			gap: 5px;
		}
	}
}

.route-areaList--active {
	display: flex;
}
