.routeCommon-vesselCard {
	position: absolute;
	top: 70px;
	left: 334px;
	z-index: 1;
	display: flex;
	flex-direction: column;
	gap: 8px;
	overflow: hidden auto;
	max-height: calc(100% - 140px);
}
