.perfreport-voyageList {
  width: 316px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #EFF1F5;
  padding: 25px 14px 0px 14px;
  :global {
    .voyageList-warper {
      width: 100%;
      height: calc(100% - 85px);
      overflow: hidden auto;
      .voyageList-scrollArea {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
    }
  }
}