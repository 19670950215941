.perfreport-voyageFilter {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 17px;

  :global {
    .voyageFilter-title {
      color: var(--Special-Color, #254377);
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
    }

    .voyageFilter-container {
      width: 100%;
      display: flex;
      gap: 7px;
      height: 32px;

      .voyageFilter-search {
        width: calc(100% - 39px);
        height: 100%;
      }

      .voyageFilter-select {
        width: 32px;
        height: 100%;
      }
    }
  }
}