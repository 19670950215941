.fleet-formitem {
  display: flex;
  flex-direction: column;
  gap: 2px;
  :global {
    .label {
      // margin-bottom: 2px;
      color: #484848;
      
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &::before {
        content: "\*";
        color: #F00;
        text-align: center;
        
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        margin-right: 2px;
      }

      &.disabled {
        &::before {
          display: none;
        }
      }
    }
    .ant-cascader {
      width: 100%;
    }
  }

}

.fleet-formitem__row {
  flex-direction: row;
  align-items: center;
  gap: 6px;
  >:last-child {
    flex-grow: 1
  }
  :global{
    .label{
      flex-shrink: 0;
      // margin-right: 6px;
      // margin-bottom: 0px;
      
    }
  }
}