.movement-handover {
  width: 1100x;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  height: 750px;
  position: relative;
  :global{

  }
}