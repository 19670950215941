.routeCommon-otherSelect--Popup {
	// width: 300px;

	:global {
		.otherSelect--popup--container {
			width: 100%;
			max-height: 250px;
			overflow: auto;
			&::-webkit-scrollbar {
				width: 8px;
				position: absolute;
			}
			&::-webkit-scrollbar-thumb {
				width: 8px;
				border-radius: 4px;
				background-color: rgba(0, 0, 0, 0.5);
			}
			.select--popup--scrollArea {
				width: 100%;
				.select--popup--item {
					line-height: 24px;
					padding: 5px 12px;
					cursor: pointer;
					li {
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}
				.select--popup--item:hover {
					background-color: rgb(245, 245, 245);
				}
				.select--popup--item:active {
					background-color: rgb(198, 203, 209);
				}
			}
		}
		.select--popup--loadMore {
			display: flex;
			justify-content: center;
			line-height: 35px;
			border-top: 1px solid #eee;
			color: rgb(61, 85, 145);
			cursor: pointer;
		}
	}
}
