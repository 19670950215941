.fleet-statement {
	width: 100%;
	height: calc(100% - 64px);
	overflow: hidden;

	:global {
		.statement-header {
			width: 100%;
			height: 49px;
			background: #fff;
			display: flex;
			flex-direction: column;
			:global {
				.statement-header-tabs {
					height: 48px;
				}
				.statement-header-line {
					width: 100%;
					height: 1px;
					background: #d9d9d9;
				}
			}
		}
		.statement-common {
			width: 100%;
			height: calc(100% - 49px);
			.statement-common-title {
				height: 36px;
				width: 100%;
				padding: 0px 0px 16px;
				border-bottom: 1px solid #d9d9d9;
				color: #484848;

				font-size: 16px;
				font-weight: 500;
				margin-bottom: 16px;
			}
		}
	}
}
