.movement-performance {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  :global{
    .movement-details--header {
      .fleet-title {
        line-height: 22px;

        &::before {
          background: rgba(53, 86, 145, 0.50);
        }
      }
    }
    .performance-table {
      height: fit-content;
    }
  }
}