.estDetails-cargoAndPort {
  width: 100%;
  padding: 12px;
  display: flex;
  border-radius: 6px;
  border: 1px solid rgba(217, 217, 217, 0.87);
  background: #FFF;
  flex-direction: column;
  gap: 12px;

  :global {
    .cargoAndPort-header {
      width: 100%;
      display: flex;
      align-items: center;
      padding-bottom: 12px;
      border-bottom: 1px solid #D9D9D9;
    }

    .cargoAndPort-container {
      width: 100%;
      display: flex;
      gap: 10px;
      flex-direction: column;

      .cargoAndPort-item {
        display: grid;
        width: 100%;
        grid-template-rows: 56px;
        gap: 4px;
        align-items: flex-end;

        &.thirty-seven {
          grid-template-columns: 222px 1fr;
          gap: 4px;
        }

        &.thirty-unequal {
          grid-template-columns: 192px 75px 72px;
          gap: 4px;
        }

        &.fourty-unequal {
          grid-template-columns: 135px 58px 72px 72px;
          gap: 4px;
        }

        &.eighty-thirty-unequal {
          grid-template-columns: 235px 32px 72px;
          gap: 4px;
        }

        &.two-equal {
          grid-template-columns: repeat(auto-fit, calc((100% - 4px) /2));
          gap: 4px;
        }

        &.fourty-thirty-unequal {
          grid-template-columns: 133.5px 133.5px 72px;
          gap: 4px;
        }

        &.ninety {
          grid-template-columns: 313px 32px;
          gap: 4px;
        }

        &.fourty {
          grid-template-columns: repeat(auto-fit, calc((100% - 24px)/4));
          gap: 4px;
        }

        &.thirty-thirty-unequal {
          grid-template-columns: 1fr 1fr 66px;
          gap: 4px;
        }

        input {
          min-width: unset;
        }
      }
    }
  }
}

@media screen and (min-width:1920px) {
  .estimationDetails-rotation {
    height: calc(100% - 475px);
  }
}