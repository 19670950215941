.movement-bunkerPlan {
  width: 1391px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;

  :global {
    .bunkerPlan {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .bunkerPlan-commom {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .bunkerPlan-header--title {
          color: #484848;
          font-size: 16px;
          font-weight: 500;
          line-height: 19px;
          display: flex;
          align-items: center;

          &::before {
            content: '';
            display: inline-block;
            margin-right: 8px;
            border-radius: 32px;
            background: #355691;
            width: 6px;
            height: 6px;
          }
        }
      }

      .bunkerPlan-header {
        width: 100%;
        display: grid;
        grid-template-columns: 695px calc(100% - 711px);
        gap: 16px;

        .bunkerPlan-details {
          display: grid;
          grid-template-columns: repeat(auto-fit, calc((100% - 16px)/3));
          gap: 8px;
          grid-template-rows: repeat(auto-fit, 56px);
        }
      }

      .bunkerPlan-container {}
    }
  }
}