.voyagemanage-fixtureList {
  :global {
    // .vm-common-title {
    //   height: 56px;
    //   width: 100%;
    //   padding: 20px 0px 16px;
    //   border-bottom: 1px solid #D9D9D9;
    //   color: #484848;
    //   
    //   font-size: 16px;
    //   font-weight: 500;
    // }


    .headFixture-form {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin-bottom: 24px;
      .form-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, 210px);
        grid-template-rows: repeat(auto-fit, 56px);
        grid-gap: 12px 8px;
      }
    }
  }
}
.filter-content{
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}