.fleet-estimationDetails {
  display: flex;
  position: relative;
  :global {
    .details-title {
      color: #355691;
      
      font-size: 16px;
      font-weight: 500;
      display: flex;
      align-items: center;

      &::before {
        width: 5px;
        height: 13px;
        border-radius: 11px;
        background: #355691;
        content: "";
        margin-right: 8px;
      }
    }

    .prompt-header {
      width: 100%;
      height: 23px;
      font-size: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .header-title {
        color: #254377;
        
        font-size: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;

        &::before {
          width: 6px;
          height: 16px;
          border-radius: 11px;
          background: #355691;
          content: "";
          margin-right: 8px;
        }
      }

      .header-close {
        width: 16px;
        height: 16px;
        display: flex;
      }
    }

    .estimationDetails {
      padding: 10px;
      height: 100%;
      width: 395px;
      transition: width 200ms ease-out;
      overflow: hidden auto;
      min-width: 395px;
      background: #F4F4F4;

      .estimationDetails-container {
        width: 100%;
        display: flex;
        flex-direction: column;

        .estimationDetails-form {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-bottom: 12px;
          gap: 12px;
        }

        .estimationDetails-profit {
          // width: 100%;
        }
      }
    }

    .estimationDetails-graph {
      width: calc(100% - 395px);
      padding: 11px 20px 12px 23px;
      display: flex;
      flex-direction: column;
      min-width: 1049px;
      overflow: hidden auto;
    }
  }
}

@media screen and (min-width:1920px) {
  .fleet-estimationDetails {
    :global {
      .estimationDetails {
        overflow: hidden;
        width: 772px;

        .estimationDetails-container {
          flex-direction: row;

          height: 100%;

          .estimationDetails-form {
            width: 371px;
            height: 100%;
            margin-bottom: 0px;
            margin-right: 12px;
            overflow: hidden auto;
          }

          .estimationDetails-profit {
            width: calc(100% - 382px);
            height: 100%;
            overflow: hidden auto;

            .remakes {
              height: calc(100% - 669px);
              min-height: 305px;
            }
          }
        }
      }

      .estimationDetails-graph {
        width: calc(100% - 772px);
      }
    }
  }
}