.movement-sea-reports {
  width: 1400px;
  height: 866px;
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: #fff;

  :global {
    .sea-reports-header {
      width: 100%;
      position: relative;
      .sea-reports-uploadarea {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 1000px;
        height: 70px;
        .sea-reports-upload-drop {
          display: block;
          height: 100%;
          border: 0;
        }
        .sea-reports-upload-drop-hide {
          display: none;
          height: 100%;
        }
        .sea-reports-upload-icon {
          font-size: 30px;
          color: #9f9f9f;
        }
        .ant-upload-drag {
          background-color: #efefef;
          border: 0;
          .ant-upload-btn {
            padding: 0;
          }
          &:hover {
            border: 0 !important;
          }
        }
        .ant-upload-drag-hover {
          border: 0 !important;
        }
      }
    }

    .sea-reports-main {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;
      margin-bottom: 10px;

      .sea-reports-container {
        width: calc(100% - 266px);
        transition: width 0.3s;
        .sea-reports-button {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          height: 42px;
          .sea-reports-button-group {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-start;
            gap: 10px;
            span {
              margin-right: 4px;
            }
            .sea-reports-page {
              height: 32px;
              color: #7d7d7d;
              font-family: "SF Pro";
              font-size: 14px;
              font-weight: 400;
              line-height: 32px;
            }
            .ant-upload-wrapper {
              width: 160px;
            }
          }
        }
      }
      .sea-reports-textarea {
        width: 266px;
        background: #f8f8f9;
        height: fit-content;
        .textarea-header {
          padding: 0 8px;
          height: 40px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          color: rgba(0, 0, 0, 0.85);
          font-family: "SF Pro";
          font-size: 14px;
          font-weight: 400;
          line-height: 40px; /* 157.143% */
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        .sea-reports-addarea {
          padding: 10px;
          .ant-input {
            &::-webkit-scrollbar {
              width: 8px;
            }
            &::-webkit-scrollbar-thumb {
              width: 8px;
              border-radius: 4px;
              background: #d2d2d2;
            }
          }
          button {
            margin-top: 8px;
            width: 100%;
            background-color: #355691;
          }
        }
        .sea-reports-messageText {
          padding: 6px 10px;
          font-size: 12px;
          font-weight: 400;
          height: 680px;
          line-height: 24px;
          overflow: auto;
          white-space: pre-line;
          &::-webkit-scrollbar {
            width: 8px;
          }
          &::-webkit-scrollbar-thumb {
            width: 8px;
            border-radius: 4px;
            background: #d2d2d2;
          }
        }
      }
      .sea-reports-container-full {
        width: 100%;
      }
    }

    .sea-reports-footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 56px;
      margin-top: 10px;
      gap: 10px;
    }
  }
}

.movement-seaReport-category {
  :global {
    .sea-table-category {
      width: 1150px !important;
      background-color: #ffffff;
      background-clip: padding-box;
      border-radius: 8px;
    }
  }
}
