.performance-plotting {
  width: 100%;
  height: 245px;
  display: flex;
  flex-direction: column;

  :global {
    .plotting-header {
      width: 100%;
      display: flex;
      margin-bottom: 12px;
      height: 32px;
      align-items: center;
      justify-content: space-between;

      .header-title {
        color: #355691;
        
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        // width: calc(100% - 641px);

        &::before {
          border-radius: 13px;
          background: #355691;
          width: 6px;
          height: 6px;
          content: "";
          display: inline-block;
          margin-right: 4px;
        }
      }

      .header-form {
        // width: 641px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        gap: 16px;
        .form-descriptor {
          display: flex;
          // margin-right: 16px;
          align-items: center;
          gap: 4px;

          .descriptor-prefix {
            height: 2px;
            width: 10px;
            // margin-right: 4px;
          }

          .descriptor-prefix-1 {
            background: #FF8632;
          }

          .descriptor-prefix-2 {
            background: #355691;
          }

          .descriptor-prefix-icon {
            width: 10px;
            height: 10px;
            display: flex;
          }

          .descriptor-text {
            color: #484848;
            
            font-size: 12px;
            font-weight: 400;
            line-height: 22px;
            /* 183.333% */
          }
        }

        .form-search {
          height: 100%;
          // margin-right: 18px;
          align-items: center;

          .form-input {
            width: 60px;
            border-radius: 40px;
            border: 1px solid #D9D9D9;

            input {
              min-width: unset;
            }
          }
        }

        &>:last-child {
          margin-right: 0px;
        }
      }
    }

    .plotting {
      width: 100%;
      height: calc(100% - 44px);
      position: relative;
      width: 100%;

      #myCanvasDot {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
      }

      #markCanvas {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        transform: rotate(180deg);
      }
    }
  }
}