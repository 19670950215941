.estCompare-item {
  height: 100%;
  width: 284px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 12px 16px;
  border-radius: 6px;
  border: 1px solid #D9D9D9;
  background: #F5F5F5;
  gap: 12px;

  :global {
    .compareItem-header {
      width: 100%;
      padding-bottom: 12px;
      border-bottom: 1px solid #E7E7E7;
      display: flex;
      align-items: center;

      .compareItem-header--select {
        height: fit-content;
      }
    }

    .compareItem-container {
      width: 100%;
      height: calc(100% - 47px);
      overflow: hidden auto;

      .compareItem-scrollArea {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .compareItem-warp {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 4px;

          .compareItem-formItem {
            width: 100%;
            height: 56px;
            display: grid;
            align-items: flex-end;
            grid-template-rows: repeat(auto-fit, 56px);
            gap: 4px;

            &.full {
              grid-template-columns: repeat(auto-fit, 100%);
            }

            &.two-equal {
              grid-template-columns: repeat(auto-fit, calc((100% - 4px)/2));
            }

            &.thirty-equal {
              grid-template-columns: repeat(auto-fit, calc((100% - 8px)/3));
            }

            &.thirty-noequal {
              grid-template-columns: 120px 72px 50px;
            }

            &.eighty-thirty-unequal {
              grid-template-columns: 156px 32px 50px;
            }

            &.eighty-two {
              grid-template-columns: 1fr 58px;
            }

            &.fourty-thirty-unequal {
              grid-template-columns: 95px 95px 50px;
              gap: 4px;
            }

            &.nighty {
              grid-template-columns: calc(100% - 36px) 32px;
              gap: 4px;
            }

            &.fourty-fourty-unequal {
              grid-template-columns: calc((100% - 74px)/2) calc((100% - 74px)/2) 66px;
              gap: 4px;
            }

            input {
              min-width: unset;
            }
          }
        }

        .compareItem-formItem--title {
          width: 100%;
          color: #484848;
          font-size: 16px;
          font-weight: 500;
          padding-bottom: 12px;
          border-bottom: 1px solid #E7E7E7;

          &.profit {
            color: #355691;
          }
        }

        .compareItem-profit {
          width: 100%;
          display: flex;
          flex-direction: column;

          &>* {
            width: 100%;
            padding: 8px 0px;
            display: flex;
            justify-content: space-between;

            &>:first-child {
              color: rgba(0, 0, 0, 0.50);
              font-size: 12px;
              font-weight: 500;
              line-height: 22px;

              &.highest {
                color: rgba(0, 0, 0, 0.88);
              }
            }

            &>:last-child {
              font-size: 12px;
              font-weight: 600;
              line-height: 22px;
              color: rgba(0, 0, 0, 0.50);
              display: flex;
              align-items: center;

              &::after {
                margin-left: 4px;
                content: "USD";
                color: rgba(0, 0, 0, 0.50);
                font-size: 12px;
                font-weight: 600;
                line-height: 22px;
                display: inline-block;
              }

              &.highest {
                color: #FF8632;

                &::after {
                  color: #355691;
                }
              }
            }
          }

          &>:not(:last-child) {
            border-bottom: 1px solid #D9D9D9;
          }
        }
      }
    }
  }
}