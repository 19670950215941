.route-areaItem {
	width: 100%;
	padding: 6px 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #fff;
	& + {
		border-top: 1px solid rgba(0, 0, 0, 0.06);
	}
	:global {
		.areaItem-preset-select {
			height: fit-content;
			width: fit-content;
			.radio-icon {
				width: 14px;
				height: 14px;
			}
			.radio-label {
				width: calc(100% - 24px);
			}
		}
	}
}

.route-areaItem--selected {
	padding: 6px 20px 6px 6px;
}
