.fleet-personal-center {
  width: 100%;
  height: 100%;
  padding: 0 30px;
  :global {
    .personal-center-content{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
    .personal-center-back {
      width: fit-content;
      height: 48px;
      padding-left: 21px;
      display: flex;
      align-items: center;
      gap: 1px;
      cursor: pointer;

      .back-icon {
        font-size: 20px;
      }

      .back-title {
        color: #221e1e;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
