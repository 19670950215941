.movement-cargoInfo {
  width: 281.5px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.10);
  padding: 16px;

  :global {
    .cargoInfo-item {
      width: 100%;
      height: 32px;
      display: grid;
      grid-template-columns: calc(100% - 166px) 160px;
      grid-template-rows: repeat(auto-fit, 32px);
    }
  }
}