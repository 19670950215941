.layTime-leftArea {
  width: 153px;
  height: 100%;
  overflow: hidden auto;
  border-right: 1px solid #D9D9D9;
  background: #EFF1F5;
  padding: 8px;

  :global {
    .leftArea {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .leftArea-item {
        width: 100%;
        height: 66px;
        border-radius: 6px;
        border: 1px solid #D9D9D9;
        background: #FFF;
        display: flex;
        padding: 16px 12px;
        align-items: center;
        gap: 16px;

        .leftArea-portArea {
          height: 100%;
          display: flex;
          flex-direction: column;
          gap: 2px;
          width: calc(100% - 32px);

          &>:first-child {
            color: #484848;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

          }

          &>:last-child {
            color: rgba(125, 125, 125, 0.86);
            font-size: 12px;
            font-weight: 500;
            line-height: 14px;
          }
        }

        .leftArea-select {
          width: 16px;
          height: 16px;
        }

        &.active {
          border-radius: 6px;
          border: 1px solid #355691;
          background: rgba(53, 86, 145, 0.05);
          box-shadow: 0px 0px 0px 2px rgba(53, 86, 145, 0.10);
          .leftArea-portArea {
            &>* {
              color: #355691;
            }
          }
        }
      }
    }
  }
}