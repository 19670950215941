.fleet-database {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: calc(100% - 64px);

  :global {
    .database-common {
      width: 100%;
      height: calc(100% - 49px);
      display: flex;
      flex-direction: column;
      padding: 25px 20px 20px;
      gap: 12px;

      .database-toolbar {
        width: 100%;
        height: 58px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .btn-prefix {
          width: 14px;
          height: 14px;
        }
      }

      .database-table {
        width: 100%;
        height: calc(100% - 70px);

        .td-operate {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;

          .operate-btn {
            font-size: 12px;
          }

          .operate-icon {
            width: 16px;
            height: 16px;
            display: flex;
            cursor: pointer;
          }
        }
      }

      .database-newly {
        padding: 16px;
        background-color: #fff;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        position: relative;

        .newly-header {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 6px 6px 0px 0px;

          .header-title {
            color: #484848;
            font-size: 20px;
            font-weight: 500;
            line-height: normal;
            display: flex;
            align-items: center;

            &::before {
              content: '';
              width: 6px;
              height: 14px;
              border-radius: 38px;
              background: #355691;
              display: inline-block;
              margin-right: 8px;
            }

            /* 140% */
          }

          .font_family {
            font-size: 14px;
            line-height: 1;
          }
        }

        .newly-footer {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }
}