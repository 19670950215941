.voyageManage-voyageRemark--movement {
	:global {
		.voyageRemark-scrollArea {
			.voyageManage-voyageRemark-container {
				display: grid;
				grid-template-columns: repeat(auto-fit, calc((100% - 13px) / 2));
				gap: 13px;
			}
		}
	}
}
