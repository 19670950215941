.routeCommon-dailyReport {
	:global {
		.dailyReport-list {
			.ant-spin-nested-loading {
				.ant-spin-container {
					.ant-table {
						height: 100%;
						.ant-table-container {
							.ant-table-header {
								.ant-table-thead {
									tr {
										th {
											padding: 4px 8px;
											color: #333;
											font-size: 12px;
											font-weight: 450;
											line-height: 22px;
											background: #e6eaef;
										}
									}
								}
							}
							.ant-table-body {
								height: calc(100% - 31px) !important;
								tr {
									td {
										padding: 4px 8px;
										color: #484848;
										font-size: 12px;
										font-weight: 400;
										line-height: 22px;
									}
								}
							}
							.portVessel-list-operation {
								display: flex;
								gap: 12px;
								& > * {
									color: #355691;
									font-size: 12px;
									font-weight: 400;
									line-height: 22px; /* 183.333% */
									cursor: pointer;
								}
							}
						}
					}
				}
			}
		}
	}
}
