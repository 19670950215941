.fleet-cascader {
  :global{

  }
}

.fleet-cascader-popup {
  :global{
    .ant-cascader-menus {
      >ul {
        height: 182px;
        padding: 4px;
        &::-webkit-scrollbar {
          width: 0px;
          height: 0px;
          display: none;
        }
    
        &::-webkit-scrollbar-corner {
          background-color: rgba(41, 42, 44, 1);
        }
    
        &::-webkit-scrollbar-thumb {
          border-radius: 6px;
          background-color: rgba(41, 42, 44, 1);
          border: 1px solid rgb(219, 219, 219);
        }
    
        &::-webkit-scrollbar-track {
          border-radius: 4px;
        }
    
        scrollbar-width: none;
        /* Firefox */
        -ms-overflow-style: none;
        /* IE 10+ */
      }
    }
  }
}