.routeCommon-pointPopup {
	// width: 225px;
	display: flex;
	flex-direction: column;
	position: relative;
	:global {
		.pointPopup-header {
			width: 255px;
			height: 19px;
			background: rgba(0, 0, 0, 0.45);
			display: flex;
			.pointPopup-latLng {
				width: 100%;
				color: #fff;
				font-size: 12px;
				font-weight: 400;
				line-height: 17px;
				padding: 1px 6px;
				border-right: 1px solid rgba(0, 0, 0, 0.2);
			}
			// .pointPopup-close {
			// 	padding: 3.5px;
			// 	display: flex;
			// }
		}
		.pointPopup-container {
			width: 255px;
			display: flex;
			flex-direction: column;
			.pointPopup-item {
				border-bottom: 1px solid rgba(0, 0, 0, 0.3);
				width: 100%;
				display: grid;
				grid-template-columns: 65px 190px;
				grid-template-rows: repeat(auto-fit, 19px);
				font-size: 12px;
				font-weight: 400;
				line-height: 17px;
				background: #373737;
				& > .label {
					padding: 1px 6px;
					color: #fff;
				}
				& > .value {
					padding: 1px 10px;
					background: #ffe600;
					display: flex;
					justify-content: space-between;
					& > .pointPopup-wind-directive {
						display: flex;
						gap: 4px;
						align-items: center;
					}
				}
			}
		}
		.pointPopup-pointer {
			position: absolute;
			bottom: -25px;
			left: -4.5px;
			width: 9px;
			height: 28px;
		}
	}
}
