.cargoBook-cargoInfo {
  :global{
    .cargoInfo-formItem {
      display: flex;
      .formItem-input {
        width: 136px;
        margin-right: 4px;
      }
    }
  }
}