.movement-financialData {
  width: calc(100% - 1280px);
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.10);
  padding: 12px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  height: auto;

  :global {
    .movement-details--header {
      justify-content: space-between;
    }
    .financialData-container {
      width: 100%;
      height: calc(100% - 38px);
      overflow: hidden auto;

      .financialData-scrollArea {
        width: 100%;
        display: flex;
        flex-direction: column;

        .financialData-item--common {
          width: 100%;
          display: grid;
          padding: 4px 0px;
          // grid-template-columns: 154px 99px 127px 48px 106px 48px;
          grid-template-columns: 27% 17% 22% 8% 18% 8%;
          grid-template-rows: repeat(auto-fit, 23px);
          border-bottom: 1px solid var(--Conditional-divider, rgba(0, 0, 0, 0.06));

          &>* {
            color: #484848;
            font-size: 12px;
            font-weight: 400;
            line-height: 22px;
            border-right: 1px solid rgba(0, 0, 0, 0.06);
            padding: 0px 8px;
            text-align: right;
          }

          &>:first-child {
            color: rgba(0, 0, 0, 0.88);
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
          }

          &>:last-child {
            border-right: 0px;
          }

          .financialData-item--desc {
            display: flex;
            gap: 6px;
            align-items: center;

            &>:first-child {
              font-size: 12px;
            }

            &>:last-child {
              color: rgba(0, 0, 0, 0.88);
              font-weight: 500;
            }
          }

          &.the-second {
            &>:first-child {
              padding-left: 26px;
            }
          }

          &.the-third {
            &>:first-child {
              padding-left: 40px;
            }
          }

          &.the-fourth {
            &>:first-child {
              padding-left: 54px;
            }
          }
        }

        .financialData-header {
          background: #F8F8F9;

          &>* {
            color: var(--character-title-85, rgba(0, 0, 0, 0.85));
            font-weight: 500;
          }
        }

        .financialData-container--item {
          &>* {
            border: 0px;
          }
        }

      }
    }
  }
}

.movement-financialData--tooltip {
  padding: 6px 8px;
  color: rgba(0, 0, 0, 0.85);
  text-align: start;
  text-decoration: none;
  word-wrap: break-word;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 3px;
  :global{
    .financialData-tooltip--item {
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.88);
      &.fall {
        color: #D60000;
      }
      &.rise {
        color: #00D62F;
      }
    }
    .financialData-trend-icon {
      width: 12px;
      height: 12px;
      display: inline-flex;
    }
  }
}

.movement-financialData-tctc {
  :global {
    .financialData-container {
      .financialData-scrollArea {
        .financialData-item--common {
            grid-template-columns: 27% 30% 10% 23% 10%;
        }
      }
    }
  }
}