.aisList-filter {
	width: 300px;
	height: 300px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	gap: 5px;
	padding: 14px;
	border-radius: 6px;
	border: 1px solid rgba(217, 217, 217, 0.6);
	background: #fff;
	box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);

	:global {
		.aisList-filter-header {
			color: rgba(117, 117, 117, 1);
			font-size: 14px;
			line-height: 20px;
			font-weight: 400;
		}
		.aisList-filter-container {
			width: 100%;
			height: calc(100% - 25px);
			overflow: hidden auto;

			&::-webkit-scrollbar {
				width: 5px;
				height: 0px;
			}

			&::-webkit-scrollbar-corner {
				background-color: rgba(41, 42, 44, 1);
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 6px;
				background-color: rgba(53, 86, 145, 0.18);
				border: 1px solid rgb(219, 219, 219);
				cursor: pointer;
			}

			&::-webkit-scrollbar-track {
				border-radius: 4px;
			}
			.aisList-filter-scrollArea {
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: 5px;
				.aisList-filter-item {
					padding: 8px 6px;
					width: 100%;
					display: flex;
					flex-direction: column;
					transition: background 0.3s ease;
					cursor: pointer;
					.aisList-filter-vesselName {
						color: rgba(30, 30, 30, 1);
						font-size: 14px;
						line-height: 20px;
						font-weight: 400;
					}
					.aisList-filter-otherInfo {
						width: 100%;
						display: grid;
						grid-template-columns: repeat(auto-fit, calc((100% - 5px) / 2));
						gap: 5px;
						color: rgba(117, 117, 117, 1);
						font-size: 13px;
						line-height: 20px;
						font-weight: 400;
					}
					&:hover {
						background: rgba(76, 114, 215, 0.06);
					}
				}
			}
		}
	}
}
