.statement-fileDownload {
	width: 670px;
	display: flex;
	flex-direction: column;
	gap: 15px;
	background-color: #fff;
	padding: 20px;
	border-radius: 6px;
	:global {
		.fileDownload-container {
			width: 100%;
			max-height: 80vh;
			overflow: auto;
			display: flex;
			flex-direction: column;
			gap: 10px;
			&::-webkit-scrollbar {
				width: 6px;
				position: absolute;
			}
			&::-webkit-scrollbar-thumb {
				width: 6px;
				border-radius: 4px;
				background-color: rgba(0, 0, 0, 0.1);
			}
			.fileDownload-files {
				display: flex;
				flex-direction: column;
				gap: 12px;
				.header--title {
					color: #484848;
					font-size: 16px;
					font-weight: 500;
					line-height: 19px;
					display: flex;
					align-items: center;

					&::before {
						content: "";
						display: inline-block;
						margin-right: 8px;
						border-radius: 32px;
						background: #355691;
						width: 6px;
						height: 6px;
					}
				}
				.uploadfile-item {
					width: 100%;
					border-radius: 4px;
					border: 1px solid #d9d9d9;
					background: #fff;
					padding: 9px 8px;
					display: flex;
					justify-content: space-between;
					align-items: center;

					.uploadfile-info {
						display: flex;
						gap: 8px;
						align-items: center;
						.img-icon {
							font-size: 48px;
							width: 48px;
							height: 48px;
						}
						& > :first-child {
							font-size: 48px;
							color: #2fa556;
							border-radius: 6px;
							line-height: 1;
						}

						& > :last-child {
							color: #355691;
							font-size: 14px;
							font-weight: 400;
							line-height: 22px;
							width: 300px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							/* 157.143% */
						}
					}
					.uploadfile-tool {
						display: flex;
						gap: 8px;
						align-items: center;
					}

					.uploadfile-icon {
						font-size: 14px;
						cursor: pointer;
					}
				}
			}
			.fileDownload-nodata {
				display: flex;
				justify-content: center;
				height: 100px;
				align-items: center;
				border-radius: 4px;
				border: 1px solid #d9d9d9;
			}
		}
	}
}
