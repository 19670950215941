.layout-header-menu {
  display: flex;
  height: 100%;

  :global {
    .menu-item {
      display: flex;
      height: 100%;
      padding: 10px 18px;
      align-items: center;
      cursor: pointer;
      margin-right: 8px;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      .menu-icon {
        width: 16px;
        height: 16px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        user-select: none;
      }

      >span {
        color: #707070;
        
        font-size: 14px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        user-select: none;
      }

      &:hover {
        border-radius: 6px;
        background: rgba(53, 86, 145, 0.07);
      }

      &.active {
        border-radius: 6px;
        background: rgba(53, 86, 145, 0.10);

        >span {
          color: rgba(53, 86, 145, 1);
        }
      }

      &.disabled {
        border-radius: 0px;
        background: unset;
        >span {
          color: rgba(53, 86, 145, .6);
        }
      }
    }

    :last-child {
      margin-right: 0px;
    }

  }
}