.esDetails-stowagePlan {
  // width: 629px;
  height: 846px;
  padding: 20px 32px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: #FFF;
  min-width: 347px;

  :global {
    .stowagePlan-header {
      margin-bottom: 16px;
    }

    .stowagePlan-container {
      width: 100%;
      height: calc(100% - 87px);
      border-radius: 6px;
      border: 1px solid #D9D9D9;
      border-right: 0px;
      display: flex;
      margin-bottom: 16px;

      .stowagePlan {
        width: 283px;
        height: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 6px 0px 0px 6px;

        .stowagePlan-label {
          width: 100%;
          height: 39px;
          display: flex;
          background: #FAFAFA;
          padding: 8px;
          padding-right: 0px;
          justify-content: flex-start;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);
          border-radius: 6px 0px 0px 0px;

          &>span {
            width: 100%;
            color: rgba(0, 0, 0, 0.85);
            
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            display: flex;
            align-items: center;
            border-right: 1px solid rgba(0, 0, 0, 0.06);

            &::before {
              content: "";
              display: inline-block;
              width: 6px;
              height: 6px;
              border-radius: 11px;
              background: #254377;
              margin-right: 6px;

            }
          }
        }

        .pt-container,.loaded-container,.minaSapr-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          height: 370px;
          border-right: 1px solid rgba(0, 0, 0, 0.06);
          .pt-vpLabel {
            width: 100%;
            height: 39px;
            padding: 8px;
            color: #707070;
            
            font-size: 12px;
            font-weight: 500;
            line-height: 22px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.06);
            background: #FAFAFA;
            /* 183.333% */
          }
          .pt-item {
            width: 100%;
            height: 66px;
            display: flex;
            align-items: flex-end;
            padding: 6px 8px;

            .pt-formItem-1 {
              width: 231px;
              margin-right: 4px;
            }

            .pt-formItem-2 {
              width: 190px;
              margin-right: 4px;
            }

            .pt-formItem-3 {
              width: 131.5px;
              margin-right: 4px;
            }

            .pt-formItem-4 {
              width: 100%;
            }

            &>:last-child.pt-formItem-3 {
              margin-right: 0px
            }

            .pt-radio {
              width: 32px;
              height: 32px;
              cursor: pointer;
            }

            .pt-radio-distance {
              margin-right: 10px;
            }
          }

          &>:last-child.pt-item {
            border-radius: 0px 0px 6px 6px;
          }
        }
        .loaded-label {
          border-right: 1px solid rgba(0, 0, 0, 0.06);
          border-radius: 0px;
          &>span{
            border-right: 0px;
          }
        }
        .loaded-container {
          padding-bottom: 4px;
          height: 268px;
          border-radius: 0px 0px 6px 6px;
        }
      }

      .stowagePlan-minaSapr {
        border-radius: 0px 6px 6px 0px;

        .stowagePlan-label {
          border-radius: 0px 6px 0px 0px;

          &>span {
            border-right: 0px;

            &::before {
              background: #FF8632;
            }
          }
        }
        .maxQuantity-label {
          border-radius: 0px 0px 6px 0px;
          border-bottom: 0px;
          &>span:last-child {
            border-right: 0px;

            &::before {
              display: none;
            }
          }
        }
        .minaSapr-container {
          height: 635px;
          border-right: 0px;
        }
      }
    }

    .stowagePlan-footer {
      width: 100%;
      display: flex;
      height: 36px;
      align-items: center;
      gap: 8px;
      justify-content: flex-end;
    }
  }
}