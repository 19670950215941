.common-contactSelect--Popup {
  height: 270px;
  width: 483px;

  :global {
    .select--popup--header {
      grid-template-columns: 148px 83px 122px 74px;
    }

    .select--popup--container {
      .select--popup--scrollArea {
        .select--popup--item {
          grid-template-columns: 148px 83px 122px 74px;
        }
      }
    }
  }
}