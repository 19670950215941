.fleet-personal-information {
  width: 420px;
  height: 600px;
  border-radius: 4px;
  border: 1px solid #e6ebf5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  :global {
    .personal-information-header {
      font-size: 18px;
      padding: 16px 15px 7px;
      height: 50px;
      border-bottom: 1px solid #e6ebf5;
      color: #303133;
    }
    .personal-information-profile {
      position: relative;
      height: 200px;
      margin: 0 30px;
      border-bottom: 1px solid #e6ebf5;
      .profile {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: #dbdbdb;
        overflow: hidden;
        // position: relative;
        .ant-upload-wrapper {
          height: 150px;
          width: 150px;
          .ant-upload {
            height: 150px;
            width: 150px;
          }
        }
        img {
          width: 150px;
          height: 150px;
          &:hover {
            background: rgba(0, 0, 0, 0.5);
          }
          &::after {
            content: "+";
            height: 150px;
            width: 150px;
            text-align: center;
            font-size: 20px;
            color: #333;
            background: rgba(0, 0, 0, 0.5);
            cursor: pointer;
            line-height: 150px;
          }
        }
      }
    }

    .information-item {
      margin: 0 30px;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      border-bottom: 1px solid #e6ebf5;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .information-item-value {
        flex: 1;
        text-align: right;
      }
    }
  }
}
