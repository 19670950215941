.fleet-tabs {
	height: 100%;

	:global {
		.tabs {
			height: 100%;

			.ant-tabs-nav {
				margin: 0px;
				height: 100%;
				padding-left: 20px;
				min-width: 600px;
				.ant-tabs-nav-wrap {
					overflow: unset;
				}
			}
			.ant-tabs-content-holder {
				display: none;
			}
		}
	}
}

.fleet-tabbar {
	display: flex;
	height: 32px;

	:global {
		.tabbar-item {
			display: flex;
			justify-content: center;
			align-items: center;

			font-size: 12px;
			font-weight: 400;
			line-height: 22px;
			cursor: pointer;
			color: #467996;
			border: 1px solid #467996;
			transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

			&.left {
				border-radius: 6px 0px 0px 6px;
				padding: 5px 8px;
				border-right: 0px;
			}

			&.middle {
				padding: 5px 6px;
			}

			&.right {
				border-radius: 0px 6px 6px 0px;
				border-left: 0px;
				padding: 5px;
			}

			&.active {
				color: #fff;
				background: #467996;
				border: 0px;
			}

			// &.disabled,
			// &.left {
			//   border: 1px solid rgba(212, 212, 212, 0.50);
			//   border-right: 0px;
			//   border-radius: 6px 0px 0px 6px;
			//   background: #E4E4E4;
			//   cursor: not-allowed;
			//   color: #ABABAB;
			// }

			// &.disabled,
			// &.right {
			//   border-radius: 0px 6px 6px 0px;
			//   border-top: 1px solid #D4D4D4;
			//   border-right: 1px solid #D4D4D4;
			//   border-bottom: 1px solid #D4D4D4;
			//   background: #FFF;
			//   color: #ABABAB;
			//   cursor: not-allowed;
			// }
		}

		.disabled {
			&.left {
				border: 1px solid rgba(212, 212, 212, 0.5);
				border-right: 0px;
				border-radius: 6px 0px 0px 6px;
				background: #e4e4e4;
				cursor: not-allowed;
				color: #ababab;
			}

			&.right {
				border-radius: 0px 6px 6px 0px;
				border-top: 1px solid #d4d4d4;
				border-right: 1px solid #d4d4d4;
				border-bottom: 1px solid #d4d4d4;
				background: #fff;
				color: #ababab;
				cursor: not-allowed;
			}
		}
	}
}

.fleet-portTabs {
	width: 236px;
	border-radius: 6px;
	padding: 3px;
	border: 1px solid #d2dae4;
	background: rgba(53, 86, 145, 0.08);
	display: flex;
	height: 32px;
	margin-bottom: 12px;

	:global {
		.tab-item {
			width: calc(100% / 3);
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 2px 12px;
			background-color: transparent;
			color: rgba(53, 86, 145, 0.7);

			font-size: 12px;
			font-weight: 400;
			border-radius: 4px;
			line-height: 22px;
			/* 183.333% */
			transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			cursor: pointer;

			&.active {
				background-color: #355691;
				color: #ffffff;
			}
		}
	}
}
