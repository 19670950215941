.apAr-list-filter {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  :global {
    .filter-title {
      color: var(--color-text-body-1, #151515);
      font-size: 20px;
      font-weight: 590;
      line-height: 28px;
      /* 140% */
    }

    .filter-container {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .filter-wrap {
        display: grid;
        grid-template-columns: 210px 240px 153px 153px 153px 153px;
        grid-template-rows: repeat(auto-fit, 32px);
        gap: 8px;
      }
      .filter-toolbar {
        display: flex;
        gap: 8px;
        align-items: center;
        justify-self: flex-end;
      }
    }
  }
}