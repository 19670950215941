.apar-tag {
	padding: 0px 4px;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	border-radius: var(--spacing-1, 2px);
}

.apar-tag--Unsettled {
	color: #e18a07;
	background: rgba(225, 164, 7, 0.1);
}

.apar-tag--Settled {
	background: rgba(17, 147, 15, 0.1);
	color: #11930f;
}

.apar-tag--Inprogress {
	background: rgba(79, 141, 253, 0.1);
	color: #4f8dfd;
}

.apar-tag--AP {
	background: rgba(79, 141, 253, 0.1);
	color: #4f8dfd;
}

.apar-tag--AR {
	background: rgba(17, 147, 15, 0.1);
	color: #11930f;
}

.apar-tag--TCTC {
	background: rgba(9, 209, 125, 0.12);
	color: #06b169;
}

.apar-tag--TCVC {
	background: var(--color-bg-accent-fleetblue-subtler, #ebeef4);
	color: var(--color-bg-accent-fleetblue-bolder, #355691);
}
