.routeCommon-portCall {
	width: 100%;
	height: 100%;
	position: relative;
	display: none;
	padding: 12px 12px 8px 25px;
	flex-direction: column;
	gap: 20px;
	:global {
		.portCall-header {
			width: 100%;
			display: flex;
			justify-content: space-between;
			.portCall-header-leftArea {
				display: flex;
				gap: 20px;
				align-items: center;
				.portCall-header-crumbs {
					display: flex;
					align-items: center;
					gap: 16px;
					.crumbs-pointer {
						font-size: 32px;
						cursor: pointer;
						transform: rotateZ(180deg);
					}
					.crumbs-container {
						display: flex;
						gap: 8px;
						align-items: center;
						font-size: 16px;
						font-weight: 500;
						line-height: 22px;
						color: var(--Text-Default, #333);
						& > :first-child {
							// text-decoration-line: underline;
							color: #44a5ff;
						}
						& > :nth-child(2) {
							color: rgba(72, 72, 72, 0.6);
						}
					}
				}
				.portCall-header-summaryItem {
					color: rgba(72, 72, 72, 0.6);
					font-size: 14px;
					font-weight: 500;
					line-height: 22px;
					& > :last-child {
						color: #333;
					}
				}
			}
		}
		.portCall-container {
			width: 100%;
			height: calc(100% - 52px);
		}
	}
}
