.legend-waves {
	:global {
    .legend-waves-container {
      display: flex;
      align-items: center;
      &>* {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        width: 26px;
        padding: 2px 0px;
        text-align: center;
      }
    }
  }
}