.fleet-notification {
  display: flex;
  width: 384px;
  padding: 20px 24px;
  flex-direction: column;
  border-radius: 4px;
  // background: var(--conditional-pop-over, #FFF);
  background: #fff;
  gap: 8px;
  /* drop-shadow/0.12+0.8+0.5 */
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);

  :global {
    .notification-container {
      width: 100%;
      height: calc(100% - 48px);
      display: flex;
      gap: 16px;

      .notification-icon {
        width: 24px;
        height: 24px;
        display: flex;
      }

      .notification {
        width: calc(100% - 40px);
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .notification-header {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 24px;

          &>:first-child {
            // color: var(--character-title-85, rgba(0, 0, 0, 0.85));
            color: rgba(0, 0, 0, 0.85);
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            /* 150% */
          }

          &>:last-child {
            width: 14px;
            height: 14px;
            display: flex;
          }
        }

        .notification-content {
          width: 100%;
          height: calc(100% - 32px);
          display: flex;
          // color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
          color: rgba(0, 0, 0, 0.85);
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          /* 157.143% */
        }

      }
    }

    .notification-footer {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      gap: 8px;
    }
  }
}