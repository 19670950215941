.vesselArrival-item {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 8px;

	:global {
		.vesselArrival-item--header {
			width: 100%;
			border-radius: 4px;
			background: rgba(53, 86, 145, 0.15);
			padding: 2px 8px;
			color: #254377;
			font-size: 12px;
			font-weight: 600;
			line-height: 20px;
			/* 166.667% */
		}

		.vesselArrival-item--container {
			width: 100%;
			display: grid;
			grid-template-columns: repeat(auto-fit, calc(100% / 6));
			grid-template-rows: repeat(auto-fit, 65px);
			gap: 8px 0px;

			& > * {
				display: flex;
				flex-direction: column;

				& > :first-child {
					width: 100%;
					padding: 6px 16px;
					background: #f8f8f9;
					color: rgba(0, 0, 0, 0.85);
					font-size: 12px;
					font-weight: 450;
					line-height: 22px;
					height: 35px;
					border-top: 1px solid #e5e5ea;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				& > :last-child {
					border-top: 1px solid #e5e5e5;
					border-bottom: 1px solid #e5e5e5;
					border-right: 1px solid #e5e5e5;
					width: 100%;
					padding: 6px 12px;
					color: #000;
					font-size: 12px;
					font-weight: 400;
					line-height: 16px;
					height: 30px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			& > :not(:nth-child(6n)) {
				& > :first-child {
					position: relative;

					&::after {
						content: "";
						display: inline-block;
						background-color: #e5e5ea;
						height: 16px;
						width: 1px;
						position: absolute;
						top: 8px;
						right: 0px;
					}
				}

				& > :last-child {
					border-right: 1px solid #e5e5e5;
				}
			}

			& > :nth-child(6n + 1) {
				& > :first-child {
					border-left: 1px solid #e5e5e5;
					border-radius: 4px 0px 0px 0px;
				}
				& > :last-child {
					border-left: 1px solid #e5e5e5;
					border-radius: 0px 0px 0px 4px;
				}
			}

			& > :last-child,
			& > :nth-child(6n) {
				& > :first-child {
					border-right: 1px solid #e5e5e5;
					border-radius: 0px 4px 0px 0px;
					&::after {
						display: none;
					}
				}
				& > :last-child {
					border-radius: 0px 0px 4px 0px;
				}
			}
		}
	}
}
