.common-contactSelect--Popup {
  height: 232px;
  width: 550px;
  display: flex;
  flex-direction: column;
  position: relative;
  :global{
    .contactSelect--popup--header {
      width: 100%;
      height: 38px;
      border-radius: 6px 6px 0px 0px;
      background-color: #EFF1F5;
      padding: 8px 16px;
      border-bottom: 1px solid rgba(217, 217, 217, 0.87);
      display: grid;
      gap: 12px;
      grid-template-columns: 130px 110px 156px 109px;
      grid-template-rows: repeat(auto-fit, 100%);
      &>* {
        color: #484848;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .contactSelect--popup--container {
      padding: 8px 16px;
      width: 100%;
      height: calc(100% - 76px);
      border-radius: 0px 0px 6px 6px;
      overflow: hidden auto;
      background: #FFF;
      .contact--popup--scrollArea {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        .contact--popup--item {
          width: 100%;
          height: 31px;
          display: grid;
          gap: 12px;
          grid-template-columns: 130px 110px 156px 109px;
          grid-template-rows: repeat(auto-fit, 22px);
          border-bottom: 1px solid #E6E6E6;
          padding-bottom: 8px;
          &>* {
            color: #707070;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .contactSelect--popup--loadMore {
      width: 100%;
      height: 38px;
      padding: 0px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      gap: 6px;
      color: #467996;
      &>:first-child {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
      &>:last-child {
        font-size: 16px;
        transform: rotateZ(-90deg);
      }
    }
  }
}