.fleet-aisDateSelect {
	margin: 0px;
	padding: 0px;
	list-style: none;
	gap: 3px;
	display: flex;

	:global {
		li {
			width: 26px;
			border-radius: 4px;
			border: 1px solid rgba(137, 137, 137, 0.22);
			background: rgba(99, 99, 99, 0.06);
			background-color: #fff;
			padding: 1px 0px;
			color: #1c1c1c;
			font-size: 12px;
			font-weight: 400;
			line-height: 20px;
			cursor: pointer;
			text-align: center;
			transition: all 300ms ease-out;

			&.active {
				background: rgba(61, 61, 61, 0.88);
				color: #fff;
				border-color: transparent;

				&.highest {
					background: #ff8632;
				}
			}
		}
	}
}

.fleet-aisDateSelect-tooltip {
	:global {
		.ant-popover-inner {
			padding: 6px 8px !important;
		}
	}
}
