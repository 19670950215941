.routeCommon-port-tabbar {
	width: 100%;
	display: flex;
	gap: 5px;
	:global {
		.port-tabbar-item {
			font-size: 14px;
			line-height: 20px;
			font-weight: 400;
			color: #757575;
			padding: 4px 10px;
      cursor: pointer;
      &.active {
        color: #2C2C2C;
        background-color: rgb(245, 245, 245);
        border-radius: 4px;
      }
		}
	}
}
