.invoices-detail {
	height: 100%;
	width: calc(100% - 195px);
	background: var(--color-bg-page, #f0f2f5);
	// box-shadow: 0px 2px 13px 0px rgba(31, 36, 41, 0.20);
	display: flex;
	flex-direction: column;
	position: relative;

	:global {
		.invoicesDetail-header {
			width: 100%;
			height: 48px;
			padding: 8px 12px;
			border-bottom: 1px solid var(--color-line-separator, #e7e9ec);
			background: var(--color-elevation-surface-overlay, #f7f8fa);

			box-shadow: 10px 2px 13px 0px rgba(31, 36, 41, 0.08);
			// z-index: 3;

			display: grid;
			grid-template-columns: calc((100% - 143px) / 2) 143px calc((100% - 143px) / 2);
			grid-template-rows: repeat(auto-fit, 100%);

			.invoicesPeriod-container {
				display: flex;
				color: var(--color-text-body-1, #151515);
				font-size: 14px;
				font-weight: 400;
				line-height: 22px;
				justify-content: flex-start;
				gap: 12px;

				.invoicesPeriod-version {
					display: flex;
					align-items: center;
					gap: 8px;

					.invoicesPeriod-input {
						align-items: center;
						// width: 50px;
						// justify-content: space-between;

						& > span {
							margin-right: 0px;
						}

						.input-container {
							width: 32px;

							input {
								min-width: unset;
							}
						}
					}
				}

				.toolbar-action {
					font-weight: 400;
					border: 0px;
					background: var(--color-bg-button-thirdly-default, #f7f8fa);
					color: var(--color-text-body-1, #151515);
					box-shadow: none;
					align-items: center;
					width: 43px;
					gap: 2px;
				}
			}

			.invoicesTemplate-container {
				justify-self: center;
				width: 143px;
				margin-bottom: 0px;
				height: unset;

				.tab-item {
					padding: 0px 16px;
					// width: calc(100% / 2);
					width: unset;
				}
			}

			.invoicesToolbar-container {
				display: flex;
				gap: 8px;
				justify-content: flex-end;

				.toolbar-action {
					font-weight: 400;
					border: 1px solid var(--color-border-button-thirdly-default, #dadada);
					background: var(--color-bg-button-thirdly-default, #fff);
					color: var(--color-text-body-1, #151515);
				}

				// &>:first-child.toolbar-action {
				//   border: 0px;
				//   background: var(--color-elevation-surface-overlay, #F7F8FA);
				//   box-shadow: none;
				// }

				// .toolbar-icon {
				//   display: flex;
				// }
			}

			.invoicesDetail-icon {
				width: 16px;
				height: 16px;
				display: flex;
			}
		}

		.invoicesDetail-container {
			width: 100%;
			height: calc(100% - 48px);
			overflow: hidden;
			display: flex;
			background-image: url(static/image/invoices_bg.jpeg);
			background-size: 100% 206px;
			/* 禁止背景图片重复 */
			background-repeat: no-repeat;
			position: relative;
			display: flex;
			justify-content: center;

			.invoicesTemplate-scrollArea {
				width: 930px;
				height: 100%;
				overflow: hidden auto;
				position: absolute;
				// margin-top: 64px;
				z-index: 1;
				opacity: 0;
				// transform: translateX(-100%);
				// left: 50%;
				// transform: translateX(-50%);
				// transition:  0.5s;
				// animation: fadeInOut 4s infinite;
			}

			.invoicesDetail-scrollArea {
				width: 872px;
				// height: calc(100% - 64px);
				height: 100%;
				overflow: hidden auto;
				position: absolute;
				// margin-top: 64px;
				z-index: 2;
				opacity: 1;
				// transform: translateX(0px);
				// left: 50%;
				// transform: translateX(-50%);
				// transition: opacity 0.5s;
				// animation: fadeInOut 4s infinite;

				.invoicesDetail-item {
					width: 100%;
					padding: 30px 24px 36px 24px;
					border-radius: 6px;
					background: var(--color-bg-container, #fff);
					/* card shadow */
					// box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.10);
					display: flex;
					flex-direction: column;
					gap: 28px;
					box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);

					.invoicesDetail-form {
						width: 100%;
						display: flex;
						flex-direction: column;
						gap: 16px;
						border-radius: 4px;
						border: 1px solid rgba(0, 0, 0, 0.1);
						background: #fbfbfb;
						padding: 12px;

						.invoicesForm-header {
							width: 100%;
							display: flex;
							flex-direction: column;
							gap: 8px;
							padding-bottom: 8px;
							border-bottom: 1px solid #e7e9ec;

							& > :first-child {
								color: var(--color-text-body-1, #151515);
								font-size: 16px;
								font-weight: 590;
								line-height: 24px;
								/* 150% */
							}

							& > :last-child {
								color: var(--color-text-body-3, #a3a3a3);
								font-size: 12px;
								font-weight: 400;
								line-height: 20px;
								/* 166.667% */
							}
						}

						.invoicesForm-container {
							width: 100%;
							gap: 16px 8px;
							display: grid;
							align-items: flex-end;
							grid-template-columns: repeat(auto-fit, calc((100% - 24px) / 4));
							grid-template-rows: repeat(auto-fit, 56px);
						}

						.invoicesForm-subheading {
							color: var(--color-text-body-3, #a3a3a3);
							font-size: 12px;
							font-weight: 700;
							line-height: 20px;
							/* 166.667% */
						}
					}
				}
			}

			&.preview {
				.invoicesTemplate-scrollArea {
					z-index: 2;
					opacity: 1;
				}

				.invoicesDetail-scrollArea {
					z-index: 1;
					opacity: 0;
				}
			}
		}
	}
}

.operate-menu {
	display: flex;
	flex-direction: column;
	width: 101px;
	border-radius: 6px;
	background: #fff;
	gap: 4px;
	padding: 4px;
	box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);

	:global {
		.operateMenu-item {
			width: 100%;
			height: 32px;
			display: flex;
			gap: 8px;
			padding: 8px;
			cursor: pointer;
			// color: var(--, #EE605B);
			font-size: 14px;
			font-weight: 400;
			line-height: normal;

			.operateMenu-icon {
				width: 16px;
				height: 16px;
				display: flex;
			}

			&.disabled {
				color: rgba(31, 36, 41, 0.08);
			}
		}
	}
}
