.timeAxis-control {
	width: 150px;
	justify-content: space-between;
	padding: 3px 12px 3px 16px;
	height: 100%;
	display: flex;
	align-items: center;
  border-right: 2px solid rgba(0, 0, 0, 0.15);
	:global {
		.timeAxis-control-timeSelect {
			height: 100%;
			width: 75px;
			.ant-select-selector {
				border: 0px;
				background: unset !important;
				padding: 0px;
				box-shadow: none !important;
				border-color: transparent !important;
				.ant-select-selection-item {
					padding-inline-end: 0px;
					color: rgba(0, 0, 0, 0.85);
					font-size: 12px;
					font-weight: 400;
					line-height: 22px; /* 183.333% */
				}
				.ant-select-selection-placeholder {
					line-height: 22px;
				}
				.ant-select-selection-search {
					inset-inline-start: 0px;
					inset-inline-end: 0px;
					input {
						color: rgba(0, 0, 0, 0.85);
						font-size: 12px;
						font-weight: 400;
						line-height: 22px; /* 183.333% */
						height: 100%;
					}
				}
			}
			.ant-select-arrow {
				right: 0px;
			}
		}
		.control-operation {
			display: flex;
			align-items: center;
			gap: 4px;

			& > * {
				cursor: pointer;
				&.next {
					// transform: rotateZ(90deg);
				}
			}
		}
	}
}
