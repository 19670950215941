.portVessel-list {
	width: calc(100% - 340px);
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	gap: 20px;
	:global {
		.portVessel-list-header {
			width: 100%;
			display: flex;
			justify-content: space-between;
			.portVessel-list-info {
				display: flex;
				gap: 20px;
				align-items: center;
				& > :first-child {
					color: var(--Text-Default, #333);
					font-size: 16px;
					font-weight: 500;
					line-height: 22px;
				}
				.list-headerItem {
					color: rgba(72, 72, 72, 0.6);
					font-size: 14px;
					font-weight: 500;
					line-height: 22px;
					& > :last-child {
						color: #333;
					}
				}
			}
		}
		.portVessel-list-container {
			width: 100%;
			height: calc(100% - 52px);
			.ant-spin-nested-loading {
				.ant-spin-container {
					.ant-table {
						height: 100%;
						.ant-table-container {
							.ant-table-header {
								.ant-table-thead {
									tr {
										th {
											padding: 4px 8px;
											color: #333;
											font-size: 12px;
											font-weight: 450;
											line-height: 22px;
											background: #e6eaef;
										}
									}
								}
							}
							.ant-table-body {
								height: calc(100% - 31px) !important;
								tr {
									td {
										padding: 4px 8px;
										color: #484848;
										font-size: 12px;
										font-weight: 400;
										line-height: 22px;
									}
								}
							}
							.portVessel-list-operation {
								display: flex;
								gap: 12px;
								& > * {
									color: #355691;
									font-size: 12px;
									font-weight: 400;
									line-height: 22px; /* 183.333% */
									cursor: pointer;
								}
							}
						}
					}
				}
			}
		}
	}
}
