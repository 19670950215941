.esDetials-performance {
  width: 835px;
  height: 657px;
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
  border-radius: 6px;
  background: #FFF;
  position: relative;

  :global {
    .performance-header {
      display: flex;
      width: 100%;
      height: 23px;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;

      .header-title {
        color: #254377;
        
        font-size: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;

        &::before {
          border-radius: 34px;
          background: #355691;
          width: 6px;
          height: 16px;
          content: "";
          display: inline-block;
          margin-right: 8px;
        }
      }

      .header-close {
        width: 16px;
        height: 16px;
        display: flex;
        cursor: pointer;
      }
    }

    .performance-tabs {
      margin-bottom: 12px;
      height: 32px;
      width: 100%;

      .tabs {
        width: 132px;
        margin-bottom: 0px;

        .tab-item {
          width: calc(100% / 2);
        }
      }
    }

    .performance-container {
      width: 100%;
      height: calc(100% - 115px);
      display: flex;
      flex-direction: column;

      &>:first-child {
        margin-bottom: 12px;
        border-bottom: 1px solid #D9D9D9;
        ;
      }
    }

    .performance-footer {
      width: 100%;
      height: 36px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap:8px;      
    }
  }
}