.movement-table {
  width: 1186px;
  height: 166px;
  overflow: auto;
  border: 1px solid #EBEBEB;
  border-radius: 6px;
  :global {
    .table-layout {
      width: max-content;
      border-collapse: collapse;

      .table-thead {
        tr {
          th {
            position: sticky;
            top: 0;
            height: 31px;
            background-color: #f5f5f5;
            padding: 8px;
            // border: 1px solid #ccc;
            z-index: 10;
            color: rgba(0, 0, 0, 0.88);
            text-align: center;
            font-size: 12px;
            font-weight: 450;
            line-height: 22px;
            /* 183.333% */
            padding: 4px 8px;
            white-space: nowrap;
            border-bottom: 1px solid #EBEBEB;
            &::after {
              position: absolute;
              right: 0px;
              top: 50%;
              transform: translateY(-50%);
              width: 1px;
              height: 22px;
              background: rgba(0, 0, 0, 0.06);
              display: inline-block;
              content: "";
            }
          }

          &>th:nth-child(1) {
            position: sticky;
            left: 0px;
            z-index: 13;
          }

          &>th:nth-child(2) {
            position: sticky;
            left: 16px;
            z-index: 12;
          }

          &>th:nth-child(3) {
            position: sticky;
            left: 64px;
            z-index: 11;
          }

          // &>th:last-child {
          //   position: sticky;
          //   right: 0px;
          //   z-index: 11;
          //   &::after {
          //     display: none;
          //   }
          // }
        }
      }

      .table-tbody {
        tr {
          // border-bottom: 1px solid #EBEBEB;
          td {
            background-color: #fff;
            padding: 2px 8px;
            height: 27px;
            color: #484848;
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            line-height: 22px;
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 150px;
            border-bottom: 1px solid #EBEBEB;
            /* 183.333% */
            // &::before {
            //   content: "";
            //   position: absolute;
            //   top: 0;
            //   left: 0;
            //   width: 100%;
            //   height: 100%;
            //   border-right: 1px solid #ccc;
            //   pointer-events: none; /* 防止遮挡鼠标事件 */
            //   box-sizing: border-box;
            // }
          }

          // &>td:nth-child(1) {
          //   position: sticky;
          //   left: 0px;
          //   z-index: 5;
          //   &::before {
          //     border-left: 1px solid #ccc;
          //   }
          // }

          // &>td:nth-child(2) {
          //   position: sticky;
          //   z-index: 4;
          //   left: 16px;
          // }

          // &>td:nth-child(3) {
          //   position: sticky;
          //   z-index: 2;
          //   left: 64px;
          // }


          // &>td:last-child {
          //   position: sticky;
          //   right: 0px;
          //   z-index: 1;
          // }
          &.summay {
            td {
              position: sticky;
              bottom: 0;
              z-index: 10;
              text-align: center;
              span{
                display: inline-flex;
                justify-content: center;
                align-items: center;
                text-align: center;
              }
              
            }
          }
        }

      }
    }
  }
  &::-webkit-scrollbar {
    width: 0px;
    height: 5px;
  }

  &::-webkit-scrollbar-corner {
    background-color: rgba(41, 42, 44, 1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: rgba(53, 86, 145, 0.18);
    border: 1px solid rgb(219, 219, 219);
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }

  // scrollbar-width: none;
  // /* Firefox */
  // -ms-overflow-style: none;
}