.voyagemanage-apAr {
  background: #FFF;
  padding: 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  gap: 8px;

  :global {
    .apAr-table {
      width: 100%;
      height: calc(100% - 76px);
      &.summary {
        .ant-table-body {
          height: calc(100% - 133px) !important;
        }
      }
    }
  }
}