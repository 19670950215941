.routeCommon-dailyReportDetails {
	:global {
		.dailyReportDetails-container {
			.dailyReportDetails-scrollArea {
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				.textarea {
					padding: 0px;
					border: 0px;
					border-radius: 0px;
					textarea {
						background-color: #f4f4f4;
						color: var(--Main-Text, #484848);
						font-size: 14px;
						font-weight: 400;
						line-height: 22px;
					}
					.ant-input-suffix {
						display: none;
					}
				}
				/* 157.143% */
			}

			&::-webkit-scrollbar {
				width: 6px;
				height: 6px;
			}

			&::-webkit-scrollbar-thumb {
				background-color: rgba(192, 197, 207, 1);
				border-radius: 3px;
			}
		}
	}
}
