.estDetails-vesselParticular {
  width: 100%;
  border: 1px solid rgba(217, 217, 217, 0.87);
  background: #FFF;
  flex-direction: column;
  display: flex;
  border-radius: 6px;
  padding: 12px;
  gap: 12px;

  :global {
    .vesselParticular-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 12px;
      border-bottom: 1px solid #D9D9D9;

      .vesselParticular-template {
        gap: 4px;
        display: grid;
        grid-template-columns: 149px 28px;
        grid-template-rows: repeat(auto-fit, 28px);
        align-items: center;

        &>:first-child {
          &>:last-child {
            height: 28px;
          }
        }

        &>:last-child {
          height: 28px;
        }
      }
    }

    .vesselParticular-container {
      width: 100%;
      display: flex;
      gap: 10px;
      flex-direction: column;

      .vesselParticular-item {
        display: grid;
        width: 100%;
        grid-template-rows: 56px;
        grid-template-columns: repeat(auto-fit, calc((100% - 8px)/3));
        gap: 4px;
        align-items: flex-end;

        &.bisector {
          grid-template-columns: repeat(auto-fit, calc((100% - 4px)/2));
        }

        &.untrisection {
          grid-template-columns: 1fr 1fr 1fr;
        }

        input {
          min-width: unset;
        }
      }
    }
  }
}