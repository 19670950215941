.movement-settings {
	width: 281.5px;
	display: flex;
	flex-direction: column;
	gap: 12px;
	border-radius: 4px;
	background: #fff;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
	padding: 16px;

	:global {
		.movement-settings-container {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 12px;

			.settings-item {
				width: 100%;
				height: 32px;
				display: grid;
				grid-template-columns: calc(100% - 166px) 160px;
				grid-template-rows: repeat(auto-fit, 32px);
			}
		}
	}
}

.movement-settings-tctc {
	width: 100%;

	:global {
		.movement-settings-container {
			display: grid;
			grid-template-columns: repeat(auto-fit, calc((100% - 36px) / 4));
			.settings-item {
				width: unset;
				display: flex;
			}
		}
	}
}
