.voygae-item {
	width: 330px;
	// height: 102px;
	display: flex;
	flex-direction: column;
	padding: 14px 16px;
	// display: grid;
	// grid-template-columns: repeat(auto-fit, 100%);
	// grid-template-rows: repeat(auto-fit, calc((100% - 8px) / 3));
	gap: 4px;
	border-radius: 6px;
	border: 1px solid var(--color-border-card, #dadada);
	background: var(--color-elevation-surface-overlay, #f7f8fa);
	cursor: pointer;

	:global {
		.voyageItem-voyageType {
			width: fit-content;
			padding: 0px 4px;
			border-radius: var(--spacing-1, 2px);
			color: var(--color-bg-accent-fleetblue-bolder, #355691);
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			&.TCTC {
				background: rgba(9, 209, 125, 0.12);
				color: #06b169;
			}
			&.TCVC {
				background: var(--color-bg-accent-fleetblue-subtler, #ebeef4);
				color: var(--color-bg-accent-fleetblue-bolder, #355691);
			}
		}
		.voyageItem-card {
			display: flex;
			align-items: center;
			gap: 4px;

			& > * {
				color: var(--color-text-body-3, #a3a3a3);
				font-size: 14px;
				font-weight: 400;
				line-height: 22px;
				width: calc((100% - 2px) / 2);
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			&.header {
				& > :first-child {
					color: var(--color-text-body-1, #151515);
					font-size: 14px;
					font-weight: 510;
					line-height: 22px;
				}

				& > :last-child {
					padding: 0px 4px;
					font-size: 14px;
					font-weight: 400;
					line-height: 22px;
					width: fit-content;
					border-radius: 4px;
					background: var(--color-bg-accent-fleetblue-subtler, #ebeef4);
					color: var(--color-bg-accent-fleetblue-bolder, #355691);
					&.Commencing {
						background: var(--color-bg-accent-fleetblue-subtler, #ebeef4);
						color: var(--color-bg-accent-fleetblue-bolder, #355691);
					}

					&.Completed {
						background: var(--color-bg-accent-green-subtler, #eef9e8);
						color: var(--color-bg-accent-green-bolder, #4cb518);
					}

					&.Closed {
						background: var(--color-bg-accent-neutral-subtler, #ededed);
						color: var(--color-bg-accent-neutral-bolder, #7d7d7d);
					}
				}
			}
		}
	}
}
