.fleet-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  top: 0;
  left: 0;
  opacity: 0.5;
  background: #f4f4f4;
  border-radius: inherit;
  :global{
    .loading-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
    .mask-loading {
      
    }
  }
}