.fleet-select {
	height: 32px;

	// padding: 5px 8px;
	:global {
		.select {
			width: 100%;
			height: 100%;

			.ant-select-selector {
				cursor: pointer;

				.ant-select-selection-overflow {
					flex-wrap: nowrap;
					overflow: hidden;
				}
				.ant-select-selection-overflow-item-rest {
					.ant-select-selection-item {
						width: 26px;
						padding: 0px;
						margin: 0px;
						display: flex;
						justify-content: center;
						align-items: center;
						.ant-select-selection-item-content {
							margin: 0px;
							font-size: 12px;
							overflow: hidden;
							color: #355691;
							font-weight: 400;
						}
					}
				}
			}
		}
	}
}

.fleet-select-popup {
	padding: 0px;
	box-shadow:
		0px 9px 28px 8px rgba(0, 0, 0, 0.05),
		0px 6px 16px 0px rgba(0, 0, 0, 0.08),
		0px 3px 6px -4px rgba(0, 0, 0, 0.12);
}

.fleet-select-tag {
	width: 90px;
	height: 24px;
	padding: 3px 4px;
	background: rgba(0, 0, 0, 0.06);
	border-radius: 4px;
	align-items: center;
	display: flex;
	margin-right: 2px;
	:global {
		span {
			margin-right: 4px;
			line-height: 22px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-size: 12px;
			overflow: hidden;
			color: #355691;
			font-weight: 400;
		}
		.rm-icon {
			width: 14px;
			height: 14px;
			display: flex;
			cursor: pointer;
		}
	}
}
