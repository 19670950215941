.route-portCurrentItem {
	padding: 12px;
	width: 100%;
	border-radius: 6px;
	border: 1px solid rgba(0, 0, 0, 0.06);
	background: #fff;
	display: flex;
	flex-direction: column;
	gap: 10px;
	:global {
		.portCurrentItem-header {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.portCurrentItem-vesselType {
				color: #2853c3;
				padding: 2px 8px;
				font-size: 14px;
				font-weight: 600;
				line-height: 22px; /* 157.143% */
				border-radius: 4px;
				background: rgba(40, 83, 195, 0.1);
			}
			.portCurrentItem-vesselTotal {
				color: #2853c3;
				font-size: 13px;
				font-weight: 400;
				line-height: 22px; /* 169.231% */
			}
		}
		.portCurrentItem-container {
			width: 100%;
			display: grid;
			grid-template-columns: repeat(auto-fit, calc((100% - 8px) / 2));
			gap: 8px;
			& > * {
				color: var(--Second-Text, #707070);
				font-size: 13px;
				font-weight: 400;
				line-height: 22px;
				display: flex;
				justify-content: space-between;
				& > :last-child {
					color: #1e1e1e;
				}
			}
		}
	}
}
