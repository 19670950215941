.database-commonAdd {
  width: 872px;
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  gap: 12px;
  border-radius: 6px;
  position: relative;

  :global {
    .commonAdd-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      // height: 23px;

      .fleet-title {
        color: var(--color-text-body-1, #151515);
        font-size: 20px;
        font-weight: 590;
        line-height: 28px;
        /* 140% */
      }

      .header-close {
        width: 16px;
        height: 16px;
        display: flex;
      }
    }

    .commonAdd-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .commonAddItem {
        width: 100%;
        display: flex;
        flex-direction: column;

        .commonAddItem-label {
          width: 100%;
          color: var(--color-text-body-1, #151515);
          font-size: 14px;
          font-weight: 510;
          line-height: 22px;
          margin-bottom: 8px;
          padding-bottom: 8px;
          border-bottom: 1px solid #D9D9D9;
        }
        .commonAddItem-container {
          width: 100%;
          gap: 8px;
          display: grid;
          grid-template-columns: repeat(auto-fit,calc((100% - 24px)/4));
          grid-template-rows: repeat(auto-fit,56px);
        }
      }
    }

    .commonAdd-footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 32px;
      gap: 12px;
    }
  }
}