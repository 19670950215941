.routeCommon-groupSelect {
	display: flex;
	flex-direction: column;
	padding: 10px 16px;
	border-radius: 6px;
	box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
	background-color: #fff;
	gap: 10px;
	:global {
		.groupSelect-header {
			color: var(--color-text-body-1, #151515);
			font-size: 16px;
			font-weight: 400;
			line-height: 28px; /* 175% */
		}
		.groupSelect-container {
			display: flex;
			gap: 12px;
			.groupSelect-select {
				width: 154px;
			}
		}
		.groupSelect-footer {
			display: flex;
			gap: 10px;
			justify-content: flex-end;
		}
	}
}

.routeCommon-groupSelect--popover {
	width: 100%;
}
