.route-aisList {
	width: 100%;
	height: 100%;
	display: none;
	flex-direction: column;
	gap: 10px;
	position: relative;

	:global {
		.aisList-wrapper {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 12px;

			.aisList-wrapper-title {
				color: rgba(117, 117, 117, 1);
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				/* 19.6px */
			}

			.aisList-wrapper-header {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 8px 0px;
				border-top: 1px solid rgba(0, 0, 0, 0.1);
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);

				.aisList-wrapper-addGroup {
					display: flex;
					gap: 6px;
					align-items: center;
					color: rgba(40, 83, 195, 1);
					cursor: pointer;

					& > .addGroup-icon {
						font-size: 14px;
					}

					& > :last-child {
						color: #2853c3;
						font-size: 14px;
						font-weight: 400;
						line-height: 20px;
					}
				}
				.aisList-wrapper-actions {
					display: flex;
					& > * {
						padding: 5px 8px;
						border: 1px solid #46799680;
						display: flex;
						&.left {
							border-right: 0px;
							border-radius: 6px 0px 0px 6px;
						}
						&.right {
							border-left: 0px;
							border-radius: 0px 6px 6px 0px;
						}
						&.active {
							background-color: #467996;
							border-color: transparent;
							& > .left {
								border-right-color: transparent;
							}
							& > .right {
								border-left-color: transparent;
							}
						}
					}
				}
			}

			.aisList-wrapper-container {
				width: 100%;
				height: calc(100% - 90px);
				overflow: hidden auto;

				&::-webkit-scrollbar {
					width: 4px;
					height: 0px;
				}

				&::-webkit-scrollbar-corner {
					background-color: rgba(41, 42, 44, 1);
				}

				&::-webkit-scrollbar-thumb {
					border-radius: 6px;
					background-color: rgba(53, 86, 145, 0.18);
					border: 1px solid rgb(219, 219, 219);
					cursor: pointer;
				}

				&::-webkit-scrollbar-track {
					border-radius: 4px;
				}

				.aisList-wrapper-scrollArea {
					width: 100%;
					display: flex;
					flex-direction: column;
					gap: 14px;

					& > .drag-enter {
						border-radius: 4px;
						border-color: rgba(76, 114, 215, 0.6);
						background: rgba(76, 114, 215, 0.06);
					}
				}
			}
		}
	}
}

.route-aisList--active {
	display: flex;
}
