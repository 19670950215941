.otherReport-shifting {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  :global {
    .shifting-header {
      height: 42px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid #dedede;
      line-height: 32px;
      padding-bottom: 10px;
    }
    .shifting-main {
      height: calc(100% - 50px);
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 20px;
      overflow: hidden auto;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        width: 6px;
        border-radius: 3px;
        background: #d2d2d2;
      }
      .shifting-item {
        height: 200px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.06);
        padding: 10px;
        gap: 8px;
        .shifting-port {
          width: 150px;
          height: 90px;
          padding: 10px;
          border-radius: 4px;
          background-color: #f8f8f9;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          gap: 10px;
          font-size: 14px;
        }
        table {
          width: calc(100% - 350px);
          border-radius: 4px;
          .shiftingTable-tr {
            border: 1px solid #dedede;
            td {
              font-size: 14px;
              border: 1px solid rgba(0, 0, 0, 0.06);
            }
            .shiftingTable-header {
              background-color: #f8f8f9;
              font-size: 14px;
              border: 1px solid #dedede;
              height: 32px;
              line-height: 32px;
              padding: 0 8px;
            }
            .shiftingTable-input {
              background-color: #fff;
              border: 1px solid #dedede;
              height: 48px;
              line-height: 40px;
              .shiftingTable-cell {
                padding: 0 8px;
                display: flex;
                align-items: center;
                & > :first-child {
                  width: 100%;
                  line-height: 32px;
                }
              }
            }
          }
        }
        .shifting-footer {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 8px;
          .shifting-remark {
            width: 180px;
            height: calc(100% - 40px);
            border-radius: 4px;
            padding: 10px;
            background-color: #f8f8f9;
            font-size: 14px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 4px;
            .shifting-remarkContain{
              width:160px;
            }
          }
          .shifting-delete {
            width: 100%;
            height: 32px;
            span {
              display: inline-block;
              width: 100%;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
