.fleet-icon {
  position: relative;

  :global {
    img {
      width: 100%;
      height: 100%;
      border-radius: inherit;
    }

    .loading {
      width: 100%;
      height: 100%;
    }

    .mask-loading {
      width: 100%;
      height: 100%;
      border-radius: inherit;
    }
  }
}