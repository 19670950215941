.common-newly--contact {
  width: 710px;
  gap: 32px;

  :global {

    .newly-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .contacts-detail {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, calc((100% - 16px)/3));
        grid-template-rows: repeat(auto-fit, 56px);
        grid-gap: 12px 8px;

        .contacts-conpmay--container {
          display: grid;
          grid-template-columns: calc((100% - 36px)) 32px;
          gap: 4px;

          .font_family {
            font-size: 32px;
            cursor: pointer;
            line-height: 1;
            opacity: .4;
          }
        }
      }

      .contacts-remarks {
        width: 100%;
        height: 118px;
        display: flex;
        flex-direction: column;
      }
    }
  }

}