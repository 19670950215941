.fleet-fieldAdd {
  width: 420px;
  height: 275px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px 24px;
  border-radius: 6px;
  background: #FFF;
  :global{
    .filedAdd-header {
      width: 100%;
      height: 23px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .header-icon {
        width: 16px;
        height: 16px;
        display: flex;
        cursor: pointer;
      }
    }
    .filedAdd-container {
      width: 100%;
      height: calc(100% - 87px);
      display: flex;
      flex-direction: column;
      gap: 4px;
      &>:first-child {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit,calc((100% - 4px)/2));
        grid-template-rows: repeat(auto-fit,56px);
        gap: 4px;
      }
      &>:last-child {
        width: 100%;
        height: 88px;
      }
    }

    .filedAdd-footer {
      width: 100%;
      height: 32px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      gap: 10px;
    }
  }
}