.estList-voyageCompare {
  padding: 20px 24px;
  min-width: 332px;
  border-radius: 6px;
  background: #FFF;
  flex-direction: column;
  display: flex;
  gap: 12px;

  :global {
    .estList-voyageCompare--container {
      height: 718px;
      width: 100%;
      display: flex;
      gap: 12px;
    }
  }
}