.areaVessel-filter {
	width: 320px;
	height: 100%;
	gap: 10px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	background: #fff;
	border-radius: 6px;
	padding: 12px;
	:global {
		.areaVessel-filter-title {
			color: var(--Text-Default, #333);
			font-size: 16px;
			font-weight: 700;
			line-height: 22px;
		}
		.areaVessel-filter-container {
			width: 100%;
			height: calc(100% - 32px);
			padding-right: 6px;
			overflow: hidden auto;
			&::-webkit-scrollbar {
				width: 4px;
				height: 0px;
			}

			&::-webkit-scrollbar-corner {
				background-color: rgba(41, 42, 44, 1);
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 6px;
				background-color: rgba(53, 86, 145, 0.18);
				border: 1px solid rgb(219, 219, 219);
				cursor: pointer;
			}

			&::-webkit-scrollbar-track {
				border-radius: 4px;
			}
			.areaVessel-filter-scrollArea {
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: 12px;
				.areaVessel-filter-custom {
					width: 100%;
					display: grid;
					gap: 6px 12px;
					grid-template-rows: auto auto auto;
					grid-template-columns: 1fr 1fr;
					& > :first-child {
						grid-column: 1 / -1;
					}
					& > :nth-child(2) {
						grid-row: 2;
						/* 第二行 */
						grid-column: 1;
						/* 第一列 */
					}
					& > :nth-child(3) {
						grid-row: 2;
						/* 第二行 */
						grid-column: 2;
						/* 第二列 */
					}

					& > :nth-child(4) {
						grid-row: 3;
						/* 第三行 */
						grid-column: 1;
						/* 第一列 */
					}

					& > :nth-child(5) {
						grid-row: 3;
						/* 第三行 */
						grid-column: 2;
						/* 第二列 */
					}
					input {
						min-width: unset;
					}
				}
			}
		}
	}
}
