.timeAxis-timeLine {
	width: calc(100% - 152px);
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	:global {
		.timeLine-container {
			width: 100%;
			height: 8px;
			background: #a1b0ca;
			border-top-right-radius: 14px;
			align-items: center;
			display: flex;
			position: relative;
			// overflow: hidden;
			cursor: pointer;
			.timeLine-schedule {
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				width: 12px;
				background-color: #355691;
				z-index: 1;
				transition: width ease-out 0.5s;
			}
			.timeLine-current {
				width: 3px;
				height: 100%;
				position: absolute;
				top: 0px;
				left: 0px;
				background-color: #9d0300;
				z-index: 2;
			}
			.timeLine-area {
				width: 100%;
				height: 100%;
				display: grid;
				grid-template-columns: repeat(auto-fit, 10%);
				grid-template-rows: repeat(auto-fit, 8px);
				position: absolute;
				z-index: 3;
				top: 0px;
				left: 0px;
			}
			.timeLine-disabled {
				width: 0px;
				height: 100%;
				cursor: not-allowed;
				z-index: 4;
				top: 0px;
				right: 0px;
				background-color: rgba(53, 86, 145, 0.4);
			}
		}
		.timeLine-date-container {
			width: 100%;
			height: 20px;
			display: grid;
			grid-template-columns: repeat(auto-fit, 10%);
			grid-template-rows: repeat(auto-fit, 20px);
			& > * {
				color: rgba(0, 0, 0, 0.8);
				font-size: 12px;
				font-weight: 400;
				line-height: 14px;
				padding: 3px 0px 3px 14px;
				cursor: pointer;
			}
			& > :not(:last-child) {
				border-right: 1px solid rgba(0, 0, 0, 0.15);
			}
		}
		.timeLine-info {
			opacity: 1;
			left: -28px;
			z-index: 5;
			top: -30px;
			position: absolute;
			.timeLine-info-container {
				box-shadow: 0 0 4px 0 #000;
				cursor: pointer;
				color: #fff;
				padding: 0px 10px;
				line-height: 20px;
				color: #fff;
				background: rgba(64, 64, 64, 0.78);
				font-size: 12px;
				font-weight: 400;
				&::before {
					top: 100%;
					left: 2em;
					border: solid transparent;
					content: "";
					height: 0;
					width: 0;
					position: absolute;
					border-top-color: rgba(64, 64, 64, 0.78);
					border-width: 0.5em;
					margin-left: -0.5em;
				}
			}
		}
		.timeLine-current-info {
			opacity: 0;
			left: -28px;
			z-index: 4;
			top: -30px;
			position: absolute;
			.current-info-container {
				box-shadow: 0 0 4px 0 #000;
				cursor: pointer;
				color: #fff;
				padding: 0px 10px;
				line-height: 20px;
				color: #fff;
				background: rgba(64, 64, 64, 0.68);
				font-size: 12px;
				font-weight: 400;
				&::before {
					top: 100%;
					left: 2em;
					border: solid transparent;
					content: "";
					height: 0;
					width: 0;
					position: absolute;
					border-top-color: rgba(64, 64, 64, 0.68);
					border-width: 0.5em;
					margin-left: -0.5em;
				}
			}
		}
	}
}
