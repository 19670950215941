.perfreport-voyageItem {
  width: 100%;
  padding: 14px 16px;
  display: flex;
  flex-direction: column;
  height: 74px;
  gap: 4px;
  border-radius: 6px;
  border: 1px solid var(--Line_color, #D9D9D9);
  background: #FFF;
  transition: border-color 300ms ease-in-out;

  &.active {
    border: 1px solid #355691;
  }

  :global {
    .voyageItem-header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .voyageItem-vesselInfo {
        display: flex;
        gap: 4px;

        &>.vesselInfo-vesselName {
          color: var(--character-title-85, rgba(0, 0, 0, 0.85));
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          /* 157.143% */
        }

        &>.vesselInfo-status {
          color: #355691;
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          /* 116.667% */
          width: 16px;
          height: 16px;
          border-radius: 8px;
          background-color: rgba(53, 101, 145, 0.10);
        }
      }

      &>.is-downloaded {
        border-radius: 4px;
        border: 1px solid rgba(53, 86, 145, 0.40);
        background: rgba(53, 86, 145, 0.06);
        padding: 1px 8px;
        color: #3F63A3;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
      }
    }

    .voyageItem-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &>* {
        color: var(--Normal-Text, #ADADAD);
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
      }
    }
  }
}