.movement-disputeCharge {
  width: 900px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  :global{
    .disputeCharge-container {
      height: 300px;
    }
  }
}