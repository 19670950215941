.routePlan-upload {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;

  :global {
    .upload-field {
      width: 100%;
      align-items: center;
      gap: 16px;

      &>.label {
        color: var(--text-secondary, rgba(0, 0, 0, 0.60));
      }

      .upload-input {
        width: 160px;
      }

      .upload-datePicker {
        width: 362px;
        margin-left: 30px;
      }
    }

    .attach-title {
      color: rgba(0, 0, 0, 0.80);
      font-size: 16px;
      font-weight: 510;
      line-height: 28px;
      /* 175% */
    }

    .template-container {
      width: 100%;
      display: flex;
      gap: 10px;

      .template-download {
        width: fit-content;
        gap: 8px;

        .font_family {
          font-size: 14px;
        }
      }

    }

    .not-empty {
      // transform: scale(0);
      display: none;
    }

    .uploadfile-item {
      width: 100%;
      border-radius: 4px;
      border: 1px solid #D9D9D9;
      background: #FFF;
      padding: 9px 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .uploadfile-info {
        display: flex;
        gap: 8px;
        align-items: center;

        &>:first-child {
          font-size: 48px;
          color: #2FA556;
          border-radius: 6px;
          line-height: 1;
        }

        &>:last-child {
          color: #355691;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          /* 157.143% */
        }
      }

      .uploadfile-remove {
        font-size: 14px;
        cursor: pointer;
      }
    }

  }
}