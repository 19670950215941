.movement-subTotal {
  width: 100%;
  height: 87px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  :global{
    .subTotal-header {
      width: 100%;
      height: 19px;
      display: flex;
      align-items: center;
      .fleet-title {
        font-size: 16px;
        &::before {
          width: 6px;
          height: 16px;
        }
      }
    }
    .subTotal-container {
      width: 100%;
      height: calc(100% - 31px);
      display: grid;
      grid-template-columns: repeat(auto-fit,calc((100% - 20px)/6));
      grid-template-rows: repeat(auto-fit,56px);
      gap: 4px;
    }
  }
}