.routeCommon-details-tabs {
	display: flex;
	padding: 2px;
	border: 1px solid rgba(53, 86, 145, 0.5);
	background: rgba(53, 86, 145, 0.08);
	border-radius: 6px;
	min-height: 32px;
	:global {
		.details-tabItem {
			font-size: 12px;
			font-weight: 400;
			line-height: 22px;
			cursor: pointer;
			color: rgba(53, 86, 145, 0.5);
			border-radius: 4px;
			padding: 2px 16px;
			transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			&.active {
				color: #fff;
				background: rgba(53, 86, 145, 1);
			}
		}
	}
}

.routeCommon-details-tabs--empty {
	visibility: hidden;
}
