.statement-desciption {
	:global {
		.invoices-add {
			// align-self: flex-end;
		}
		.invoicesRate-computed {
			color: var(--color-text-placeholder, #c8c8c8);
			font-size: 12px;
			font-weight: 400;
			line-height: 20px; /* 166.667% */
			width: 100%;
		}
		.invoicesForm-freight {
			display: flex;
			gap: 4px;
			align-items: flex-end;

			input {
				min-width: unset;
			}

			& > :first-child {
				width: 126px;
			}

			& > :last-child {
				width: 70px;
			}

			.freight-unit {
				.verity-header {
					// justify-content: flex-end;
					padding-left: 12px;
				}
			}
		}

		// .ttl-onhireForm {
		//   grid-template-columns: 150px 326px 150px 150px !important;
		// }
		.ttl-onoffForm {
			grid-template-columns: repeat(auto-fit, calc((100% - 8px) / 2)) !important;
		}
	}
}
