.handoverForm-basicInfo {
  :global {
    .basicInfo-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .basicInfo-voyageInfo {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, calc((100% - 24px)/4));
        grid-template-rows: repeat(auto-fit, 56px);
        gap: 8px;
      }

      .basicInfo--textarea {
        width: 100%;
        background: rgba(250, 250, 250, 0.60);
        height: 76px;
      }
    }
  }
}