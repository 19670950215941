.movement-portExpenses {
  width: 1350px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;

  :global {
    .portExpenses-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .portExpenses-vesselInfo {
        display: grid;
        grid-template-columns: 307px 307px 307px 1fr;
        grid-template-rows: repeat(auto-fit, 32px);
        gap: 15px;
      }

      .portExpenses-portList {
        width: 100%;
        height: 300px;

        .portExpenses-cargo--container {
          display: grid;
          grid-template-columns: 101px 71px;
          gap: 4px;
          grid-template-rows: repeat(auto-fit, 28px);

          input {
            min-width: unset;
          }
        }
      }

      .portExpenses-summary--scrollArea {
        position: relative;
        width: 100%;
        display: grid;
        grid-template-columns: 150px 222px 220px 150px 150px 150px 300px;

        &>* {
          color: var(--color-text-Gray-95, #222);
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          /* 157.143% */
          padding: 2px 12px 2px 16px;
          border-top: 1px solid #F0F0F0;
          border-right: 1px solid #F0F0F0;
          border-bottom: 1px solid #F0F0F0;
          background: #F8F8F9;
        }
      }
    }
  }
}