.invoices-otherExpenses {
  :global {
    .invoicesForm-container {
      grid-template-columns: 1fr 1fr 2fr  !important;
    }
    .otherExpenses-fields {
      display: flex;
      gap: 4px;
      align-items: flex-end;

      &>:first-child {
        width: calc(100% - 36px);
      }

      &>:last-child {
        width: 32px;
        height: 32px;
        display: flex;
        cursor: pointer;
      }
    }
  }
}