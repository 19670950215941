.invStatement-basics {
	:global {
		.invoicesForm-firstRow {
			width: 100%;
			gap: 16px 8px;
			display: grid;
			align-items: flex-end;
			grid-template-columns: repeat(auto-fit, calc((100% - 24px) / 2));
			grid-template-rows: repeat(auto-fit, 56px);
		}
	}
}
