.fleet-perfreport {
  width: 100%;
  height: calc(100% - 64px);
  overflow: hidden;

  :global {
    .perfreport-warpper {
      width: 100%;
      height: 100%;
      display: flex;
      background: #FFF;
      position: relative;
      overflow: hidden;
    }
  }
}