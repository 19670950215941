.movement-list {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 14px 20px 20px 20px;

	:global {
		.movementList-header {
			width: 100%;
			height: 32px;
			display: flex;
			gap: 8px;
			margin-bottom: 14px;
			.details-title {
				// color: var(--Special-Color, #254377);
				color: #254377;
				font-size: 24px;
				font-weight: 500;
				line-height: normal;
				display: flex;
				align-items: center;
				margin-right: 24px;

				&::before {
					width: 8px;
					height: 18px;
					border-radius: 37px;
					background: #254377;
					margin-right: 12px;
					display: inline-block;
					content: "";
				}
			}
		}

		.movementList-search {
			display: flex;
			height: 56px;
			width: 100%;
			justify-content: space-between;
			margin-bottom: 12px;

			.search-container {
				height: 56px;
				display: flex;
				align-items: flex-end;
				gap: 8px;

				.input-filter {
					width: 254px;
				}
				.voyage-select {
					width: 200px;
				}
			}

			.toolbar-container {
				display: flex;
				height: 100%;
				align-items: flex-end;
				gap: 8px;
			}
		}

		.movementList-table {
			width: 100%;
			height: calc(100% - 114px);

			.td-operate {
				display: flex;
				width: 100%;
				height: 100%;
				justify-content: space-between;
				align-items: center;

				.operate-btn {
					font-size: 12px;
					border: 1px solid #35569166;
					background-color: #3556910f;
					color: #3f63a3;
					padding: 0 8px;
					border-radius: 4px;
					cursor: pointer;
				}

				.operate-icon {
					width: 16px;
					height: 16px;
					display: flex;
					cursor: pointer;
				}

				.status-select {
					.ant-select-selector {
						border: 0px;
					}
				}
			}

			.movement-task--warning {
				color: #f4ad0b;
				cursor: pointer;
				&.fleet-disabled {
					color: #ccc;
				}
			}

			.movement-voyageType {
				padding: 0px 4px;
				border-radius: var(--spacing-1, 2px);
				color: var(--color-bg-accent-fleetblue-bolder, #355691);
				font-size: 14px;
				font-weight: 400;
				line-height: 22px;
				&.TCTC {
					background: rgba(9, 209, 125, 0.12);
					color: #06b169;
				}
				&.TCVC {
					background: var(--color-bg-accent-fleetblue-subtler, #ebeef4);
					color: var(--color-bg-accent-fleetblue-bolder, #355691);
				}
			}

			// .ant-pagination {
			//   justify-content: flex-end !important;
			// }
			// height: calc(100% -136px);
		}

		.movement-statusShow {
			width: 1200px;
			display: flex;
			flex-direction: column;
			gap: 12px;
			background-color: #fff;
			padding: 20px;
			border-radius: 6px;
			.prompt-header--container {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 10px;
				.header-title {
					color: var(--color-text-body-1, #151515);
					font-size: 20px;
					font-weight: 590;
					line-height: 28px;
					gap: 10px;
					display: flex;
					align-items: center;

					&::before {
						display: inline-block;
						content: "";
						border-radius: 38px;
						background: #355691;
						width: 6px;
						height: 16px;
					}
				}

				.header-close {
					font-size: 16px;
					cursor: pointer;
				}
			}
		}
	}
}

.filter-content {
	background-color: #ffffff;
	background-clip: padding-box;
	border-radius: 8px;
	box-shadow:
		0 6px 16px 0 rgba(0, 0, 0, 0.08),
		0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
