.fleet-password {
  margin-left: 10px;
  :global {
    .password-item {
      height: 50px;
      align-items: center;
      width: 100%;
      .label {
        font-size: 16px;
        line-height: 50px;
        width: 180px;
        text-align: right;
      }
      .password-item-inner{
        width:100%;
      }
    }
  }
}
