.apar-financial {
	width: 600px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	background-color: #fff;
	padding: 12px 16px;
	border-radius: 6px;
	position: relative;
	:global {
		.financial-container {
			display: flex;
			flex-direction: column;
			gap: 16px;
			.financial-info {
				width: 100%;
				display: grid;
				grid-template-columns: repeat(auto-fit, calc((100% - 16px) / 3));
				grid-template-rows: repeat(auto-fit, 56px);
				gap: 12px 8px;
			}
			.financial-upload-container {
				display: grid;
				width: 100%;
				gap: 12px 8px;
				grid-template-columns: repeat(auto-fit, calc((100% - 16px) / 2));
				grid-template-rows: repeat(auto-fit, 56px);
				.upload-item {
					border-radius: 4px;
					border: 1px solid #d9d9d9;
					background: #fff;
					padding: 9px 8px;
					display: flex;
					justify-content: space-between;
					align-items: center;

					.uploadfile-info {
						display: flex;
						gap: 8px;
						align-items: center;

						& > :first-child {
							border-radius: 6px;
							width: 36px;
							height: 36px;
						}

						& > :last-child {
							color: #355691;
							font-size: 14px;
							font-weight: 400;
							line-height: 22px;
							width: calc(100% - 56px);
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							/* 157.143% */
						}
					}
					.upload-actions {
						display: flex;
						align-items: center;
						gap: 6px;
						& > * {
							cursor: pointer;
						}
					}
				}
			}
		}
	}
}
