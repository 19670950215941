.routeCommon-vesselCard-item {
	width: 353px;
	border-radius: 4px;
	background: #fff;
	box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
	padding: 14px;
	gap: 12px;
	display: flex;
	flex-direction: column;
	position: relative;

	:global {
		.ant-collapse-item {
			border: 0px;
			gap: 12px;
		}

		.ant-collapse-header {
			border-radius: 0px !important;
			padding: 0px 0px 12px 0px !important;
			height: unset !important;
			background-color: inherit !important;
			gap: 8px;

			&[aria-expanded="false"] {
				padding-bottom: 0px !important;
			}

			& > .ant-collapse-expand-icon {
				width: 20px !important;
				height: 20px !important;
				padding-inline-end: 0px !important;
				justify-content: center;
			}

			& > .ant-collapse-header-text {
				width: calc(100% - 28px);

				.aisDetails-header {
					width: 100%;
					display: flex;
					gap: 8px;
					align-items: center;

					.header-warpper {
						width: calc(100% - 24px);
						display: flex;
						gap: 8px;
						align-items: center;

						.header-vesselName {
							color: var(--Special-Color, #254377);
							font-size: 14px;
							font-weight: 500;
							line-height: 22px;
							margin-right: 6px;
							/* 157.143% */
						}

						&.ais {
							.header-tag {
								color: var(--Orange-600, #ff8632);
								font-size: 12px;
								font-weight: 400;
								line-height: 20px;
								/* 166.667% */
								padding: 0px 8px;
								border-radius: 4px;
								background: rgba(255, 134, 50, 0.1);
							}

							.font_family {
								font-size: 14px;
								color: rgba(0, 0, 0, 0.3);

								&.active {
									color: #ff8632;
								}
							}
						}

						&.port {
							.header-tag {
								color: var(--Orange-600, #f900d1);
								font-size: 12px;
								font-weight: 400;
								line-height: 20px;
								/* 166.667% */
								padding: 0px 8px;
								border-radius: 4px;
								background: rgba(249, 0, 209, 0.1);
							}
						}

						&.area {
							.header-tag {
								color: var(--Orange-600, #0072f9);
								font-size: 12px;
								font-weight: 400;
								line-height: 20px;
								/* 166.667% */
								padding: 0px 8px;
								border-radius: 4px;
								background: rgba(0, 114, 249, 0.1);
							}
						}

						&.vm {
							.header-vesselName {
								width: calc(50% - 4px);
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
								margin-right: 0px;
							}

							.header-voyageNumber {
								width: calc(50% - 4px);
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;

								& > * {
									color: var(--Main-Text, #484848);
									font-size: 12px;
									font-weight: 400;
									line-height: 22px;

									&.label {
										color: var(--Normal-Text, #adadad);
									}
								}
							}
						}
					}

					.aisDetails-close {
						font-size: 16px;
						color: #636363;
					}
				}
			}
		}

		.ant-collapse-content {
			border-radius: 0px !important;
			background-color: inherit !important;

			.ant-collapse-content-box {
				padding: 0px !important;
				margin: 0px !important;

				.aisDetails-container {
					width: 100%;
					display: flex;
					flex-direction: column;
					padding-top: 6px;
					gap: 6px;
				}
			}
		}
	}
}
