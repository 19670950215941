.movement-commentItem {
  border-radius: 4px;
  background: #FFF;
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  :global {
    .commentItem-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .commentItem-creater {
        display: flex;
        gap: 16px;

        &>:first-child {
          color: #1D1D1D;
          font-size: 14px;
          font-weight: 510;
          line-height: normal;
        }

        &>:last-child {
          color: var(--Second-Text, #707070);
          font-size: 14px;
          font-weight: 400;
          line-height: normal;
        }
      }
      .commentItem-toolbar {
        display: flex;
        .font_family {
          font-size: 14px;
          cursor: pointer;
          &.toolbar-delete {
            color: #FF0E0E;
          }
        }
      }
    }

    .commentItem-container {
      color: var(--Main-Text, #484848);
      font-size: 14px;
      font-weight: 400;
      line-height: 120%;
      white-space: pre-wrap;
      /* 换行符保留，并根据需要换行 */
      word-wrap: break-word;
      /* 长单词换行 */
      width: 100%;
    }
  }
}