.movement-portReports {
  width: 1600px;
  height: 860px;
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: #fff;

  :global {
    .port-reports-header {
      width: 100%;
      height: 23px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      .header-icon {
        width: 16px;
        height: 16px;
        cursor: pointer;
        display: flex;
      }
    }

    .port-reports-main {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;
      margin: 10px 0;
      .port-reports-cards {
        width: 172px;
        background: #f8f8f9;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .port-card {
          margin-top: 8px;
          padding: 0 8px;
          width: 160px;
          height: 66px;
          border-radius: 6px;
          border: 1px solid #d9d9d9;
          background: #fff;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .port-card-port {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .port-card-name {
              color: #484848;
              font-family: Roboto;
              font-size: 14px;
              font-weight: 600;
              height: 20px;
              max-width: 70px;
              line-height: 20px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .port-card-behavier {
              color: rgba(125, 125, 125, 0.86);
              font-family: Roboto;
              font-size: 12px;
              font-weight: 500;
              height: 20px;
              line-height: 20px;
            }
          }
          
          .port-card-state {
            line-height: 66px;
            .port-card-state {
              height: 24px;
              width: 0;
              border-radius: 2px;
              padding: 0px 8px;
              text-align: center;
              font-family: Roboto;
              font-size: 12px;
              font-weight: 400;
              line-height: 20px;
            }
            .state-update {
              width: 70px;
              border: 1px solid rgba(53, 86, 145, 0.4);
              background: rgba(53, 86, 145, 0.06);
              color: #3f63a3;
            }
            .state-not {
              width: 50px;
              border: 1px solid rgba(223, 143, 50, 0.45);
              background: #ffeedf;
              color: #ff9213;
            }
          }
        }
        .selected-port {
          border: 1px solid #355691;
          background: rgba(53, 86, 145, 0.05);
          box-shadow: 0px 0px 0px 2px rgba(53, 86, 145, 0.1);
        }
      }
      .port-reports-container {
        flex: 1;
        border-left: 1px solid #d9d9d9;
        padding: 0 10px 0 20px;

        .ant-upload-wrapper {
          .ant-upload {
            margin-bottom: 10px !important;
          }
        }
        .port-reports-tables {
          height: 620px;
          overflow: auto;
          padding-right: 16px;
          scroll-behavior: smooth;
          &::-webkit-scrollbar {
            width: 6px;
            margin-left: 16px;
          }
          &::-webkit-scrollbar-thumb {
            width: 6px;
            border-radius: 4px;
            background: #d2d2d2;
          }
        }
      }
    }

    .port-reports-footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 54px;
      gap: 10px;
    }
    .port-report-title {
      color: #484848;
      font-family: Roboto;
      font-size: 16px;
      height: 20px;
      font-weight: 500;
      line-height: 20px;
      height: 42px;
      line-height: 42px;
    }
    .port-report-add {
      border-radius: 4px;
      border: 1px solid #dadada;
      padding: 3px 6px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
