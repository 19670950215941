.esDetails-mulRoute {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  border: 1px solid #D9D9D9;
  background: #EEF1F4;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;

  :global {
    .mulRoute-header {
      width: 100%;
      justify-content: space-between;
      display: flex;
      align-items: center;
      padding: 12px 16px 12px 4px;
      height: 54px;
      border-radius: 6px 6px 0px 0px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);

      .header-select {
        display: flex;
        font-size: 16px;
        font-weight: 590;
        line-height: 24px;
        align-items: center;

        &>:first-child {
          width: 28px;
          height: 28px;
          display: flex;
          margin-right: 4px;
        }

        &>:nth-child(2) {
          // color: var(--color-text-body-4, #B5B5B5);
          color: #B5B5B5;

          /* 150% */
          &::after {
            display: inline-block;
            content: "/";
            color: #B5B5B5;
            margin: 0px 10px;
          }
        }

        &>:last-child {
          // color: var(--color-text-body-1, #151515);
          color: #151515;
        }
      }

      .header-confirm {
        // color: var(--color-text-primary, #355691);
        color: #355691;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        cursor: pointer;
      }
    }

    .mulRoute-container {
      width: 100%;
      height: calc(100% - 54px);
      border-radius: 0px 0px 6px 6px;
      border: 0px;
      background-color: #fff;

      &::-webkit-scrollbar {
        width: 0px;
        height: 6px;
      }

      .table-layout {
        thead {
          tr {
            th {
              height: 34px;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              // background-color: #EEF1F4;
              /* 166.667% */
            }

            &>th:nth-child(1) {
              position: sticky;
              left: unset;
              z-index: 13;
            }

            &>th:nth-child(2) {
              position: sticky;
              left: unset;
              z-index: 12;
            }

            &>th:nth-child(3) {
              position: sticky;
              left: unset;
              z-index: 11;
            }
          }
        }

        table {
          tr {
            td {
              height: 34px;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              /* 157.143% */
            }
          }
        }
      }
    }
  }
}