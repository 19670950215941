.coordinates-controll {
	position: absolute;
	bottom: 20px;
	right: 20px;
	color: #fff;
	font-weight: 2000;
	background-color: rgba(0, 0, 0, 0.5);
	padding: 5px;
	font-size: 6px;
}
