.account-filter {
  gap: 10px;
  display: flex;
  align-items: center;

  :global {
    .filter-search {
      width: 254px;
    }

    .filter-companySelect {
      width: 160px;
    }
  }
}