.route-legFilter {
	width: 100%;
	display: flex;
	justify-content: space-between;
	:global {
		.legFilter-label {
			line-height: 22px;
			font-size: 13px;
			font-weight: 400;
			border-radius: 4px;
			background: rgba(40, 83, 195, 0.1);
			padding: 0px 4px;
		}
		.legFilter-container {
			align-items: center;
			display: flex;
			gap: 6px;
			cursor: pointer;
			color: #2853c3;
			.legFilter-icon {
				font-size: 16px;
			}
			& > :last-child {
				font-size: 14px;
				font-weight: 400;
				line-height: 20px; /* 19.6px */
			}
		}
	}
}

.legFilter-statuOptions {
	width: 130px;
	padding: 12px 8px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	background-color: #ffffff;
	border-radius: 6px;
	box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
	:global {
		ul {
			display: flex;
			flex-direction: column;
			border-bottom: 1px solid rgba(5, 5, 5, 0.06);
			gap: 2px;
			padding-bottom: 8px;
		}
		.statuOptions-footer {
			display: flex;
			justify-content: space-between;
		}
	}
}
